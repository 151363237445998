import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import NavbarCam from "../../components/navbar/navbarcam";
import Checkout from "../cartproducts/checkout";
import InOrder from "../../components/inorder/inorder";
import BottomCheckout from "../../components/bottomnav/bottomcheckout";
import BottomInOrder from "../../components/bottomnav/bottominorder";
import SideBar from "../../components/home/sidebar";
import SearchPage from "../../components/inorder/search"
import { nameChange } from "../../redux/cartredux";
import { nameChange_ii } from "../../redux/cartredux_two";
import { nameChange_iii } from "../../redux/cartredux_three";
import { nameChange_iv } from "../../redux/cartredux_four";
import { nameChange_v } from "../../redux/cartredux_five";
import { useDispatch, useSelector } from "react-redux";

const InOrderPage = () => {
  const dispatch = useDispatch();
  const [todaysDate, setTodaysDate] = useState("");
  const [todaysUnix, setTodaysUnix] = useState("");
  const [camShow, setCamShow] = useState(false);
  const date = new Date();
  const stringed = JSON.stringify(date).slice(1, -15);
  const dateDate = moment(stringed).format("DD");
  const dateMonth = moment(stringed).format("MM");
  const dateYear = moment(stringed).format("YYYY");
  const actualD = `${dateMonth}/${dateDate}/${dateYear}`;
  const [stringDate, setStringDate] = useState(actualD);
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showArrow, setShowArrow] = useState(false);
  const [finishSelected, setFinishSelected] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [searchBAr, setSearchBar] = useState(false);
  const [page, setPage] = useState(0);
  const [editName, setEditName] = useState(false);
  const [orderName, setOrderName] = useState(null);

  const unixDate = moment(
    `${stringDate} 23:59:59`,
    "MM/DD/YYYY HH:mm:ss"
  ).valueOf();
  const actualDate = moment(unixDate).format("YYYY-MM-DD");

  useEffect(() => {
    setTodaysDate(actualDate);
    setTodaysUnix(unixDate);
  }, [stringDate]);

  const changeFunction = () => {
    if (page === 0) {
      dispatch(nameChange({ custName: orderName }));
      setEditName(false);
      setOrderName(null);
    } else if (page === 1) {
      dispatch(nameChange_ii({ custName: orderName }));
      setEditName(false);
      setOrderName(null);
    } else if (page === 2) {
      dispatch(nameChange_iii({ custName: orderName }));
      setEditName(false);
      setOrderName(null);
    } else if (page === 3) {
      dispatch(nameChange_iv({ custName: orderName }));
      setEditName(false);
      setOrderName(null);
    } else if (page === 4) {
      dispatch(nameChange_v({ custName: orderName }));
      setEditName(false);
      setOrderName(null);
    }
  };

  return (
    <Body finishselected={finishSelected.toString()}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <AnimatePresence>
          {searchBAr && <SearchPage setSearchBar={setSearchBar} page={page} />}
        </AnimatePresence>
        <NavbarCam
          camShow={camShow}
          setCamShow={setCamShow}
          scanResultWebCam={scanResultWebCam}
          setScanResultWebCam={setScanResultWebCam}
          showArrow={showArrow}
          setShowArrow={setShowArrow}
          searchBAr={searchBAr}
        />
        <InOrder
          scanResultWebCam={scanResultWebCam}
          setScanResultWebCam={setScanResultWebCam}
          showArrow={showArrow}
          setShowArrow={setShowArrow}
          setFinishSelected={setFinishSelected}
          finishSelected={finishSelected}
          searchBAr={searchBAr}
          page={page}
          setPage={setPage}
          editName={editName}
          setEditName={setEditName}
          orderName={orderName}
          setOrderName={setOrderName}
        />
        <BottomInOrder
          camShow={camShow}
          setCamShow={setCamShow}
          setFinishSelected={setFinishSelected}
          finishSelected={finishSelected}
          sidebar={sidebar}
          setSidebar={setSidebar}
          setSearchBar={setSearchBar}
          page={page}
          setPage={setPage}
          editName={editName}
          setEditName={setEditName}
          changeFunction={changeFunction}
        />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)`
  position: fixed;
  overflow-y: hidden;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export default InOrderPage;

/*
 pointer-events: ${({ finishSelected }) =>
    finishSelected === "false" ? "auto" : "none"};

  z-index: ${({ finishSelected }) =>
   (finishSelected === "false" ? "1" : "-1")};*/
