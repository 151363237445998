import { configureStore, combineReducers } from "@reduxjs/toolkit";
import cartReducer from "./cartredux";
import cartredux_two from "./cartredux_two";
import cartredux_three from "./cartredux_three";
import cartredux_four from "./cartredux_four";
import cartredux_five from "./cartredux_five";
import userReducer from "./userredux";
import productReducer from "./productredux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
  key: "root",
  version: 1,
  storage,
};
const rootReducer = combineReducers({
  user: userReducer,
  product: productReducer,
  cart: cartReducer,
  cart_two: cartredux_two,
  cart_three: cartredux_three,
  cart_four: cartredux_four,
  cart_five: cartredux_five,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export let persistor = persistStore(store);
