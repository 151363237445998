import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { IoIosAddCircleOutline } from "react-icons/io";
import { publicRequest } from "../../requestmethods";
import { useDispatch } from "react-redux";
import { addProduct } from "../../redux/cartredux";
import { addProduct_ii } from "../../redux/cartredux_two";
import { addProduct_iii } from "../../redux/cartredux_three";
import { addProduct_iv } from "../../redux/cartredux_four";
import { addProduct_v } from "../../redux/cartredux_five";
import { MdDownloadDone } from "react-icons/md";

const SearchPage = ({ setEditOrder, makeChanges, setSearchBar, page }) => {
  const dispatch = useDispatch();
  const [searching, setSearching] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [assesedProducts, setAssessedProducts] = useState([]);
  const [maxLength, setMaxLength] = useState(null);
  const [showLess, setShowLess] = useState(false);
  const [quantity, setQuantity] = useState(1);
  const [changeColor, setChangeColor] = useState(null);
  const [view, setView] = useState(8);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setSearchBar(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (typeof searching === "string" && searching.length > 0) {
          let lettersinput = searching.toLowerCase();
          const res = await publicRequest.get(
            `/products?category=${lettersinput}`
          );
          setSearchResults(res?.data || []);
          setMaxLength(res?.data?.length || null);
        }
      } catch (error) {
        console.error("Error fetching search results:", error);
      }
    };

    if (searching.trim() !== "") {
      fetchData();
    } else {
      setSearchResults([]);
      setMaxLength(null);
    }
  }, [searching]);

  const handleInput = (e) => {
    setSearching(e.target.value);
  };

  const addCart = (d, key) => {
    if (page === 0) {
      dispatch(addProduct({ ...d, quantity }));
    }

    setChangeColor(key);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setChangeColor(null);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [changeColor]);

  useEffect(() => {
    const maxi = () => {
      if (maxLength <= view) {
        setShowLess(true);
      }

      if (maxLength === 0) {
        setView(0);
      }
    };

    maxLength && maxi();
  }, [view, maxLength]);

  useEffect(() => {
    const miniControl = () => {
      if (!searchResults.length || maxLength === 0) {
        setShowLess(true);
        return true;
      }
      return false;
    };

    if (maxLength && view >= maxLength) {
      setShowLess(true);
    }

    if (maxLength > view) {
      setShowLess(false);
    }

    if (maxLength > 8 && view < 8) {
      setView(8);
      setShowLess(false);
    }

    miniControl();
  }, [view, searchResults, maxLength]);

  useEffect(() => {
    const viewer = searchResults.slice(0, view);
    setAssessedProducts(viewer);
  }, [searchResults, view, searching]);

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <Footage>
          <Editing>
            <Input>
              <div className="searchTitle">Search</div>
              <input type="text" value={searching}  onChange={handleInput} />
            </Input>
            <Searching>
              {searchResults?.length > 0 &&
                searching && 
                assesedProducts?.length > 0 &&
                assesedProducts?.map((d, key) => (
                  <div
                    className={d?.inStock ? "searchNresults" : "fix"}
                    key={key}
                  >
                    <div className="actualImg">
                      <img src={d?.img} alt="" className="searchimg" />
                      {d?.inStock ? (
                        ""
                      ) : (
                        <div className="out">OUT OF STOCK</div>
                      )}
                    </div>
                    <div className="dataResults">
                      <div>{d?.artNo}</div>
                      <div>{d?.price}</div>
                      <div>{d?.pcs}</div>
                    </div>
                    <div className="icon">
                      {changeColor === key ? (
                        <MdDownloadDone
                          size={25}
                          color={"green"}
                          onClick={() => addCart(d, key)}
                        />
                      ) : (
                        <IoIosAddCircleOutline
                          size={25}
                          color={"black"}
                          onClick={() => addCart(d, key)}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </Searching>
            <Button>
              {searchResults && searching && !showLess && (
                <div className="button" onClick={() => setView(view + 8)}>
                  load more ...
                </div>
              )}
            </Button>
          </Editing>
        </Footage>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;
  z-index: 2;
`;

const Footage = styled(motion.div)`
  height: 100%;
  top: 2.5%;
  bottom: 7.5%;
  position: absolute;
  width: 90%;
  right: 5%;
  left: 5%;
  border-radius: 1rem;
`;

const Editing = styled(motion.div)`
  position: absolute;
  height: 100%;
  width: 95%;
  display: grid;
  grid-template-rows: 15% 75.5% 5%;

  right: 2.5%;
  left: 2.5%;
  align-items: center;
`;

const Input = styled(motion.div)`
  top: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 30% 50%;
  align-items: center;

  .searchTitle {
    text-align: start;
  }

  input {
    padding: 4%;
    width: 100%;
    z-index: 1;
    border: 0.01rem solid gray;
    height: 80%;
    background-color: white;
    border-radius: 2rem;
  }
`;

const Searching = styled(motion.div)`
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;

  scroll-behaviour: smooth;
  bottom: 0;

  .searchNresults {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    margin: 0%;
  }

  .fix {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    margin: 0%;
  }

  .searchimg {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    grid-gap: 0.5rem;
  }

  .actualImg {
    height: 20%;
    display: grid;
    align-items: center;
    justify-items: center;

    .out {
      font-weight: 1000;
      font-size: 50%;
    }
  }

  .dataResults {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Button = styled(motion.div)`
  display: grid;
  align-items: center;
  justify-items: end;

  .button {
  }
`;

export default SearchPage;
