import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { MdQrCodeScanner, MdOutlineCameraEnhance } from "react-icons/md";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineStock } from "react-icons/ai";
import { VscMenu } from "react-icons/vsc";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

const BottomNav = ({ sidebar, setSidebar }) => {
  const User = useSelector((state) => state?.user.currentUser);
  return (
    <Container>
      <Data user={User.isAdmin.toString()}>
        <div>
          <Link to="/orderscan" style={{ color: "black" }}>
            <MdQrCodeScanner size={25} />
          </Link>
        </div>
        <div>
          <Link to="/products" style={{ color: "black" }}>
            <HiOutlineShoppingBag size={25} />
          </Link>
        </div>
        <div>
          <Link to="/inorder" className="stocks">
            <AiOutlineStock size={25} className="stocks" />
          </Link>
        </div>
        <div>
          <Link to="/checkout" style={{ color: "black" }}>
            <MdOutlineCameraEnhance size={25} />
          </Link>
        </div>
        <div style={{ cursor: "pointer" }}>
          <VscMenu size={25} onClick={() => setSidebar(!sidebar)} />
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)``;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  position: absolute;
  bottom: 0;
  border-top: 0.04rem solid gray;
  height: 10%;
  background: white;
  z-index: 1;

  .stocks {
    color: black;
  }
`;

export default BottomNav;
