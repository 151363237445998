import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import Products from "./products";
import BottomProductNav from "../../components/bottomnav/bottomproductnav";
import SideBar from "../../components/home/sidebar";

const ProductPage = () => {
  const [sidebar, setSidebar] = useState(false);
  const [searchBAr, setSearchBar] = useState(false);
  const [startSearch, setStartSearch] = useState(false);
  
  return (
    <Body>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar setSidebar={setSidebar} />}
        </AnimatePresence>
        <Navbar />
        <Products startSearch={startSearch} setStartSearch={setStartSearch} />
        <BottomProductNav
          sidebar={sidebar}
          setSidebar={setSidebar}
          setSearchBar={setSearchBar}
          searchBAr={searchBAr}
          startSearch={startSearch}
          setStartSearch={setStartSearch}
        />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)``;

export default ProductPage;
