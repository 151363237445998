import NavbarCam from "../../components/navbar/navbarcam";
import BottomScan from "../../components/bottomnav/bottomscan";
import OrderScanning from "./orderscanning";
import { userRequest, publicRequest } from "../../requestmethods";
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Scanning = () => {
  const [camShow, setCamShow] = useState(false);
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showArrow, setShowArrow] = useState(false);
  const [finishSelected, setFinishSelected] = useState(false);
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [orderId, setOrderId] = useState("");
  const [printer, setPrinter] = useState(false);
  const [editOrder, setEditOrder] = useState(false);
  const [product, setProduct] = useState(null);
  const [orderBy, setOrderBy] = useState(null);
  const [editProcess, setEditProcess] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [errType, setErrType] = useState(null);
  const [initiateCheck, setInitiateCheck] = useState(false);
  const [jumpToFinal, setJumpToFinal] = useState(false);
  const [transCust, setTransCust] = useState("");
  const [getToFinal, setGetToFinal] = useState(null);
  const [fetchedTotal, setFetchedTotal] = useState(null);
  const user = useSelector((state) => state.user.currentUser);

  useEffect(() => {
    const searchingUser = async () => {
      try {
        if (orderBy === "cust") {
        } else {
          if (user?.isAdmin) {
            const res = await userRequest.get(`/users/find/${orderBy}`);

            if (res.data.isAdmin) {
              setJumpToFinal(true);
              const resOne = await userRequest.get(
                `/customers/find/${customerId}`
              );
            } else if (res.data.isWorker) {
              const resTwo = await userRequest.get(
                `/customers/findfromworker/${customerId}`
              );

              console.log(resTwo);
            }
          } else if (user?.isWorker) {
            setJumpToFinal(true);
            const resTwo = await userRequest.get(
              `/workerscustomers/find/${customerId}`
            );

            console.log(resTwo);
          }
        }
      } catch (err) {
        console.log(err);
        orderBy && setErrType(err.response.data.message);
      }
    };

    orderBy && searchingUser();
  }, [orderBy]);

  useEffect(() => {
    const moveUser = async () => {};
  }, []);

  const handlePaid = async () => {
    const productsInOrder = [];

    fetchedProducts[0] &&
      fetchedProducts.map(async (d) => {
        productsInOrder.push(d.productId);
      });

    let filteredProducts = [...new Set(productsInOrder)];

    await filteredProducts.map(async (d) => {
      let filteredData = [];

      for (let i = 0; i < fetchedProducts.length; i++) {
        if (d === fetchedProducts[i].productId) {
          filteredData.push(fetchedProducts[i].quantity);
        }
      }

      let sumQuantity = filteredData.reduce((a, b) => a + b, 0);

      const res = await publicRequest.get(`/products/find/${d}`);

      let updatedStock = (await res.data.nostock) - sumQuantity;

      let newdata = {
        nostock: updatedStock,
      };

      await userRequest.put(`/products/${d}`, newdata);
    });

    let statusDetail = {
      status: "paid",
    };
    await userRequest.put(`/instoreorders/find/${orderId}`, statusDetail);
  };

  const handleJumla = () => {
    let totalPrice = [];
    if (fetchedProducts) {
      //console.log(fetchedProducts);
      fetchedProducts &&
        fetchedProducts.map((d) => {
          let onlyprice = d.productData.price;
          let onlypcs = d.productData.pcs;
          let onlyqty = d.quantity;
          let jumla = onlyprice * onlypcs * onlyqty;

          totalPrice.push(jumla);
        });
    }
    const totalbei = fetchedProducts && totalPrice.reduce((a, b) => a + b, 0);

    return totalbei;
  };

  const handleUpdates = async () => {
    const update = {
      products: fetchedProducts,
    };

    const resOrder = await userRequest.put(
      `/instoreorders/find/${orderId}`,
      update
    );

    if (resOrder.statusText === "OK") {
      if (handleJumla() !== fetchedTotal) {
        return;
      }
    }
  };

  return (
    <div className="Scanner">
      <NavbarCam
        camShow={camShow}
        setCamShow={setCamShow}
        scanResultWebCam={scanResultWebCam}
        setScanResultWebCam={setScanResultWebCam}
        showArrow={showArrow}
        setShowArrow={setShowArrow}
      />

      <OrderScanning
        scanResultWebCam={scanResultWebCam}
        setScanResultWebCam={setScanResultWebCam}
        showArrow={showArrow}
        setShowArrow={setShowArrow}
        setFinishSelected={setFinishSelected}
        finishSelected={finishSelected}
        printer={printer}
        setPrinter={setPrinter}
        setEditOrder={setEditOrder}
        editOrder={editOrder}
        fetchedProducts={fetchedProducts}
        setFetchedProducts={setFetchedProducts}
        orderId={orderId}
        setOrderId={setOrderId}
        product={product}
        setProduct={setProduct}
        orderBy={orderBy}
        setOrderBy={setOrderBy}
        editProcess={editProcess}
        setCustomerId={setCustomerId}
        initiateCheck={initiateCheck}
        setInitiateCheck={setInitiateCheck}
        transCust={transCust}
        setTransCust={setTransCust}
        getToFinal={getToFinal}
        setGetToFinal={setGetToFinal}
        customerId={customerId}
        jumpToFinal={jumpToFinal}
        fetchedTotal={fetchedTotal}
        setFetchedTotal={setFetchedTotal}
        handleJumla={handleJumla}
      />
      <BottomScan
        camShow={camShow}
        setCamShow={setCamShow}
        setFinishSelected={setFinishSelected}
        finishSelected={finishSelected}
        handleUpdates={handleUpdates}
        handlePaid={handlePaid}
        printer={printer}
        setPrinter={setPrinter}
        setEditOrder={setEditOrder}
        product={product}
        setEditProcess={setEditProcess}
        editProcess={editProcess}
        initiateCheck={initiateCheck}
        setInitiateCheck={setInitiateCheck}
        jumpToFinal={jumpToFinal}
        setGetToFinal={setGetToFinal}
      />
    </div>
  );
};

export default Scanning;
