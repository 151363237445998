import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import moment from "moment";
import { BsHourglassSplit, BsCheck2All } from "react-icons/bs";

const StocksCustomer = ({ todaysUnix }) => {
  const [totalTransactions, setTotalTransaction] = useState(null);
  const todaysM = moment(todaysUnix).format("MM");
  const todaysY = moment(todaysUnix).format("YYYY");
  const todaysD = moment(todaysUnix).format("DD");
  const [sortedTransactions, setSortedTransactions] = useState(null);

  useEffect(() => {
    const loader = async () => {
      try {
        const resTrans = await userRequest.get("/transactions");

        setTotalTransaction(resTrans.data);
      } catch (err) {
        console.log(err);
      }
    };

    loader();
  }, []);

  useEffect(() => {
    const all = async () => {
      const sortingTrans = [];
      let sortingPays = [];
      const daySorting = async () => {
        await totalTransactions?.map(async (d) => {
          const cuttingOff = d?.createdAt.slice(0, 19);
          const dayssorting = moment(
            cuttingOff,
            "YYYY-MM-DDTHH:mm:ss"
          ).valueOf();
          const dDay = moment(dayssorting).format("DD");
          const dMonth = moment(dayssorting).format("MM");
          const dYear = moment(dayssorting).format("YYYY");

          if (
            `${dDay}/${dMonth}/${dYear}` === `${todaysD}/${todaysM}/${todaysY}`
          ) {
            sortingTrans.push(d);

            console.log(d);
          }
        });

        setSortedTransactions(sortingTrans);
      };

      await daySorting();
    };

    all();
  }, [todaysUnix, totalTransactions]);

  return (
    <Container>
      <Contain>
        <Header>
          <tr>
            <td>Customer Name</td>
            <td>transID</td>
            <td>Amount</td>
            <td>Status</td>
          </tr>
        </Header>

        <Stocks>
          {sortedTransactions?.map((d) => {
            const orderSetting = () => {
              if (d.orderstatus === "paid") {
                return true;
              } else if (d.orderstatus === "credit") {
                return false;
              }
            };

            return (
              <Product key={d?.orderId}>
                <td>
                  <div>{d?.customerName}</div>
                </td>
                <td>
                  <div>{d?.orderId.slice(0, -12)}</div>
                </td>
                <td>
                  <div>{d?.amount}</div>
                </td>
                <td>
                  <div>
                    {orderSetting() ? <BsCheck2All /> : <BsHourglassSplit />}
                  </div>
                </td>
              </Product>
            );
          })}
        </Stocks>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 20%;
  bottom: 10%;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`;

const Contain = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 20%;
`;

const Stocks = styled(motion.tbody)`
  height: 20%;
  width: 100%;
`;

const Header = styled(motion.thead)`
  text-align: center;
  padding-bottom: 1rem;
`;

const Product = styled(motion.tr)`
  text-align: center;
`;

export default StocksCustomer;
