import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { logins, logsout } from "../../redux/apiCalls";
import { useDispatch, useSelector } from "react-redux";
import { publicRequest } from "../../requestmethods";
import moment from "moment";
import { authentication } from "../../regs/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import OtpLogin from "../../components/login/otp";
import AuthLogin from "../../components/login/auth";
import LoadingSpinner from "../../components/LoadingSpinner";
import { useHistory } from "react-router";

//start
const Login = () => {
  const dispatch = useDispatch();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [authOtp, setAuthOtp] = useState("");
  const [enableEntry, setEnableEntry] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [enterOtp, setEnterOtp] = useState(false);
  const [enterAuthOtp, setEnterAuthOtp] = useState(false);
  const [firstFetch, setFirstFetch] = useState(null);
  const [errMsg, setErrMsg] = useState(null);
  const [checkClear, setCheckClear] = useState(false);
  const [errOtp, setErrOtp] = useState(false);
  const [errAuthOtp, setErrAuthOtp] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [otpLoading, setOtpLoading] = useState(false);
  const [authOtpLoading, setAuthOtpLoading] = useState(false);
  const [logged, setIsLogged] = useState(false);
  const User = useSelector((state) => state.user.currentUser);
  const success = useSelector((state) => state.user.isFetching);
  const timing = useSelector((state) => state.user.timer);
  const history = useHistory();

  useEffect(() => {
    const logging = () => {
      if (User?.accessToken && logged && !success && timing) {
        history.push(`/`);
      }
    };

    logging();
  }, [logged, User?.accessToken, timing, success]);

  useEffect(() => {
    const generateRecaptcha = () => {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
          callback: (response) => {},
        },
        authentication
      );
    };

    const requestOTP = async () => {
      if (phoneNumber.length >= 10) {
        setEnableEntry(true);
        generateRecaptcha();
        const appVerifier = window.recaptchaVerifier;
        await signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;

            setEnterOtp(true);
          })
          .catch((error) => {
            // Error; SMS not sent
            console.log(error);
          });
      }
    };

    requestOTP();
  }, [firstFetch]);

  const stageCheck = () => {
    if (username && phoneNumber && password) {
      return true;
    } else {
      return false;
    }
  };

  const verifyAuthOTP = async (e) => {
    let authotp = e.target?.value;

    setAuthOtp(authotp);

    if (authotp.length === 6) {
      setAuthOtpLoading(true);

      const confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          // User signed in successfully.
          const user = await result?.user;
          const stringer = parseFloat(firstFetch?.phonenumber);
          const phoner = "+" + stringer;

          if (firstFetch?.pUid === user?.uid && phoner === user?.phoneNumber) {
            const poster = {
              username: username,
              password: password,
              phonenumber: firstFetch?.phonenumber,
              token: user?.accessToken,
              iD: firstFetch?.pUid,
              code: authotp,
            };

            if (user?.accessToken) {
              await logins(dispatch, poster);

              setIsLogged(true);
              //history.push(`/`);
            }
          }

          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log(error);
          setErrAuthOtp(true);
          setInterval(() => {
            setOtpLoading(false);
          }, 1500);
        });
    }
  };

  const verifyOTP = async (e) => {
    let otp = e.target?.value;
    setOtp(otp);
    if (otp.length === 6) {
      if (firstFetch?.secretstatus) {
        setOtpLoading(true);

        setEnterOtp(false);

        setEnterAuthOtp(true);
      } else {
        const confirmationResult = window.confirmationResult;

        confirmationResult
          .confirm(otp)
          .then(async (result) => {
            // User signed in successfully.
            const user = await result?.user;
            const stringer = parseFloat(firstFetch?.phonenumber);
            const phoner = "+" + stringer;

            if (
              firstFetch?.pUid === user?.uid &&
              phoner === user?.phoneNumber
            ) {
              const poster = {
                username: username,
                password: password,
                phonenumber: firstFetch?.phonenumber,
                token: user?.accessToken,
                iD: firstFetch?.pUid,
              };

              if (user?.accessToken) {
                logins(dispatch, poster);

                history.push(`/`);
              }
            }

            // ...
          })
          .catch((error) => {
            // User couldn't sign in (bad verification code?)
            console.log(error);
            setErrOtp(true);
            setInterval(() => {
              setOtpLoading(false);
            }, 1500);
          });
      }
    }
  };

  const handleRequest = async (e) => {
    e.preventDefault();

    if (stageCheck()) {
      setIsLoading(true);
      const phone = parseInt(phoneNumber.slice(1, 13));

      const poster = {
        username: username,
        password: password,
        phonenumber: phoneNumber,
        phone: phone,
      };

      logsout(dispatch);

      try {
        const res = await publicRequest.post("/auth/login", poster);

        if (res.statusText === "OK") {
          setFirstFetch(res?.data);
        }
      } catch (err) {
        console.log(err);
        setErrMsg(true);
        setIsLoading(false);
      }
    } else {
      setCheckClear(true);
    }
  };

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {enterOtp && (
            <OtpLogin
              enterOtp={enterOtp}
              setEnterOtp={setEnterOtp}
              otp={otp}
              setOtp={setOtp}
              verifyOTP={verifyOTP}
              errOtp={errOtp}
              otpLoading={otpLoading}
              setOtpLoading={setOtpLoading}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {enterAuthOtp && (
            <AuthLogin
              enterAuthOtp={enterAuthOtp}
              setEnterAuthOtp={setEnterAuthOtp}
              authOtp={authOtp}
              setAuthOtp={setAuthOtp}
              verifyAuthOTP={verifyAuthOTP}
              errAuthOtp={errAuthOtp}
              authOtpLoading={authOtpLoading}
              setAuthOtpLoading={setAuthOtpLoading}
              isLoading={isLoading}
              setIsLoading={setIsLoading}
            />
          )}
        </AnimatePresence>
        <Wrapper>
          <Title>LOGIN</Title>
          <Form>
            <Data>
              <Input
                type="tel"
                placeholder="phone number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Input
                type="username"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                type="password"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />
            </Data>
            {errMsg && (
              <Err>
                <div className="err">Wrong Credentials please try again</div>
              </Err>
            )}
            {checkClear && (
              <Err>
                <div className="err">Please fill in all the required field</div>
              </Err>
            )}

            {!isLoading ? (
              <Button onClick={handleRequest}>
                <button>Login </button>
              </Button>
            ) : (
              <div className="loader">
                <LoadingSpinner />
              </div>
            )}
          </Form>
        </Wrapper>

        <div id="recaptcha-container"></div>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`

#recaptcha-container{
    position:absolute:
    bottom:0;
    height:5rem;
    width:5rem;
    right:0;
    margin-right:10%;

}

.loader{
  margin-top:2%;
  display:grid;
  align-items:center;
  justify-items:center;

}
`;

const Wrapper = styled(motion.div)``;

const Title = styled(motion.div)`
  height: 10vh;
  display: grid;
  align-items: center;
  justify-items: center;
  font-size: 1.5rem;
  font-weight: 100;
`;

const Data = styled(motion.div)`
  display: grid;
  margin-top: 50%;
  margin-left: 5%;
  margin-right: 5%;

  justify-items: center;
  align-items: center;
`;
const Form = styled(motion.form)``;
const Input = styled(motion.input)`
  width: 100%;
  height: 100%;
  margin: 0.5rem;
  padding: 0.5rem;
  border: 0.04rem solid gray;
`;
const Agreement = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  margin: 1rem;
  font-size: 0.6rem;
`;

const Err = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;

  .err {
    text-align: center;
    color: #ff7276;
  }
`;

const Button = styled(motion.div)`
  display: grid;
  height: 5vh;
  width: 100%;

  grid-template-rows: 1fr;
  justify-items: center;
  align-items: center;
  margin: 0.5rem 0.5rem 2rem 0.5rem;

  button {
    height: 100%;
    width: 80%;
    border-radius: 20rem;
    border: 0.02rem solid gray;
    -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
  }
`;

export default Login;
