import React, { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";

const CustomerPrinting = ({
  customerDets,
  transDate,
  transStatus,
  madeOrder,
  imageUrl,
  clicked,
}) => {
  const cart = useSelector((state) => state.cart);
  const [cartons, setCartons] = useState(null);

  useEffect(() => {
    const totalCtns = [];

    cart.products?.map((d) => {
      totalCtns.push(d.quantity);
    });

    const totals = totalCtns.reduce((a, b) => a + b, 0);

    setCartons(totals);
  }, [cart.products]);

  return (
    <Container>
      <Customers className="dataCust">
        <Title>
          <div className="title">
            <div>WYL,</div>
            <div>0754266428, 0754302272.</div>
          </div>
          <div className="lowertitle">
            <div className="left">
              <div className="one">{customerDets?.username}</div>
            </div>

            <div className="right">
              <div className="one">{transDate}</div>
              <div className="two">{transStatus}</div>
            </div>
          </div>
        </Title>

        <Item cart={cart}>
          <div className="container">
            {madeOrder?.products?.map((d) => {
              return (
                <div key={d._id} className="item">
                  <div className="artno">
                    <div>{d.artNo}</div>
                  </div>
                  <div className="qty">
                    <div className="">
                      <div className="">ctns {d.quantity}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="ctns">
            <div className="left">Total cartons</div>
            <h2 className="right">{cartons && cartons}</h2>
          </div>
        </Item>

        <Generate>
          <div className="img">
            {imageUrl ? (
              <a href={imageUrl && imageUrl} download>
                <img src={imageUrl && imageUrl} alt="img" />
              </a>
            ) : null}
          </div>
        </Generate>
        <div className="end">End of Customer Receipt</div>
      </Customers>
    </Container>
  );
};
const Container = styled(motion.div)`
  scroll-behavior: smooth;
  margin-top: 2.5%;
`;
const Customers = styled(motion.div)`
  margin-top: 15%;

  width: 100%;

  .end {
    margin-top: 5%;
    margin-bottom: 5%;
  }
`;

const Title = styled(motion.div)`
  width: 100%;

  .title {
    width: 100%;
    text-align: center;
  }

  .lowertitle {
    display: grid;
    grid-template-columns: 50% 50%;

    .left {
      display: grid;
      align-items: center;

      font-size: 200%;
      .one {
        margin-left: 5%;
        font-size: 100%;
      }
    }
    .right {
      display: grid;
      align-items: center;
      justify-items: end;
      .one {
        margin-right: 5%;
      }
      .two {
        margin-right: 5%;
      }
    }
  }
`;
const Item = styled(motion.div)`
  display: grid;
  margin-left: 5%;
  .container {
    display: grid;
    grid-template-columns: ${({ cart }) =>
      cart.quantity[0] >= 10 ? "32% 32% 32%" : "100%"};
  }
  grid-gap: 5%;

  .item {
    display: grid;
    grid-template-columns: 60% 40%;
    border-right: 0.01rem dotted black;
    grid-gap: 0.5%;
  }

  .ctns {
    display: grid;
    grid-template-columns: 50% 50%;
    margin-top: 2%;
    padding: 2%;

    .qty {
    }
    .left {
      text-align: start;
    }

    .right {
    }
  }
`;

const Generate = styled(motion.div)`
  width: 100%;

  display: grid;
  justify-items: center;

  .img {
    height: 50%;
  }

  .topname {
  }
`;
export default CustomerPrinting;
