import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import UserPage from "./userpage";
import BottomNav from "../../components/bottomnav/bottomhomenav";

const PersonalPage = () => {
 

  return (
    <Body>
      <Navbar />
      <UserPage />
      <BottomNav />
    </Body>
  );
};
const Body = styled(motion.div)``;

export default PersonalPage;
