import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const NavbarDate = ({ todaysDate, setTodaysDate, setStringDate }) => {
  const handleChange = (e) => {
    const change = e.target.value;

    //console.log(change);
    setTodaysDate(change);
    const m = change.slice(5, -3);
    const d = change.slice(8);
    const y = change.slice(0, -6);

    setStringDate(`${m}/${d}/${y}`);

    // console.log(`${m}/${d}/${y}`);
  };

  return (
    <Container>
      <Data>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="title">
            <h2>UNISON</h2>
          </div>
        </Link>
        <div>
          <div className="sidedata">
            <input type="date" onChange={handleChange} value={todaysDate} />
          </div>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  @media (max-width: 540px) {
  }
`;

const Data = styled(motion.div)`
  position: absolute;
  z-index: 1;
  height: 20%;
  width: 100%;
  background-image: linear-gradient(to left top, blue, red);
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;

  .title {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sidedata {
    display: grid;
    justify-items: end;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;

    input {
      border-radius: 1rem;
      padding: 0.2rem;
      border: 0.01rem solid gray;
      margin-bottom: 1%;
    }
  }
`;

const Items = styled(motion.div)``;
export default NavbarDate;
