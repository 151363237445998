import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import SimilarSize from "./similiarSize";

const SimilarSizes = ({ products }) => {
  return (
    <Container>
      {products && (
        <Item sizeno={products.nosimilarproducts}>
          <table>
            <tbody>
              <tr>
                {products.similarproducts?.map((s) => (
                  <td key={s}>
                    <SimilarSize size={s} key={s} />
                  </td>
                ))}
              </tr>
            </tbody>
          </table>
          <div className="othersizes">
            <h4>similar products </h4>
          </div>
        </Item>
      )}
    </Container>
  );
};

const Container = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    justify-items: center;
    align-items: center;
    width: 90%;
    height: 3vh;
  }
`;

const Item = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 4fr 1fr;
    justify-items: end;
    align-items: center;

    table {
      height: 2vh;
      width: 90%;
      border: 0.02rem solid gray;
      border-radius: 2rem;
      font-size: 1rem;
    }
    td:last-child {
      border-left: 0.02rem solid gray;
    }
    td:first-child {
      border-right: 0.02rem solid gray;
    }

    .othersizes {
      font-size: 1rem;
      word-wrap: break-word;
      display: grid;
      margin-left: 0.5rem;
      align-items: center;
      justify-items: start;
    }
  }
`;

export default SimilarSizes;
