import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { publicRequest, userRequest } from "../../requestmethods";
import { IoIosSearch } from "react-icons/io";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";
import { HiOutlineUserCircle } from "react-icons/hi";

const RegisteredUsers = ({ startSearch, setStartSearch }) => {
  const [fetchedUsers, setFetchedUsers] = useState([]);
  const [cat, setCat] = useState("");
  const [sort, setSort] = useState("newest");
  const [view, setView] = useState(8);
  const [assesedUsers, setAssessedUsers] = useState([]);
  const [maxLength, setMaxLength] = useState(null);
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    const usersFetching = async () => {
      const resUsers = await userRequest.get(`/users`);

      setFetchedUsers(resUsers?.data);
      resUsers?.data && setMaxLength(resUsers?.data?.length);
    };

    usersFetching();
  }, []);

  useEffect(() => {
    const maxi = () => {
      if (maxLength <= view) {
        setShowLess(true);
      }

      if (maxLength === 0) {
        setView(0);
      }
    };

    maxLength && maxi();
  }, [view, maxLength]);

  useEffect(() => {
    const controller = () => {
      const miniControl = () => {
        if (fetchedUsers[0] === undefined || maxLength === 0) {
          setShowLess(true);
        }
      };

      if (maxLength && view >= maxLength) {
        setView(maxLength);
        setShowLess(true);
      }
      if (maxLength && view >= maxLength && miniControl()) {
        setView(maxLength);
        setShowLess(true);
      }

      if (maxLength > view) {
        setShowLess(false);
      }

      miniControl();
    };
    maxLength && controller();
  }, [view, fetchedUsers, maxLength]);

  useEffect(() => {
    const itemViewer = () => {
      const viewer = [];

      for (let i = 0; i < view; i++) {
        viewer.push(fetchedUsers[i]);
      }
      setAssessedUsers(viewer);
    };

    itemViewer();
  }, [fetchedUsers, cat, view]);

  return (
    <Container>
      <Contain>
        {startSearch && (
          <FilterContainer className={startSearch ? "nonfilter" : "filter"}>
            <div className="textDiv">
              <input type="text" onChange={(e) => setCat(e.target.value)} />
            </div>
            <div className="searchDiv">
              <IoIosSearch size={25} className="search" />
            </div>
          </FilterContainer>
        )}

        <Body>
          <Users>
            {assesedUsers.length &&
              assesedUsers?.map((d, key) => {
                return (
                  <User key={key}>
                    {d?.img ? (
                      <Img>
                        <img src={d?.img} alt="" />
                      </Img>
                    ) : (
                      <div className="icon">
                        <HiOutlineUserCircle size={30} />
                      </div>
                    )}
                    <Info>
                      <div className="title">
                        <div>ID| {d?.username}</div>
                        <div>
                          status|{" "}
                          {d?.isWorker || d?.isAdmin ? "Worker" : "Customer"}
                        </div>
                      </div>
                      <div className="info">
                        <div>access| {d?.secretstatus ? "all" : "lim"}</div>
                        <div>last login| to come</div>
                        <div>
                          phone number| {d?.phonenumber?.toString().slice(0, 8)}
                        </div>
                      </div>
                    </Info>
                    <Edit>
                      <Link
                        to={`userlist/${d?._id}`}
                        style={{ textDecoration: "none" }}
                        className="link"
                      >
                        <button>edit</button>
                      </Link>
                    </Edit>
                  </User>
                );
              })}
          </Users>
          <More>
            {!showLess && assesedUsers[0] && (
              <div onClick={() => setView(view + 8)}>load more ...</div>
            )}
          </More>
        </Body>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  width: 100%;
  top: 20%;
  bottom: 10%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Users = styled(motion.div)``;

const User = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;

  align-items: center;
  margin-bottom: 1rem;

  .icon {
    height: 100%;
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Contain = styled(motion.div)``;

const Body = styled(motion.div)`
  position: absolute;
  width: 100%;
  display: grid;
  grid-gap: 0.5%;
`;

const Img = styled(motion.div)`
  height: 100%;
  width: 100%;

  img {
    max-height: 90%;
    max-width: 90%;
  }
`;

const Info = styled(motion.div)`
  display: grid;

  .title {
  }
`;

const Edit = styled(motion.div)`
  display: grid;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;

  .link {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  button {
    width: 80%;
    height: 30%;
    margin-top: 35%;
    margin-bottom: 35%;
    border: none;
    border-radius: 2rem;
  }
`;

const FilterContainer = styled(motion.div)`
  position: absolute;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  width: 100%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 5%;
  z-index: 5;
  background: white;

  .textDiv {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: end;
    align-items: center;
    input {
      width: 90%;
      height: 70%;
      padding: 5%;
      border-radius: 2rem 0 0 2rem;
      border: 0.02rem solid gray;
    }
  }

  .searchDiv {
    border: 0.02rem solid gray;
    display: grid;
    justify-items: start;
    align-items: center;
    width: 50%;
    height: 70%;
    border-radius: 0 2rem 2rem 0;
  }
`;

const More = styled(motion.div)`
  display: grid;
  align-items: center;
  justify-items: end;

  margin-right: 4.5%;
`;

export default RegisteredUsers;
