import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { userRequest } from "../../requestmethods";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import QRcode from "qrcode";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { empty } from "../../redux/cartredux";
import { empty_ii } from "../../redux/cartredux_two";
import { empty_iii } from "../../redux/cartredux_three";
import { empty_iv } from "../../redux/cartredux_four";
import { empty_v } from "../../redux/cartredux_five";
import { toPng } from "html-to-image";

import CustPrinting from "./custprinting";

const Printing = ({
  customerDets,
  orderCode,
  setPrinting,
  setFinalCheck,
  amountPaid,
  printing,
  page,
  finalCut,
  setOrderCode,
}) => {
  //start state
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.currentUser);
  const componentRef = useRef(null);
  const [madeOrder, setMadeOrder] = useState([]);
  const [paidAmount, setPaidAmount] = useState(null);
  const [paidTotal, setPaidTotal] = useState(null);
  const [toBePaid, setToBePaid] = useState(null);
  const [filtered, setFiltered] = useState([]);
  const [transDate, setTransDate] = useState(null);
  const [transStatus, setTransStatus] = useState(null);
  const [imageUrl, setImageUrl] = useState([]);
  const dispatch = useDispatch();
  const [clicked, setClicked] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [cartons, setCartons] = useState(null);
  //end state

  useEffect(() => {
    const totalCtns = [];

    cart.products?.map((d) => {
      totalCtns.push(d.quantity);
    });

    const totals = totalCtns.reduce((a, b) => a + b, 0);

    setCartons(totals);
  }, [cart.products]);

  useEffect(() => {
    console.log(filtered);
  }, []);

  useEffect(() => {
    console.log("page", page);
    console.log("finalcut", finalCut);
  }, []);

  const turnPng = () => {
    imageUrl &&
      toPng(componentRef.current, {
        cacheBust: true,
        backgroundColor: "white",
        skipAutoScale: true,
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "wylord.png";
          // link.href = dataUrl;
          link.click();
          setClicked(true);
          setIsChecked(!isChecked);

          let interval = setInterval(() => {
            setClicked(false);
          }, 100);

          return () => {
            clearInterval(interval);
          };
        })
        .catch((err) => {
          console.log(err);
        });
  };

  const downloading = () => {
    imageUrl &&
      toPng(componentRef.current, {
        cacheBust: true,
        backgroundColor: "white",
        skipAutoScale: true,
      })
        .then((dataUrl) => {
          const link = document.createElement("a");
          link.download = "wylord.png";
          link.href = dataUrl;
          link.click();
          setClicked(true);

          let interval = setInterval(() => {
            setClicked(false);
          }, 500);

          return () => {
            clearInterval(interval);
          };
        })
        .catch((err) => {
          console.log(err);
        });
  };

  useEffect(() => {
    const customDetails = async () => {
      try {
        await orderCode?.map(async (d) => {
          if (user?.isAdmin || user?.isWorker) {
            const instore = await userRequest.get(`/orders/find/${d}`);

            // setMadeOrder((a) => [...a, instore?.data[0]]);

            setTransStatus("");
            setToBePaid("");

            setPaidTotal("");

            if (instore?.statusText === "OK") {
              const generateQrcode = async () => {
                try {
                  const response = await QRcode.toDataURL(d);
                  response &&
                    setMadeOrder((a) => [
                      ...a,
                      { data: instore?.data[0], img: response },
                    ]);
                } catch (err) {
                  console.log(err);
                }
              };
              await generateQrcode();
            }
          }
        });
      } catch (err) {
        console.log(err);
      }
    };

    customDetails();
  }, [orderCode]);

  const closer = () => {
    finalCut.map((d) => {
      if (d === 0) {
        dispatch(empty());
      }
      if (d === 1) {
        dispatch(empty_ii());
      }
      if (d === 2) {
        dispatch(empty_iii());
      }
      if (d === 3) {
        dispatch(empty_iv());
      }
      if (d === 4) {
        dispatch(empty_v());
      }
    });
    setPrinting(false);
    setFinalCheck(false);
    setFiltered([]);
    setOrderCode([]);
  };

  useEffect(() => {
    const filtering = madeOrder.filter(
      (v, i, a) => a.findIndex((v2) => v2.data.orderId === v.data.orderId) === i
    );

    setFiltered(filtering);
  }, [madeOrder]);

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <div className="close">
          <IoIosCloseCircleOutline
            size={25}
            fontWeight={100}
            onClick={() => closer()}
          />
        </div>

        <Center>
          <div ref={componentRef} className="data">
            {filtered?.map((x, index) => (
              <div key={index}>
                <Title>
                  <div>
                    <div className="title">
                      <div>WYL,</div>
                      <div>0754266428, 0754302272.</div>
                    </div>
                    <div className="lowertitle">
                      <div className="left">
                        <div className="one">{x.data?.userId}</div>
                      </div>

                      <div className="right">
                        <div className="one">
                          {moment(x.data?.createAt).format("DD/MM/YYYY, HH:mm")}
                        </div>
                        <div className="two">{""}</div>
                      </div>
                    </div>
                  </div>
                </Title>
                <Table>
                  <TabHead>
                    <div className="left">
                      <div>artNo</div>
                    </div>
                    <div className="right">
                      <div className="inleft">
                        <div>price</div>
                        <div>pcs</div>
                      </div>
                      <div className="inright">
                        <div>total</div>
                      </div>
                    </div>
                  </TabHead>
                  <Balance>
                    {x.data.products?.map((d) => {
                      return (
                        <div key={d?._id} className="item">
                          <div className="listing">
                            <div className="artno">
                              <h2>{d?.artNo}</h2>
                            </div>
                            <div className="right">
                              <div className="fixed">
                                <h2 className="price">{d?.price}</h2>
                                <h2 className="pcs">{d?.pieces}</h2>
                              </div>
                              <div className="flex">
                                <div className="inflex">
                                  <h2>ctns</h2>
                                  <h2 className="ctns">{d?.quantity}</h2>
                                </div>
                                <div className="total">
                                  <h2> {d?.price * d?.pieces * d?.quantity}</h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </Balance>
                </Table>
                <Mid>
                  <tbody>
                    <tr className="leftrow">
                      <td className="one">total cost</td>

                      <td>
                        <div></div>
                      </td>
                      <td>
                        <div></div>
                      </td>
                      <td className="two">{x.data?.amount}</td>
                    </tr>
                    <tr>
                      <td className="one">total ctns</td>

                      <td>
                        <div></div>
                      </td>
                      <td>
                        <div></div>
                      </td>
                      <td className="two">
                        {x.data.products?.reduce(
                          (n, { quantity }) => n + quantity,
                          0
                        )}
                      </td>
                    </tr>
                  </tbody>
                </Mid>
                <Bottom>
                  <tbody className="payments">
                    <tr>
                      <td className="paying">Payments</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                    </tr>
                  </tbody>
                  <Body>
                    {paidAmount?.map((d) => {
                      return (
                        <tr key={d?._id}>
                          <td className="dd">
                            {moment.unix(d?.date).format("DD/MM/YYYY, HH:mm")}
                          </td>
                          <td></td>
                          <td></td>
                          <td></td>
                          <td className="amount">{d?.amount}</td>
                        </tr>
                      );
                    })}
                  </Body>
                </Bottom>
                <Remainder>
                  <tbody>
                    <tr>
                      <td>Balance</td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="balance">{paidTotal - toBePaid}</td>
                    </tr>
                  </tbody>
                </Remainder>

                <Generate>
                  <div className="img">
                    {x?.img ? (
                      <div>
                        <img src={x?.img} alt="img" />
                      </div>
                    ) : null}
                  </div>
                </Generate>
                <div className="end">End of Store Receipt</div>
                {
                  <CustPrinting
                    customerDets={x.data?.userId}
                    transDate={moment(x.data?.createAt).format(
                      "DD/MM/YYYY, HH:mm"
                    )}
                    transStatus={transStatus}
                    madeOrder={x?.data}
                    imageUrl={x?.img}
                    clicked={clicked}
                    cartons={cartons}
                  />
                }
              </div>
            ))}
          </div>
        </Center>
        <Button>
          <div className="button">
            {isChecked ? (
              <button onClick={isChecked && downloading}>print order</button>
            ) : (
              <button onClick={turnPng}>check complete</button>
            )}
          </div>
        </Button>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .data {
    width: 100%;
  }

  .close {
    width: 90%;
    height: 5%;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    align-items: center;
    justify-items: end;
    margin-top: 0%;
    border-bottom: 0.01rem dashed gray;
  }
`;

const Title = styled(motion.div)`
  max-width: 100%;

  .topname {
    max-width: 100%;
  }

  .top {
    font-size: 150%;
  }

  tr {
    display: grid;

    align-items: center;
    justify-items: center;
  }

  .title {
    width: 100%;
    text-align: center;
  }

  thead {
    width: 100%;
  }

  .lowertitle {
    display: grid;
    grid-template-columns: 50% 50%;

    .left {
      display: grid;
      align-items: center;

      font-size: 200%;
      .one {
        margin-left: 5%;
        font-size: 100%;
      }
    }
    .right {
      display: grid;
      align-items: center;
      justify-items: end;
      .one {
        margin-right: 5%;
      }
      .two {
        margin-right: 5%;
      }
    }
  }
`;

const Table = styled(motion.div)`
  width: 100%;
  margin-bottom: 5%;
  border-top: 0.01rem dashed gray;
`;

const TabHead = styled(motion.div)`
  display: grid;
  grid-template-columns: 30% 70%;
  grid-gap: 1%;

  font-size: 90%;

  .left {
    display: grid;
    justify-items: center;
  }

  .right {
    display: grid;
    grid-template-columns: 50% 50%;

    .inleft {
      display: grid;
      grid-template-columns: 60% 40%;
      align-items: center;
      justify-items: center;
      width: 100%;
      height: 100%;
    }

    .inright {
      display: grid;
      align-items: center;
      justify-items: center;
    }
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  background: white;
  position: absolute;
  color: black;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  bottom: 0;

  .data {
    @media (print) {
      @page {
        size: 80mm 50mm;
      }
    }
  }

  .dataCust {
    @media (print) {
      @page {
        size: 80mm 50mm;
      }
    }
  }
`;

const Center = styled(motion.div)`
  position: absolute;
  height: 85%;
  top: 5%;
  overflow-y: scroll;
  overflow-x: hidden;

  .end {
    margin-top: 5%;
    margin-bottom: 5%;
  }
`;

const Generate = styled(motion.div)`
  width: 100%;
  display: grid;
  justify-items: center;

  .img {
    height: 50%;
  }

  .topname {
  }
`;

const Balance = styled(motion.div)`
  width: 100%;

  .listing {
    display: grid;
    grid-template-columns: 30% 70%;
    grid-gap: 1%;
    font-size: 100%;
    align-items: center;
    margin-top: 2.5%;
    margin-bottom: 2.5%;
    border-bottom: 0.1rem dotted black;
  }

  .right {
    display: grid;

    grid-template-rows: 50% 50%;
  }

  .artno {
    display: grid;
    align-items: center;

    height: 100%;
  }

  .fixed {
    display: grid;
    grid-template-columns: 30% 20%;
    justify-items: center;
  }

  .flex {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .artno {
    display: grid;
    justify-items: center;
  }

  .inflex {
    display: grid;
    grid-template-columns: 60% 40%;
    align-items: center;
    justify-items: center;
  }

  .total {
    display: grid;
    justify-items: center;
  }
`;

const Mid = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 150%;
  tr {
    display: grid;
    grid-template-columns: 40% 10% 10% 40%;
    align-items: center;
    justify-items: center;
  }

  .leftrow {
    border-bottom: 0.01rem dotted black;
  }

  .one {
    text-align: start;
    width: 100%;
  }

  .two {
    text-align: end;
    width: 100%;
    font-size: 150%;
  }
`;

const Bottom = styled(motion.table)`
  font-size: 150%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-top: 0.01rem dashed gray;
  margin-top: 2%;

  .payments {
    border-top: 0.01rem dashed gray;

    tr {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      justify-items: center;

      .paying {
        border-bottom: 0.01rem solid gray;
      }
    }
  }
`;

const Top = styled(motion.thead)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  .top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Body = styled(motion.tbody)`
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .dd {
    text-align: start;
  }

  .amount {
    font-size: 150%;
  }
`;

const Remainder = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;

  border-top: 0.01rem dashed gray;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }

  .balance {
    font-size: 150%;
  }
`;

const Button = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 10%;
  display: grid;
  align-items: center;
  justify-items: center;
  border-top: 0.01rem solid gray;

  .button {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 60%;
    border-radius: 2rem;
    height: 50%;
    background: gray;
    color: white;

    button {
      width: 100%;
      border: none;
      background: none;

      color: white;
    }
  }
`;

export default Printing;
