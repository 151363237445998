import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import axios from "axios";

const SimilarSize = ({ size }) => {
  const [product, setProduct] = useState([]);

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await axios.get(
          size ? `http://localhost:5000/api/products/find/${size}` : ""
        );

        setProduct(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    
    getProduct();
  }, [size]);
  return (
    <Container>
      <Link to={`/product/${size}`} className="link">
        {product && <div>{product.artNo}</div>}
      </Link>
    </Container>
  );
};

const Container = styled(motion.div)`
@media (max-width: 768px) {
  display: grid;
  align-items: center;
  justify-items: center;
  margin:0.2rem;
  padding:0.2rem
  width:100%;

  .link {
    text-decoration: black;
    color: black;
  }
}
`;

export default SimilarSize;
