import "./newProduct.css";
import { useState } from "react";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import moment from "moment";
import { userRequest } from "../../requestmethods";
import { useEffect } from "react";
import HalfSpinner from "../../components/loaders/halfspinner";
import Compressor from "compressorjs";
import ImgSmall from "./smallmg";
import ImgMedium from "./mediumImg";

export default function NewProduct() {
  const [input, setInput] = useState({});
  const [file, setFile] = useState(null);
  const [similarSize, setSimilarSizes] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [color, setColor] = useState([]);
  const [cat, setCat] = useState([]);
  const date = moment().unix();
  const [productMade, setProductMade] = useState(null);
  const [noOfStock, setNoOfStock] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [uploadedNo, setUploadedNo] = useState(false);
  const [imgSmall, setImgSmall] = useState(null);
  const [imgMedium, setImgMedium] = useState(null);
  const [firstUpload, setFirstUpload] = useState(false);
  const [productId, setProductId] = useState(null);

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleCat = (e) => {
    setCat(e.target.value.split(","));
  };

  const handleColor = (e) => {
    setColor(e.target.value.split(","));
  };

  const handleSimilarProducts = (e) => {
    setSimilarProducts(e.target.value.split(","));
  };

  const handleOtherSizes = (e) => {
    setSimilarSizes(e.target.value.split(","));
  };

 

  const handleClick = (e) => {
    e.preventDefault();
    setIsLoading(true);

    const fileName = new Date().getTime() + file.name;

    const storage = getStorage(app);

    const storageRef = ref(storage, fileName);

    const uploadTask = uploadBytesResumable(storageRef, file);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            try {
              const product = {
                ...input,
                img: downloadURL,
                imgS: "",
                imgM: "",
                categories: cat,
                similarsize: similarSize,
                similarproducts: similarProducts,
                color: color,
              };
              console.log(product);

              const resProduct = await userRequest.post("/products", product);

              setProductMade(resProduct?.data);

              resProduct && console.log(resProduct);

              if (resProduct?.statusText === "OK") {
                setProductId(resProduct?.data._id);
                setFirstUpload(true);
              }

              //   setIsLoading(false);
            } catch (err) {
              console.log(err);
            }
          }
        );
      }
    );
  };

  useEffect(() => {
    try {
      if (productMade) {
        const truckProduct = {
          productId: productMade._id,
          productArtNo: productMade.artNo,
          added: [
            {
              amount: noOfStock,
              date: date,
            },
          ],
          sales: [],
        };

        const resTrack = userRequest.post("/producttrack", truckProduct);
      }
    } catch (err) {
      console.log(err);
    }
  }, [productMade]);

  useEffect(() => {
    const handleCompressedUpload = () => {
      const image = file;
      /* new Compressor(image && image, {
        quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const results = compressedResult;
          console.log(results);
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setImgSmall(results);
        },
      });*/
      new Compressor(image && image, {
        quality: 0.3, // 0.6 can also be used, but its not recommended to go below.
        success: (compressedResult) => {
          const results = compressedResult;
          console.log(results);
          // compressedResult has the compressed file.
          // Use the compressed file to upload the images to your server.
          setImgMedium(results);
        },
      });
    };

    file && handleCompressedUpload();
  }, [file]);

  return (
    <div className="newProduct">
      <h1 className="addProductTitle">New Product</h1>
      <form className="addProductForm">
        <div className="addProductItem">
          <label>Image</label>
          <input
            type="file"
            id="file"
            onChange={(e) => setFile(e.target.files[0])}
          />
        </div>
        <div className="addProductItem">
          <label>Title</label>
          <input
            type="text"
            name="title"
            placeholder="kids"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>header</label>
          <input
            type="text"
            name="header"
            placeholder="kids shoes"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>artNo</label>
          <input
            type="text"
            name="artNo"
            placeholder="205"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>description</label>
          <input
            type="text"
            name="desc"
            placeholder="size range etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>categories</label>
          <input
            type="text"
            name="categories"
            placeholder="kids,men,sandals etc"
            onChange={handleCat}
          />
        </div>
        <div className="addProductItem">
          <label>price</label>
          <input
            type="number"
            placeholder="2000"
            name="price"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>similar sizes</label>
          <input
            type="text"
            name="similarsize"
            placeholder="25-30,31-36 etc"
            onChange={handleOtherSizes}
          />
        </div>
        <div className="addProductItem">
          <label>similar products</label>
          <input
            type="text"
            name="similarproducts"
            placeholder="306, 005-2 etc"
            onChange={handleSimilarProducts}
          />
        </div>
        <div className="addProductItem">
          <label>pcs in carton</label>
          <input
            type="number"
            name="pcs"
            placeholder="24,36,48 etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>size</label>
          <input
            type="text"
            name="size"
            placeholder="25-30 etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>color</label>
          <input
            type="text"
            name="color"
            placeholder="black,brown,white etc"
            onChange={handleColor}
          />
        </div>
        <div className="addProductItem">
          <label>no of similar products</label>
          <input
            type="number"
            name="nosimilarproducts"
            placeholder="2,3 etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>no of similar size</label>
          <input
            type="number"
            name="nosimilarsize"
            placeholder="2,3 etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>no of color</label>
          <input
            type="number"
            name="nocolor"
            placeholder="2,3 etc"
            onChange={handleChange}
          />
        </div>
        <div className="addProductItem">
          <label>no of stock</label>
          <input
            type="number"
            name="nostock"
            placeholder="20,30 etc"
            onChange={(e) => setNoOfStock(e.target.value)}
          />
        </div>
        <div className="addProductItem">
          <label>in Stock</label>
          <select name="inStock" id="active" onChange={handleChange}>
            <option value="true">Yes</option>
            <option value="false">No</option>
          </select>
        </div>

        {isLoading ? (
          <div className="loader">
            <HalfSpinner />
          </div>
        ) : (
          <button onClick={handleClick} className="addProductButton">
            Create
          </button>
        )}
      </form>
      {/* <!ImgSmall
        imgSmall={imgSmall}
        input={input}
        setFirstUpload={setFirstUpload}
        uploadedNo={uploadedNo}
        productId={productId}
        />*/}
      <ImgMedium
        imgMedium={imgMedium}
        input={input}
        setFirstUpload={setFirstUpload}
        firstUpload={firstUpload}
        uploadedNo={uploadedNo}
        setIsLoading={setIsLoading}
        productId={productId}
        setInput={setInput}
      />
    </div>
  );
}
