import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BsCash } from "react-icons/bs";
import { VscCreditCard } from "react-icons/vsc";
import { MdOutlinePhotoSizeSelectSmall } from "react-icons/md";
import { useSelector } from "react-redux";

const TransactionType = ({
  setTransType,
  setAmountPaid,
  setFinalCheck,
  setOrderType,
  setOrderStatus,
}) => {
  const [fillOut, setFillOut] = useState(false);
  const [cash, setCash] = useState(false);
  const [halfCash, setHalfCash] = useState(false);
  const [credit, setCredit] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const cart = useSelector((state) => state.cart);

  const selection = (e) => {
    const element = e.target.outerText;
    if (element === "HELA KAMILI") {
      setCredit(false);
      setHalfCash(false);
      setCash(true);
      setAmountPaid(cart.total[0]);
      setOrderType("cash");
      setOrderStatus("paid");
    } else if (element === "ANATOA KIDOGO") {
      setCash(false);
      setCredit(false);
      setHalfCash(true);
      setOrderType("half credit");
      setOrderStatus("credit");
    } else if (element === "ANAKOPA") {
      setCash(false);
      setHalfCash(false);
      setCredit(true);
      setAmountPaid(0);
      setOrderType("credit");
      setOrderStatus("credit");
    }
  };

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setTransType(false);
    }
  };

  const toFinal = () => {
    setFinalCheck(true);
    setTransType(false);
  };

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={exitDetailHander}
    >
      <Items>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Header>
            <div> AINA YA MALIPO</div>
          </Header>
          <Types>
            <Cash id="cash" className={cash ? "black" : ""} onClick={selection}>
              <div className="icon">
                <BsCash size={35} />
              </div>
              <div className="long">HELA KAMILI</div>
            </Cash>
            <Small className={halfCash ? "black" : ""} onClick={selection}>
              <TopInput onClick={() => setFillOut(true)}>
                <div className="icon">
                  <MdOutlinePhotoSizeSelectSmall size={35} />
                </div>
                <div className="long">ANATOA KIDOGO</div>
              </TopInput>
              {fillOut && (
                <Input>
                  <input
                    type="text"
                    onChange={(e) => setAmountPaid(e.target.value)}
                  />
                </Input>
              )}
            </Small>
            <Credit className={credit ? "black" : ""} onClick={selection}>
              <div className="icon">
                <VscCreditCard size={35} />
              </div>
              <div className="long">ANAKOPA</div>
            </Credit>
          </Types>
          <Submit>
            <button onClick={toFinal}>Kamilisha Order</button>
          </Submit>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;

  .title {
    margin-top: 1%;
    padding: 1rem;
    font-size: 2rem;
  }
`;

const Item = styled(motion.li)`
  width: 100%;
  font-size: 1.5rem;
  background-color: rgb(243 241 243);
  padding: 1rem;
  margin-left: 5%;
  margin-right: 5%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 90%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  display:grid;
  grid-template-row:10% 60% 10%

  border-radius: 1rem;
  

  .black {
    background: #d3d3d3;
  }
`;

const Header = styled(motion.div)`
  font-size: 1.5rem;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const Types = styled(motion.div)`
  margin-top: 10%;
`;

const Cash = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .long {
    font-size: 80%;
  }
`;

const Small = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const TopInput = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 100%;

  .long {
    font-size: 80%;
  }
`;

const Input = styled(motion.div)`
  text-align: center;
  margin-top: 0.5rem;
  height: 5rem;
  width: 100%;

  display: grid;
  align-items: center;

  input {
    height: 70%;
    width: 100%;
    border-radius: 1rem;
    border: 0.01rem solid gray;
    padding: 0.5rem;
    font-size: 1.5rem;
  }
`;

const Credit = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .long {
    font-size: 80%;
  }
`;

const Submit = styled(motion.div)`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  height: 100%;
  align-items: center;
  justify-items: center;

  button {
    width: 60%;
    height: 30%;
    border-radius: 1rem;
    border: none;
    color: white;
    background: gray;
  }
`;

export default TransactionType;
