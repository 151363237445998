import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { IoIosAddCircleOutline } from "react-icons/io";
import { MdDownloadDone } from "react-icons/md";

const EditPage = ({
  setEditOrder,
  searching,
  setSearching,
  searchResults,
  makeChanges,
  changeColor,
  setChangeColor,

}) => {
  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setEditOrder(false);
    }
  };

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <Footage>
          <Editing>
            <Input>
              <div className="searchTitle">Search</div>
              <input
                type="text"
                onChange={(e) => setSearching(e.target.value)}
              />
            </Input>
            
            <Searching>
              {searching &&
                searchResults.map((d, key) => (
                  <div
                    className={d?.inStock ? "searchNresults" : "fix"}
                    key={d._id}
                  >
                    <div className="actualImg">
                      <img src={d.img} alt="" className="searchimg" />
                    </div>
                    <div className="dataResults">
                      <div>{d.artNo}</div>
                      <div>{d.price}</div>
                      <div>{d.pcs}</div>
                    </div>
                    <div className="icon">
                      {changeColor === key ? (
                        <MdDownloadDone
                          size={25}
                          color={"green"}
                          onClick={() => makeChanges(d, key)}
                        />
                      ) : (
                        <IoIosAddCircleOutline
                          size={25}
                          color={"black"}
                          onClick={() => makeChanges(d, key)}
                        />
                      )}
                    </div>
                  </div>
                ))}
            </Searching>
          </Editing>
        </Footage>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background: white;
  position: absolute;
  color: black;
  height: 70%;
  bottom: 0;
`;

const Footage = styled(motion.div)`
  height: 90%;
  top: 5%;
  bottom: 5%;
  position: absolute;
  width: 90%;

  right: 5%;
  border-radius: 1rem;
`;

const Editing = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 4fr;
  grid-gap: 2%;
  overflow: scroll;
  scroll-behaviour: smooth;
  height: 100%;
  width: 90%;

  margin-right: 5%;
`;

const Input = styled(motion.div)`
  position: absolute;
  top: 0;
  height: 15%;
  width: 100%;

  display: grid;
  grid-template-rows: 1fr 2fr;
  align-items: center;

  .searchTitle {
    text-align: start;
  }

  input {
    padding: 4%;
    width: 100%;
    z-index: 1;
    border: 0.01rem solid gray;
    height: 80%;
    background-color: white;
    border-radius: 2rem;
  }
`;

const Searching = styled(motion.div)`
  position: absolute;
  height: 85%;
  overflow: hidden;
  overflow-y: scroll;
  bottom: 0%;

  .searchNresults {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    margin: 0%;
  }

  .fix {
    opacity: 0.5;
    pointer-events: none;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr 0.5fr;
    margin: 0%;
  }

  .searchimg {
    max-width: 100%;
    max-height: 100%;
    display: grid;
    grid-gap: 0.5rem;
  }

  .actualImg {
    height: 20%;
    display: grid;
    align-items: center;
    justify-items: center;

    .out {
      font-weight: 1000;
      font-size: 50%;
    }
  }

  .dataResults {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

export default EditPage;
