import GlobalStyles from "./components/GlobalStyles";
import Login from "./pages/loginandregistration/login";
import Register from "./pages/loginandregistration/register";
import Scanning from "./pages/scanning/scanning";
import CheckoutPage from "./pages/checkout/checkoutpage";
import CustomerPage from "./pages/customers/customerPage";
import WorkersCustomerPage from "./pages/workerscustomer/workerscustomerPage";
import StocksDataPage from "./pages/stocksdata/stocksdataPage";
import HomePage from "./pages/home/homePage";
import ProductPage from "./pages/products/productPage";
import PersonalPage from "./pages/user/personalPage";
import CreateCustomerPage from "./pages/customers/createCustomerPage";
import WorkerCreateCustomerPage from "./pages/workerscustomer/workerscustomercreatePage";
import ProductEditPage from "./pages/newProduct/producteditPage";
import SingleProductEditPage from "./pages/newProduct/singleproducteditPage";
import NewProductPage from "./pages/newProduct/newproductPage";
import SingleProductPage from "./pages/products/singleproductPage";
import StocksCustomerPage from "./pages/stocksdata/stockscustomerpage";
import UsersListPage from "./pages/user/userslistPage";
import PerUser from "./components/users/peruser";
import { useSelector } from "react-redux";
import UsersEditPage from "./pages/user/usereditPage";

import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

import moment from "moment";
import { useState } from "react";
import InOrderPage from "./pages/inorder/inorderpage";

function App() {
  const User = useSelector((state) => state?.user?.currentUser);
  const timer = useSelector((state) => state?.user?.timer);
  const ongoingTimer = moment(new Date()).unix();
  const diff = ongoingTimer - timer;

  const getInfo = () => {
    if (User !== null && diff < 18000) {
      if (User?.isAdmin === true || User?.isWorker === true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const order = getInfo();

  const guideAccess = () => {
    if (User !== null && diff < 18000) {
      if (User?.accessToken) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const guide = guideAccess();

  return (
    <div className="App">
      <GlobalStyles />
      <Router>
        <Switch>
          <Route exact path="/">
            {order ? <HomePage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/login">{guide ? <Redirect to="/" /> : <Login />}</Route>
          <Route path="/register">
            {order ? <Register /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/user">
            {order ? <PersonalPage /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/userlist">
            {order ? <UsersListPage /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/userlist/:id">
            {order ? <UsersEditPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/products">
            {order ? <ProductPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/product/:id">
            {order ? <SingleProductPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/checkout">
            {order ? <CheckoutPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/inorder">
            {order ? <InOrderPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/customer/:id">
            {order ? <CustomerPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/workerscustomer/:id">
            {order ? <WorkersCustomerPage /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/createcustomer">
            {order ? <CreateCustomerPage /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/createworkercustomer">
            {order ? <WorkerCreateCustomerPage /> : <Redirect to="/login" />}
          </Route>
          <Route exact path="/editproduct">
            {order ? <ProductEditPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/editproduct/:id">
            {order ? <SingleProductEditPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/createproduct">
            {order ? <NewProductPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/stocksdata">
            {order ? <StocksDataPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/stockscustomer">
            {order ? <StocksCustomerPage /> : <Redirect to="/login" />}
          </Route>
          <Route path="/orderscan">
            {order ? <Scanning /> : <Redirect to="/login" />}
          </Route>
        </Switch>
      </Router>
    </div>
  );
}

export default App;

/* const [todaysDate, setTodaysDate] = useState("");
  const [todaysUnix, setTodaysUnix] = useState("");
  const [camShow, setCamShow] = useState(false);
  const date = new Date();
  const stringed = JSON.stringify(date).slice(1, -15);
  const dateDate = moment(stringed).format("DD");
  const dateMonth = moment(stringed).format("MM");
  const dateYear = moment(stringed).format("YYYY");
  const actualD = `${dateMonth}/${dateDate}/${dateYear}`;
  const [stringDate, setStringDate] = useState(actualD);
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showArrow, setShowArrow] = useState(false);
  const [finishSelected, setFinishSelected] = useState(false);

  const unixDate = moment(
    `${stringDate} 23:59:59`,
    "MM/DD/YYYY HH:mm:ss"
  ).valueOf();
  const actualDate = moment(unixDate).format("YYYY-MM-DD");

  //  console.log(actualDate);

  useEffect(() => {
    setTodaysDate(actualDate);
    setTodaysUnix(unixDate);
  }, [stringDate]);

  */
