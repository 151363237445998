import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestmethods";
import moment from "moment";
import HalfSpinner from "../loaders/halfspinner";

const FinalCheckout = ({
  setTransType,
  customerDets,
  amountPaid,
  setFinalCheck,
  orderType,
  orderStatus,
  setPrinting,
  setOrderCode,
}) => {
  const cart = useSelector((state) => state.cart);
  const user = useSelector((state) => state.user.currentUser);
  const [cartons, setCartons] = useState(null);
  const rando = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
  const norando = moment(new Date()).unix();
  const todaysDate = new Date();
  const timing = moment(todaysDate).unix();
  const tTime = moment.unix(timing).format("DD/MM/YYYY, HH:mm");
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setTransType(false);
      setFinalCheck(false);
    }
  };

  useEffect(() => {
    const totalCtns = [];

    cart.products?.map((d) => {
      totalCtns.push(d.quantity);
    });

    const totals = totalCtns.reduce((a, b) => a + b, 0);

    setCartons(totals);
  }, [cart.products]);

  const submission = async () => {
    setIsLoading(true);
    const proArray = [];
    const productsInCart = [];
    const onlyProducts = [];
    const productsTopush = async () => {
      await cart.products.map((d) => {
        let ooz = {
          productId: d._id,
          artNo: d.artNo,
          price: d.price,
          pieces: d.pcs,
          img: d.img,
          quantity: d.quantity,
        };

        let cartProducts = {
          productId: d._id,
          quantity: d.quantity,
        };

        proArray.push(ooz);
        productsInCart.push(cartProducts);
        onlyProducts.push(d._id);
      });
    };
    await productsTopush();

    const setOrder = customerDets.username + rando + norando ;

    const order = {
      userId: customerDets.username,
      orderId: setOrder,
      user_ID: user._id,
      customerId: customerDets._id,
      products: proArray,
      amount: cart.total[0],
      transacted: user.isAdmin ? true : false,
    };

    const transaction = {
      userId: user._id,
      customerId: customerDets._id,
      customerName: customerDets.username,
      orderId: setOrder,
      products: proArray,
      amount: cart.total[0],
      orderstatus: orderStatus,
      transactiontype: orderType,
    };

    const payments = {
      userId: user._id,
      customerId: customerDets._id,
      customerName: customerDets.username,
      orderId: setOrder,
      amount: amountPaid,
      info: "",
    };

    const customerTransaction = {
      transactionId: setOrder,
      adminId: user._id,
      amount: cart.total[0],
      orderstatus: orderStatus,
      collectionstatus: "collected",
      date: timing,
    };

    const customerPayments = {
      transactionapproval: user._id,
      transactionId: setOrder,
      info: [orderStatus],
      amount: amountPaid,
      date: timing,
    };

    const unique = [...new Set(onlyProducts)];

    const pushingWhole = async () => {
      try {
        if (user.isAdmin) {
          const resOrder = await userRequest.post("/instoreorders/", order);

          if (resOrder.statusText === "OK") {
            const resTransactions = await userRequest.post(
              "/transactions/",
              transaction
            );

            if (resTransactions.statusText === "OK") {
              const resPayments = await userRequest.post(
                "/payments/",
                payments
              );

              if (resPayments.statusText === "OK") {
                for (let i = 0; i < unique.length; i++) {
                  let totalsAmountsPro = [];

                  await productsInCart.map((d) => {
                    if (unique[i] === d.productId) {
                      totalsAmountsPro.push(d.quantity);
                    }
                  });

                  const theTotal = await totalsAmountsPro.reduce(
                    (a, b) => a + b,
                    0
                  );

                  const toPut = await {
                    amount: theTotal,
                    date: timing,
                  };

                  const resSales = await userRequest.put(
                    `/producttrack/sales/${unique[i]}`,
                    toPut
                  );
                }
              }
            }
          }
        } else if (user.isWorker) {
          const resOrder = await userRequest.post("/instoreorders/", order);

          if (resOrder.statusText === "OK") {
            const resTransactions = await userRequest.post(
              "/workerstransactions/",
              transaction
            );

            if (resTransactions.statusText === "OK") {
              const resPayments = await userRequest.post(
                "/workerspayments/",
                payments
              );
            }
          }
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    };

    const pushingCustomer = async () => {
      try {
        if (user.isAdmin) {
          const resTrans = await userRequest.put(
            `/customers/transactions/${customerDets._id}`,
            customerTransaction
          );

          if (resTrans.statusText === "OK") {
            const resPays = await userRequest.put(
              `/customers/payments/${customerDets._id}`,
              customerPayments
            );
          }
        } else if (user.isWorker) {
          const resTrans = await userRequest.put(
            `/workerscustomers/transactions/${customerDets._id}`,
            customerTransaction
          );

          if (resTrans.statusText === "OK") {
            const resPays = await userRequest.put(
              `/workerscustomers/payments/${customerDets._id}`,
              customerPayments
            );
          }
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    };

    await pushingWhole();

    await pushingCustomer();
    setOrderCode(setOrder);
    setPrinting(true);
    setIsLoading(false);
  };

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={exitDetailHander}
    >
      <Items className={error ? "error" : ""}>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Top>
            <div className="title">{customerDets.username}</div>
            <div className="time">{tTime} </div>
          </Top>
          <Middle>
            {cart.products.map((p, index) => (
              <div className="added" key={index}>
                <div className="cartimg">
                  <img src={p.img} alt="await" />
                </div>
                <div className="data">
                  <div className="artNoCart dc">
                    <div>{p.artNo}</div>
                  </div>
                  <div className="sizeCart dc">size | {p.size}</div>
                  <div className="sizeCart dc">pcs | {p.pcs}</div>
                  <div className="pcsCart dc">price | {p.price} Tshs</div>
                  <div className="at">
                    <div className="amountbox">
                      <div className="countAmount mv">ctns | {p.quantity}</div>
                    </div>
                    <div className="totalPrice">
                      {p.price * p.quantity * p.pcs} Tshs
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Middle>

          <Bottom>
            <Info>
              <Data>
                <div>subtotal</div>
                <Line></Line>
                <div>{cart.total[0]}</div>
              </Data>
              <Data>
                <div> cartons</div>
                <Line></Line>
                <div>{cartons && cartons}</div>
              </Data>
              <Data>
                <div>Paid</div>
                <Line></Line>
                <div>{amountPaid && amountPaid}</div>
              </Data>
              <Data>
                <div>Balance</div>
                <Line></Line>
                <div>{amountPaid - cart.total[0]}</div>
              </Data>
            </Info>
            <div></div>
            <Buttons className={error ? "error" : ""}>
              {isLoading ? (
                <div className="loader">
                  <HalfSpinner />
                </div>
              ) : (
                <button onClick={submission}>
                  {error ? "error" : "finish order"}
                </button>
              )}
            </Buttons>
          </Bottom>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .error {
    bacground: #ffcccb;
  }

  .loader {
    display: grid;
    align-items: center;
    justify-items: center;
    margin-top: 2%;
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  display: grid;
  grid-template-rows: 1fr 5fr 2fr;
`;

const Top = styled(motion.div)`
  position: absolute;
  height: 5%;
  top: 0;
  width: 100%;
  display: grid;
  align-items: center;

  grid-template-columns: 2fr 1fr;
  border-bottom: 0.02rem gray solid;

  .title {
    text-align: start;
  }
  .time {
    text-align: end;
    font-size: 80%;
  }
`;
const Middle = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 5%;
  bottom: 25%;

  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-top: 0.5rem;

  .added {
    display: grid;
    grid-template-columns: 1fr 2fr;

    .artNoCart {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
    }

    .at {
      display: grid;
      grid-template-columns: 1fr 1fr;

      .totalPrice {
        text-align: end;
        margin-right: 0.5rem;
      }

      .amountbox {
        display: grid;

        width: 80%;
        margin-left: 1rem;
        border: 0.05rem solid gray;
        justify-items: center;
        align-items: center;
        border-radius: 2rem;
      }
    }

    .cartimg {
      display: grid;
      align-items: center;

      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .data {
      margin: 1rem 0.5rem 1rem 1rem;
      display: grid;
      grid-template-rows: repeat(5, 1fr);
      align-items: center;
      grid-gap: 0.25rem;

      .dc {
        margin-left: 1rem;
      }

      .remove {
        display: grid;
        justify-items: end;
        align-items: center;
      }
    }

    .cartBox {
      overflow: scroll;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 1rem;

      height: 100%;

      .added {
        display: grid;
        grid-template-columns: 1fr 2.5fr;

        margin-top: 0.5rem;
        position: relative;

        .at {
          display: grid;
          grid-template-columns: 1fr 1fr;

          .totalPrice {
            text-align: end;
            margin-right: 0.5rem;
          }

          .amountbox {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            width: 60%;
            margin-left: 1rem;
            border: 0.05rem solid gray;
            justify-items: center;
            align-items: center;
            border-radius: 20rem;
          }
        }
      }
    }
  }
`;

const Bottom = styled(motion.div)`
  position: absolute;
  height: 25%;
  bottom: 0;
  top: 75%;
  width: 100%;
  display: grid;
  grid-template-rows: 2fr 2fr;
  background: white;
  align-items: top;
`;

const Info = styled(motion.div)`
  border-bottom: 0.01rem solid gray;
  border-top: 0.01rem solid gray;
  display: grid;
  grid-template-rows: 1fr 1fr;
  grip-gap: 50%;
  align-items: center;
`;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: end;
  font-size: 100%;
  justify-items: center;
  align-items: center;
  height: 100%;
`;

const Line = styled(motion.div)``;

const Buttons = styled(motion.div)`
  width: 60%;
  margin-left: 20%;
  margin-right: 20%;
  height: 80%;
  display: grid;
  align-items: end;
  margin-bottom: 10%;

  button {
    height: 80%;
    width: 100%;
    border-radius: 2rem;
    border: none;
    background: gray;
    color: white;
  }
`;

export default FinalCheckout;
