//import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import ProductList from "../../components/products/productList";
import { useLocation } from "react-router";
import { IoIosSearch } from "react-icons/io";
import { useState } from "react";

const Products = ({ startSearch, setStartSearch }) => {
  const location = useLocation();
  const [cat, setCat] = useState("");
  const [sort, setSort] = useState("newest");
  const [filters, setFilters] = useState({});

  const handleFilter = (e) => {
    const value = e.target.value;

    setFilters({
      ...filters,
      [e.target.name]: value,
    });
  };

  const handleCat = (e) => {
    const value = e.target.value;

    setCat(value);
  };

  return (
    <Container>
      <Contain>
        {startSearch && (
          <FilterContainer className={startSearch ? "nonfilter" : "filter"}>
            <div className="textDiv">
              <input type="text" onChange={(e) => handleCat(e)} />
            </div>
            <div className="searchDiv">
              <IoIosSearch size={25} className="search" />
            </div>
          </FilterContainer>
        )}

        <Items className={startSearch ? "nonitems" : "items"}>
          <ProductList
            cat={cat}
            filters={filters}
            sort={sort}
            startSearch={startSearch}
            setCat={setCat}
          />
        </Items>
      </Contain>
    </Container>
  );
};
const Container = styled(motion.div)`
  width: 100%;
  height: 70%;
  position: absolute;
  overflow-x: hidden;
  bottom: 10%;
  top: 20%;

  .linker {
    text-decoration: none;
  }
`;


const Contain = styled(motion.div)`
  height: 100%;

  .nonitems {
    position: absolute;
    height: 90%;
    width: 100%;
    top: 10%;
    overflow-y: scroll;
  }

  .items {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    overflow-y: scroll;
  }

  .nonfilter {
    position: sticky;
    top: 0;
    display: grid;
    height: 10%;
    grid-template-columns: 5fr 1fr;
  }

  .filter {
    position: sticky;
    top: 0;
    height: 0%;
  }
`;

const Items = styled(motion.div)``;

const FilterContainer = styled(motion.div)`
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  width: 95%;
  margin-left: 2.5%;
  margin-right: 2.5%;

  .textDiv {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: end;
    align-items: center;
    input {
      width: 90%;
      height: 70%;
      padding: 5%;
      border-radius: 2rem 0 0 2rem;
      border: 0.02rem solid gray;
    }
  }

  .searchDiv {
    border: 0.02rem solid gray;
    display: grid;
    justify-items: start;
    align-items: center;
    width: 50%;
    height: 70%;
    border-radius: 0 2rem 2rem 0;
  }
`;


export default Products;

/*
<FilterContainer>
          <SelectionOne>
            <Two>
              <select name="cat" onChange={handleCat}>
                <option disabled>SIZE</option>
                <option value="kids">kids</option>
                <option value="men">men</option>
                <option value="women">women</option>
                <option value="sandals">sandals</option>
                <option value="shoes">shoes</option>
                <option value="school">school</option>
              </select>
            </Two>
            <One>
              <select name="size" onChange={handleFilter}>
                <option disabled>SIZE</option>
                <option value="15-20">15-20</option>
                <option value="21-25">21-25</option>
                <option value="26-30">26-30</option>
                <option value="31-36">31-36</option>
                <option value="37-42">37-42</option>
                <option value="40-45">40-45</option>
              </select>
            </One>
          </SelectionOne>
          <SelectionTwo>

            <Uno>
              <select onChange={(e) => setSort(e.target.value)}>
                <option value="newest">newest</option>
                <option value="asc">asc</option>
                <option value="desc">desc</option>
              </select>
            </Uno>
          </SelectionTwo>
        </FilterContainer>

        */
