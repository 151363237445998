import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import HalfSpinner from "../loaders/halfspinner";
import { userRequest } from "../../requestmethods";
import { Link } from "react-router-dom";

const WorkersCredit = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [customers, setCustomers] = useState([]);
  const [filteredCust, setFilertedCust] = useState([]);
  const [custLength, setCustLength] = useState("");

  useEffect(() => {
    const infunction = async () => {
      const res = await userRequest.get("/customers/");

      setCustomers(res.data);

      setIsLoading(false);
    };

    infunction();
  }, []);

  useEffect(() => {
    const func = () => {
      const inFilter = [];

      customers.map((d) => {
        if (d.worker) {
          inFilter.push(d);
        }
      });

      setCustLength(inFilter.length);

      setFilertedCust(inFilter);
    };

    func();
  }, [customers]);

  return (
    <Container>
      <Data>
        <div className="title">
          <div>Wateja Wakuwadai</div>
        </div>
        <div className="userdata" style={{ ["--length"]: custLength }}>
          {isLoading ? (
            <div className="loader">
              <HalfSpinner />
            </div>
          ) : (
            <div className="singledata">
              {filteredCust?.map((d) => (
                <Link
                  key={d._id}
                  style={{ textDecoration: "none", color: "black" }}
                  to={`/customer/${d._id}`}
                >
                  <div className="single" key={d._id}>
                    {d.username}
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  @media (max-width: 540px) {
  }
  .loader {
  }
`;

const Data = styled(motion.div)`
  .title {
    margin-right: 1rem;
    margin-left: 1rem;
  }

  .userdata {
    max-width: 100%;
    display: grid;

    grid-gap: 2rem;
    justify-items: center;
    align-items: center;

    height: 3rem;
    margin-left: 1rem;
    margin-right: 1rem;
    margin-bottom: 2rem;
    margin-top: 0.5rem;
    overflow-x: scroll;
    ::-webkit-scrollbar {
      display: none;
    }

    .singledata {
      margin-left: 0.5rem;
      display: grid;
      grid-template-columns: repeat(var(--length), minmax(9rem, 0.5fr));
      grid-gap: 0rem;

      border-radius: 2rem;
    }

    .single {
      padding: 0.5rem;
      background-color: rgb(243, 241, 243);
      width: 90%;
      border-radius: 2rem;
      display: grid;
      justify-items: center;
      align-items: center;
    }
  }
`;

const Items = styled(motion.div)``;

export default WorkersCredit;
