import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import CreateCustomer from "./createcustomer";
import BottomProductNav from "../../components/bottomnav/bottomproductnav";
import SideBar from "../../components/home/sidebar";

const CreateCustomerPage = () => {
  const [sidebar, setSidebar] = useState(false);
  return (
    <Body>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <Navbar />
        <CreateCustomer />
        <BottomProductNav sidebar={sidebar} setSidebar={setSidebar} />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)``;

export default CreateCustomerPage;
