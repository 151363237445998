import React, { useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import LoadingSpinner from "../LoadingSpinner";

const OtpLogin = ({
  setEnterOtp,
  otp,
  verifyOTP,
  errOtp,
  otpLoading,
  setOtpLoading,
  setIsLoading,
}) => {
  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setEnterOtp(false);
      setOtpLoading(false);
    }
  };

  useEffect(() => {
    const changer = () => {
      setIsLoading(false);
    };

    changer();
  }, []);

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <Input>
          {otpLoading ? (
            <div className="loader">
              <LoadingSpinner />
            </div>
          ) : (
            <input
              type="number"
              placeholder="OTP"
              value={otp}
              onChange={verifyOTP}
            />
          )}
          {errOtp && (
            <Err>
              <div className="err">Wrong Code please try again</div>
            </Err>
          )}
        </Input>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .loader {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Items = styled(motion.div)`
  width: 80%;
  border-radius: 1rem;
  margin-left: 10%;
  margin-right: 10%;

  background: white;
  position: absolute;

  color: black;

  height: 30%;

  top: 30%;

  .title {
    margin-top: 1%;
    padding: 1rem;
    font-size: 2rem;
  }
`;

const Input = styled(motion.div)`
  width: 100%;
  height: 20%;
  margin-top: 25%;
  margin-bottom: 40%;

  input {
    padding: 1rem;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
    font-size: 1rem;
  }
`;

const Err = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 2%;
  margin-bottom: 2%;

  .err {
    text-align: center;
    color: #ff7276;
  }
`;

export default OtpLogin;
