import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import { FiUserPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { GoSearch } from "react-icons/go";

const CustomerCheck = ({
  setFinishSelected,
  finishSelected,
  setSelectedUser,
  setTransType,
  setCustomerDets,
  setCreateCust,
}) => {
  const [customerList, setCustomerList] = useState(null);
  const User = useSelector((state) => state.user.currentUser);
  const [sorting, setSorting] = useState([]);
  const [startSearch, setStartSearch] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setFinishSelected(false);
    }
  };

  const handleClicks = (d) => {
    setSelectedUser(d);
    setTransType(true);
    setFinishSelected(false);
    setCustomerDets(d);
  };

  useEffect(() => {
    const fullFunction = async () => {
      if (customerList) {
        setCustomerList(null);
      } else {
        if (User?.isAdmin) {
          if (searchInput) {
            const res = await userRequest.get(
              `/customers?username=${searchInput && searchInput}`
            );

            setCustomerList(res?.data);
          } else {
            const res = await userRequest.get(`/customers`);

            setCustomerList(res?.data);
          }
        } else if (User?.isWorker) {
          if (searchInput) {
            const res = await userRequest.get(
              `/workerscustomers?username=${searchInput && searchInput}`
            );

            setCustomerList(res?.data);
          } else {
            const res = await userRequest.get("/workerscustomers");

            setCustomerList(res?.data);
          }
        }
      }
    };

    fullFunction();
  }, [searchInput]);

  useEffect(() => {
    const sorter = () => {
      const list = customerList?.sort((a, b) => {
        const nameA = a?.username.toUpperCase(); // ignore upper and lowercase
        const nameB = b?.username.toUpperCase(); // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      list && setSorting(list);
    };
    sorter();
  }, [customerList,searchInput]);

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items startsearch={startSearch.toString()}>
        <div className="title">Customer Names</div>

        <SearchBar>
          <input type="text" onChange={(e) => setSearchInput(e.target.value)} />
          <GoSearch size={25} className="searchIcon" />
        </SearchBar>

        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {sorting.length > 0 &&
            sorting?.map((d) => (
              <Item key={d?._id} value={d} onClick={() => handleClicks(d)}>
                {d?.username}
              </Item>
            ))}
        </Balance>
        <Bottom>
          <Search></Search>
          <Create onClick={() => setCreateCust(true)}>
            <div> CREATE USER</div>
            <FiUserPlus size={25} />
          </Create>
        </Bottom>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;
  z-index: 2;
  display: grid;
  grid-template-row: "5% 10% 65% 10%";

  .title {
    position: absolute;
    height: 5%;
    width: 100%;
    top: 1%;
    font-size: 120%;
    margin-left: 5%;
  }
`;

const Item = styled(motion.li)`
  width: 100%;
  font-size: 1.5rem;
  background-color: rgb(243 241 243);
  padding: 1rem;
  margin-left: 5%;
  margin-right: 5%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 65%;
  margin-left: 5%;
  margin-right: 5%;
  top: 17%;
  overflow-x: hidden;
  overflow-y: scroll;

  padding: 1rem;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 0.5rem;
  align-items: center;
  z-index: 2;
`;

const Create = styled(motion.div)`
  width: 90%;
  background: white;
  display: grid;
  justify-items: end;
`;

const SearchBar = styled(motion.div)`
  position: absolute;
  width: 90%;
  display: grid;
  grid-template-columns: 79% 19%;
  border: 0.01rem solid gray;
  margin-left: 5%;
  margin-right: 5%;
  height: 7.5%;
  grid-gap:2%;
  top: 6.5%;
  border-radius: 1rem;
  justify-items: center;
  align-items: center;

  input {
    height: 100%;
    width: 100%;
    border-radius: 1rem;
    border:none;
    padding:5%;
    font-size:100%;
  }

  .searchIcon {
    border-left: 0.01rem solid gray;
    width: 100%;
    alifn
  }
`;

const Search = styled(motion.div)`
  display: grid;
  justify-items: end;
`;

const Bottom = styled(motion.div)`
  position: absolute;
  bottom: 2.5%;
  height: 10%;
  display: grid;
  grid-template-columns: 30% 70%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

export default CustomerCheck;

/*

 <Link
            to={User.isAdmin ? "/createcustomer" : "/createworkercustomer"}
            style={{ textDecoration: "none", color: "black" }}
          >
          </Link>
*/
