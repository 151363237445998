import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import NavbarDate from "../../components/navbar/navbarDate";
import StocksData from "./stocksdata";
import BottomProductNav from "../../components/bottomnav/bottomproductnav";
import SideBar from "../../components/home/sidebar";

const StocksDataPage = () => {
  const [todaysDate, setTodaysDate] = useState("");
  const [todaysUnix, setTodaysUnix] = useState("");
  const date = new Date();
  const stringed = JSON.stringify(date).slice(1, -15);
  const dateDate = moment(stringed).format("DD");
  const dateMonth = moment(stringed).format("MM");
  const dateYear = moment(stringed).format("YYYY");
  const actualD = `${dateMonth}/${dateDate}/${dateYear}`;
  const [stringDate, setStringDate] = useState(actualD);
  const [sidebar, setSidebar] = useState(false);

  const unixDate = moment(
    `${stringDate} 23:59:59`,
    "MM/DD/YYYY HH:mm:ss"
  ).valueOf();
  const actualDate = moment(unixDate).format("YYYY-MM-DD");

  //  console.log(actualDate);

  useEffect(() => {
    setTodaysDate(actualDate);
    setTodaysUnix(unixDate);
  }, [stringDate]);

  return (
    <Body>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <NavbarDate
          todaysDate={todaysDate}
          setTodaysDate={setTodaysDate}
          todaysUnix={todaysUnix}
          setTodaysUnix={setTodaysUnix}
          setStringDate={setStringDate}
        />
        <StocksData todaysDate={todaysDate} todaysUnix={todaysUnix} />
        <BottomProductNav sidebar={sidebar} setSidebar={setSidebar} />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)``;

export default StocksDataPage;
