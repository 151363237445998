import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BsCash } from "react-icons/bs";
import { VscCreditCard } from "react-icons/vsc";
import { MdOutlinePhotoSizeSelectSmall } from "react-icons/md";

const CustTransType = ({
  setAmountPaid,
  setOrderType,
  setOrderStatus,
  handleJumla,
  setAllowTrans,
  setInitiateTrans,
  fetchedProducts,
}) => {
  const [fillOut, setFillOut] = useState(false);
  const [cash, setCash] = useState(false);
  const [halfCash, setHalfCash] = useState(false);
  const [credit, setCredit] = useState(false);

  const selection = async (e) => {
    const element = e.target.outerText;

    if (element && element === "Hela Kamili") {
      const totalA = fetchedProducts && handleJumla();

      setAmountPaid(fetchedProducts && totalA);
      setCredit(false);
      setHalfCash(false);
      setCash(true);
      element && setOrderType("cash");
      setOrderStatus("paid");
    } else if (element === "Anatoa Kidogo") {
      setCash(false);
      setCredit(false);
      setHalfCash(true);

      setOrderType("half credit");
      setOrderStatus("credit");
    } else if (element === "ANAKOPA") {
      setCash(false);
      setHalfCash(false);
      setCredit(true);
      setAmountPaid(0);
      setOrderType("credit");
      setOrderStatus("credit");
    }
  };

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setInitiateTrans(false);
    }
  };

  const toFinal = () => {
    if (cash || halfCash || credit) {
      setInitiateTrans(false);
      setAllowTrans(true);
    }
  };

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={exitDetailHander}
    >
      <Items>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Header>
            <div> AINA YA MALIPO</div>
          </Header>
          <Types>
            <Cash
              id="cash"
              className={cash ? "black" : ""}
              onClick={(e) => selection(e)}
            >
              <div className="icon">
                <BsCash size={35} />
              </div>
              <div>Hela Kamili</div>
            </Cash>
            <Small
              className={halfCash ? "black" : ""}
              onClick={(e) => selection(e)}
            >
              <TopInput onClick={() => setFillOut(true)}>
                <div className="icon">
                  <MdOutlinePhotoSizeSelectSmall size={35} />
                </div>
                <div>Anatoa Kidogo</div>
              </TopInput>
              {fillOut && (
                <Input>
                  <input
                    type="text"
                    onChange={(e) => setAmountPaid(e.target.value)}
                  />
                </Input>
              )}
            </Small>
            <Credit className={credit ? "black" : ""} onClick={selection}>
              <div className="icon">
                <VscCreditCard size={35} />
              </div>
              <div>ANAKOPA</div>
            </Credit>
          </Types>
          <Submit>
            <button onClick={toFinal}>Kamilisha Order</button>
          </Submit>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background: white;
  position: absolute;
  color: black;
  height: 60%;
  bottom: 0;

  .title {
    margin-top: 1%;
    padding: 1%;
    font-size: 100%;
  }
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 100%;
  margin-left: 5%;
  margin-right: 5%;

  border-radius: 1rem;

  .black {
    background: #d3d3d3;
  }
`;

const Header = styled(motion.div)`
  position: absolute;
  height: 10%;
  top: 0;
  font-size: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

const Types = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 10%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  overflow-y: scroll;
`;

const Cash = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Small = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const TopInput = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 100%;
`;

const Input = styled(motion.div)`
  text-align: center;
  margin-top: 0.5rem;
  height: 5rem;
  width: 100%;

  display: grid;
  align-items: center;

  input {
    height: 70%;
    width: 100%;
    border-radius: 1rem;
    border: 0.01rem solid gray;
    padding: 0.5rem;
    font-size: 1.5rem;
  }
`;

const Credit = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Submit = styled(motion.div)`
  position: absolute;
  width: 100%;
  display: grid;
  height: 20%;
  align-items: center;
  justify-items: center;
  bottom: 0;

  button {
    width: 60%;
    height: 70%;
    border-radius: 1rem;
    border: none;
  }
`;

export default CustTransType;
