import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import NavbarCam from "../../components/navbar/navbarcam";
import Checkout from "../cartproducts/checkout";
import BottomCheckout from "../../components/bottomnav/bottomcheckout";
import SideBar from "../../components/home/sidebar";
import SearchPage from "../../components/products/search";

const CheckoutPage = () => {
  const [todaysDate, setTodaysDate] = useState("");
  const [todaysUnix, setTodaysUnix] = useState("");
  const [camShow, setCamShow] = useState(false);
  const date = new Date();
  const stringed = JSON.stringify(date).slice(1, -15);
  const dateDate = moment(stringed).format("DD");
  const dateMonth = moment(stringed).format("MM");
  const dateYear = moment(stringed).format("YYYY");
  const actualD = `${dateMonth}/${dateDate}/${dateYear}`;
  const [stringDate, setStringDate] = useState(actualD);
  const [scanResultWebCam, setScanResultWebCam] = useState("");
  const [showArrow, setShowArrow] = useState(false);
  const [finishSelected, setFinishSelected] = useState(false);
  const [sidebar, setSidebar] = useState(false);
  const [searchBAr, setSearchBar] = useState(false);

  const unixDate = moment(
    `${stringDate} 23:59:59`,
    "MM/DD/YYYY HH:mm:ss"
  ).valueOf();
  const actualDate = moment(unixDate).format("YYYY-MM-DD");

  //  console.log(actualDate);

  useEffect(() => {
    setTodaysDate(actualDate);
    setTodaysUnix(unixDate);
  }, [stringDate]);

  return (
    <Body finishselected={finishSelected.toString()}>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <AnimatePresence>
          {searchBAr && <SearchPage setSearchBar={setSearchBar} />}
        </AnimatePresence>
        <NavbarCam
          camShow={camShow}
          setCamShow={setCamShow}
          scanResultWebCam={scanResultWebCam}
          setScanResultWebCam={setScanResultWebCam}
          showArrow={showArrow}
          setShowArrow={setShowArrow}
          searchBAr={searchBAr}
        />
        <Checkout
          scanResultWebCam={scanResultWebCam}
          setScanResultWebCam={setScanResultWebCam}
          showArrow={showArrow}
          setShowArrow={setShowArrow}
          setFinishSelected={setFinishSelected}
          finishSelected={finishSelected}
          searchBAr={searchBAr}
        />
        <BottomCheckout
          camShow={camShow}
          setCamShow={setCamShow}
          setFinishSelected={setFinishSelected}
          finishSelected={finishSelected}
          sidebar={sidebar}
          setSidebar={setSidebar}
          setSearchBar={setSearchBar}
        />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)`
  position: fixed;
  overflow-y: hidden;
  height: 100%;
  overflow: hidden;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    display: none;
  }

  &::-webkit-scrollbar-track {
    display: none;
  }
`;

export default CheckoutPage;

/*
 pointer-events: ${({ finishSelected }) =>
    finishSelected === "false" ? "auto" : "none"};

  z-index: ${({ finishSelected }) =>
   (finishSelected === "false" ? "1" : "-1")};*/
