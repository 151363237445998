import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { useState } from "react";
import { userRequest } from "../../requestmethods";
import { useEffect } from "react";
import HalfSpinner from "../../components/loaders/halfspinner";

const CreateCustomer = () => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const user = useSelector((state) => state.user.currentUser);
  const [successReg, setSuccessReg] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const CreateCust = async () => {
    setIsLoading(true);
    const userInfo = {
      username: name,
      phonenumber: number,
      email: email,
      city: city,
      registerer: user._id,
    };

    try {
      const res = await userRequest.post("/customers/register", userInfo);

      if (res.data?.message === "User registered") {
        setSuccessReg(res.data.message);

        setTimeout(() => {
          window.location.reload();
        }, [1500]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setShowBanner(true);
  }, [successReg]);

  return (
    <Container>
      <Contain>
        {showBanner && (
          <Banner>
            <div>{successReg && successReg}</div>
          </Banner>
        )}
        <Title>
          <div>FUNGUA ACCOUNT YA MTEJA</div>
        </Title>
        <Words>
          <div>
            <input
              type="text"
              placeholder="name"
              className="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              type="phonenumber"
              placeholder="number ya simu"
              className="number"
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="email"
              className="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="mkoa"
              className="city"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </Words>
        <Submit>
          {isLoading ? (
            <div className="loader">
              <HalfSpinner />
            </div>
          ) : (
            <button onClick={() => CreateCust()}>CREATE</button>
          )}
        </Submit>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)``;

const Contain = styled(motion.div)`
  position: absolute;
  top: 20%;
  overflow: hidden;
  overflow-y: scroll;
  height: 75%;
  width: 100%;
`;

const Banner = styled(motion.div)``;

const Title = styled(motion.div)`
  width: 100%;
  margin-top: 15%;
  text-align: center;
`;

const Words = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 0.05rem;
  border: 0.01rem solid gray;
  margin-top: 10%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 1rem;

  input {
    border: none;
    decoration: none;
    height: 3rem;
    padding: 1rem;
    font-size: 1rem;
  }

  .email {
    border-top: 0.01rem solid gray;
  }
  .number {
    border-top: 0.01rem solid gray;
  }
  .city {
    border-top: 0.01rem solid gray;
  }
`;

const Submit = styled(motion.div)`
  margin: 1rem;
  width: 80%;
  height: 10%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;

  button {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
  }

  .loader {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

export default CreateCustomer;
