import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const OneProductList = ({ item }) => {
  const stockAssist = item?.inStock;

  return (
    <Container>
      <Items className={stockAssist ? "normal" : "fix"}>
        <Link className="link" to={`/product/${item?._id}`}>
          <Image>
            <img src={item?.imgM ? item?.imgM : item?.img} alt="await" />
            {stockAssist ? "" : <div className="out">OUT OF STOCK</div>}
          </Image>
          <Info>
            <div className="title">{item?.header}</div>
            <div className="price">{`${item?.price} Tshs`}</div>
          </Info>
        </Link>
      </Items>
    </Container>
  );
};

const Container = styled(motion.div)`
  .fix {
    opacity: 0.5;
    pointer-events: none;
  }
`;
const Items = styled(motion.div)`
  border-radius: 1rem;
  height: 100%;
  width: 100%;
  display: grid;
  align-items: center;
  jusitfy-items: center;

  .link {
    text-decoration: none;
    color: black;
  }
`;
const Image = styled(motion.div)`
  position: relative;
  img {
    cursor: pointer;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    align-items: center;

    cursor: pointer;
  }

  .out {
    position: absolute;
    top: 50%;
    display: grid;
    justify-items: center;
    background-color: white;
    width: 100%;
    font-weight: 500;
    z-index: 1;
  }
`;
const Info = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr;
  height: 70%;
  object-fit: contain;
  align-items: center;
  justify-items: center;
  margin-top: 0;

  .title {
  }
  .price {
  }
`;

export default OneProductList;
