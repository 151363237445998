import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { userRequest } from "../../requestmethods";
import app from "../../firebase";

const ImgSmall = ({
  imgSmall,
  input,
  setFirstUpload,
  uploadedNo,
  productId,
}) => {
  console.log(imgSmall);
  console.log(uploadedNo);

  const uploadFiles = () => {
    const fileName = new Date().getTime() + imgSmall?.name;

    const storage = getStorage(app);

    const storageRef = ref(storage, fileName);

    const uploadTask = uploadBytesResumable(storageRef, imgSmall);

    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log("Upload is " + progress + "% done");
        switch (snapshot.state) {
          case "paused":
            console.log("Upload is paused");
            break;
          case "running":
            console.log("Upload is running");
            break;
          default:
        }
      },
      (error) => {
        // Handle unsuccessful uploads
      },
      async () => {
        await getDownloadURL(uploadTask.snapshot.ref).then(
          async (downloadURL) => {
            try {
              const product = {
                imgS: downloadURL,
              };

              const resProduct = await userRequest.put(
                `/products/${productId}`,
                product
              );

              if (resProduct.statusText === "OK") {
                setFirstUpload(true);
              }

              console.log(resProduct?.data);
            } catch (err) {
              console.log(err);
            }
          }
        );
      }
    );
  };

  uploadedNo && uploadFiles();

  return <Body></Body>;
};
const Body = styled(motion.div)``;

export default ImgSmall;
