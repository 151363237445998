import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { BsCash } from "react-icons/bs";
import { VscCreditCard } from "react-icons/vsc";
import { MdOutlinePhotoSizeSelectSmall } from "react-icons/md";
import { useSelector } from "react-redux";

const CurrentCust = ({
  setTransType,
  setAmountPaid,
  setFinalCheck,
  setOrderType,
  setOrderStatus,
  finalCut,
  setFinalCut,
  setFinishSelected,
}) => {
  const [fillOut, setFillOut] = useState(false);
  const [cash, setCash] = useState(false);
  const [halfCash, setHalfCash] = useState(false);
  const [credit, setCredit] = useState(false);
  const [inputValue, setInputValue] = useState(null);
  const cart = useSelector((state) => state.cart);
  const cart_ii = useSelector((state) => state.cart_two);
  const cart_iii = useSelector((state) => state.cart_three);
  const cart_iv = useSelector((state) => state.cart_four);
  const cart_v = useSelector((state) => state.cart_five);
  const [selectedCust, setSelectedCust] = useState([]);

  const [onList, setOnList] = useState([
    cart,
    cart_ii,
    cart_iii,
    cart_iv,
    cart_v,
  ]);

  const sorting = (x, y) => {
    if (selectedCust.length > 0 && !selectedCust.includes(y)) {
      setSelectedCust((prevArray) => [...prevArray, y]);
    } else if (selectedCust.length === 0) {
      setSelectedCust((prevArray) => [...prevArray, y]);
    } else if (selectedCust.includes(y)) {
      setSelectedCust(finalCut.filter((item) => item !== y));
    }
  };

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setFinishSelected(false);
    }
  };

  const toFinal = () => {
    setFinalCheck(true);
    setTransType(false);
    setFinishSelected(false);
  };

  useEffect(() => {
    const filter = selectedCust.filter((v, i) => selectedCust.indexOf(v) == i);

    setFinalCut(filter);
  }, [selectedCust]);

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={exitDetailHander}
    >
      <Items>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <Header>
            <div> AINA YA MALIPO</div>
          </Header>

          <Types>
            {onList.map((d, index) => {
              if (d.name[0] && d.products.length > 0) {
                return (
                  <div
                    key={index}
                    className={
                      finalCut && finalCut.includes(index)
                        ? "selected"
                        : "names"
                    }
                    onClick={() => sorting(d?.name[0], index)}
                  >
                    <div>{d.name[0]}</div>
                  </div>
                );
              }
            })}
          </Types>
          <Submit>
            <button onClick={toFinal}>Kamilisha Order</button>
          </Submit>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  &::-webkit-scrollbar {
    display: none;
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.5);
  }

  &::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.5);
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 90%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  display:grid;
  grid-template-row:10% 70% 10%

  border-radius: 1rem;
  

  .black {
    background: #d3d3d3;
  }
`;

const Header = styled(motion.div)`
  font-size: 1.5rem;
  display: grid;
  align-items: center;
  justify-items: center;
`;

const Types = styled(motion.div)`
  height: 100%;
  width: 100%;
  font-size: 100%;
  display: grid;
  grid-template-rows: 18% 18% 18% 18% 18%;
  grid-gap: 2%;

  .names {
    border: 0.01rem solid gray;
    border-radius: 1rem;
    display: grid;
    justify-items: center;
    align-items: center;
  }

  .selected {
    border: 0.01rem solid gray;
    border-radius: 1rem;
    display: grid;
    grid-gap: 5%;
    justify-items: center;
    align-items: center;
    background: #d3d3d3;
  }

  .thebox {
    background: 100%;
  }
`;

const Cash = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 1rem;
  margin-bottom: 1rem;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .long {
    font-size: 80%;
  }
`;

const Small = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;
  margin-top: 1rem;
  margin-bottom: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const TopInput = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 100%;

  .long {
    font-size: 80%;
  }
`;

const Input = styled(motion.div)`
  text-align: center;
  margin-top: 0.5rem;
  height: 5rem;
  width: 100%;

  display: grid;
  align-items: center;

  input {
    height: 70%;
    width: 100%;
    border-radius: 1rem;
    border: 0.01rem solid gray;
    padding: 0.5rem;
    font-size: 1.5rem;
  }
`;

const Credit = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 3fr;
  align-items: center;
  justify-items: center;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  padding: 0.5rem;
  font-size: 1.5rem;
  border: 0.01rem solid gray;
  border-radius: 1rem;

  .icon {
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .long {
    font-size: 80%;
  }
`;

const Submit = styled(motion.div)`
  margin-top: 1rem;
  width: 100%;
  display: grid;
  height: 100%;
  align-items: center;
  justify-items: center;

  button {
    width: 60%;
    height: 30%;
    border-radius: 1rem;
    border: none;
    color: white;
    background: gray;
  }
`;

export default CurrentCust;
