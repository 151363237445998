import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { MdQrCodeScanner, MdDriveFileRenameOutline } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { GiTakeMyMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { VscMenu } from "react-icons/vsc";
import { IoIosSearch, IoIosArrowBack, IoIosArrowForward } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

const BottomInOrder = ({
  camShow,
  setCamShow,
  setFinishSelected,
  setSearchBar,
  page,
  setPage,
  editName,
  setEditName,
  changeFunction,
}) => {
  const [active, setAcitive] = useState(false);
  const cart = useSelector((state) => state.cart);
  const cart_ii = useSelector((state) => state?.cart_two);
  const cart_iii = useSelector((state) => state?.cart_three);
  const cart_iv = useSelector((state) => state?.cart_four);
  const cart_v = useSelector((state) => state?.cart_five);
  const [cartier, setCartier] = useState(false);

  useEffect(() => {
    if (
      (cart?.quantity &&
        cart.quantity.length > 0 &&
        cart?.name &&
        cart.name.length > 0) ||
      (cart_ii?.quantity &&
        cart_ii.quantity.length > 0 &&
        cart_ii?.name &&
        cart_ii.name.length > 0) ||
      (cart_iii?.quantity &&
        cart_iii.quantity.length > 0 &&
        cart_iii?.name &&
        cart_iii.name.length > 0) ||
      (cart_iv?.quantity &&
        cart_iv.quantity.length > 0 &&
        cart_iv?.name &&
        cart_iv.name.length > 0) ||
      (cart_v?.quantity &&
        cart_v?.quantity.length > 0 &&
        cart_v?.name &&
        cart_v?.name.length > 0)
    ) {
      setCartier(true);
    } else {
      setCartier(false);
    }
  }, [
    cart?.quantity,
    cart_ii?.quantity,
    cart_iii?.quantity,
    cart_iv?.quantity,
    cart_v?.quantity,
    cart?.name,
    cart_ii?.name,
    cart_iii?.name,
    cart_iv?.name,
    cart_v?.name,
  ]);


 

  return (
    <Container>
      <Data cartier={cartier.toString()}>
        <div>
          <div onClick={() => setCamShow(!camShow)}>
            <MdQrCodeScanner size={25} />
          </div>
        </div>

        <div>
          <div>
            <IoIosSearch size={25} onClick={() => setSearchBar(true)} />
          </div>
        </div>
        <div>
          <div style={{ cursor: "pointer" }}>
            <GiTakeMyMoney
              size={25}
              onClick={() => setFinishSelected(true)}
              className="cashmoney"
            />
          </div>
        </div>
        <div>
          <div>
            <MdDriveFileRenameOutline size={25} onClick={changeFunction} />
          </div>
        </div>
        <div className="pages">
          <div>
            <IoIosArrowForward
              size={25}
              onClick={() => page < 4 && setPage(page + 1)}
            />
          </div>
          <div>
            <IoIosArrowBack
              size={25}
              onClick={() => page > 0 && setPage(page - 1)}
            />
          </div>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  z-index: -1;
`;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  position: absolute;
  bottom: 0;
  border-top: 0.04rem solid gray;
  height: 10%;
  background: white;
  z-index: -1;

  .cashmoney {
    pointer-events: ${({ cartier }) => (cartier === "true" ? "auto" : "none")};
    color: ${({ cartier }) => (cartier === "true" ? "black" : "dimgray")};
  }

  .pages {
    display: grid;
    grid-template-rows: 50% 50%;
    align-items: center;
    justify-items: center;
  }
`;

export default BottomInOrder;
