import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useEffect } from "react";
import { publicRequest, userRequest } from "../../requestmethods";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import DeleteProduct from "../../components/products/deleteproduct";
import { HiOutlineUserCircle } from "react-icons/hi";
import QRcode from "qrcode";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import HalfSpinner from "../../components/loaders/halfspinner";

const PerUser = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [fetchedUser, setFetchedUser] = useState(null);
  const [trackProduct, setTrackProduct] = useState(null);
  const [salesTrack, setSalesTrack] = useState(null);
  const [remainingStock, setRemainingStock] = useState(null);
  const [listingAmount, setListingAmount] = useState(null);
  const [input, setInput] = useState({});
  const [file, setFile] = useState(null);
  const [worker, setWorker] = useState(null);
  const [workerCheck, setWorkerCheck] = useState(null);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetching = async () => {
      const resUser = await userRequest.get(`/users/find/${id}`);

      const generateQrcode = async () => {
        try {
          const response = await QRcode.toDataURL(id);
          setImageUrl(response);
        } catch (err) {
          console.log(err);
        }
      };

      await generateQrcode();
      setFetchedUser(resUser?.data);
      setWorker(resUser?.data.isWorker);
      setWorkerCheck(resUser?.data.isWorker);
    };

    fetching();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const onlyInputs = async () => {
      const userdata = {
        isWorker: worker,
      };
      if (workerCheck !== worker) {
        const res = await userRequest.put(`/users/find/${id}`, userdata);

        console.log(res);

        if (res?.statusText === "OK") {
          window.location.reload();
        }
      } else {
        setIsLoading(false);
        return;
      }
    };
    onlyInputs();
  };

  useEffect(() => {
    const calculator = () => {
      const totalAdded = [];
      const totalSales = [];

      trackProduct?.added.map((d) => {
        totalAdded.push(d.amount);
      });

      trackProduct?.sales.map((d) => {
        totalSales.push(d.amount);
      });

      const totalStock = totalAdded.reduce((a, b) => a + b, 0);
      const totalStockSales = totalSales.reduce((a, b) => a + b, 0);

      setRemainingStock(totalStock - totalStockSales);

      setListingAmount(totalStock);
      setSalesTrack(totalStockSales);
    };

    calculator();
  }, [trackProduct]);

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {deleteCheck && (
            <DeleteProduct
              deleteCheck={deleteCheck}
              setDeleteCheck={setDeleteCheck}
              id={id}
            />
          )}
        </AnimatePresence>
        <Product>
          <Img>
            {fetchedUser?.img ? (
              <img src={fetchedUser?.img} alt="" />
            ) : (
              <HiOutlineUserCircle size={30} />
            )}
            <div>
              <div>
                {imageUrl ? (
                  <a href={imageUrl} download>
                    <img src={imageUrl} alt="img" />
                  </a>
                ) : null}
              </div>
            </div>
          </Img>
          <Generate>
            <div>Image</div>
            <div>
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            </div>
          </Generate>

          <Contain>
            <div className="sec">
              <div className="secname">User</div>
              <div>{fetchedUser?.username}</div>
            </div>
            <div className="sec">
              <div className="secname">Phone no</div>
              <div>{fetchedUser?.phonenumber}</div>
            </div>

            <div className="sec">
              <div className="secname">ss</div>
              <div>{fetchedUser?.secretstatus ? "Done" : "Not yet"}</div>
            </div>

            <div className="secstocks">
              <div className="stocks">
                <div className="secname">Worker</div>
                <div
                  className={worker ? "yes" : "in"}
                  name="yes"
                  onClick={() => setWorker(true)}
                >
                  Yes
                </div>

                <div
                  className={worker ? "in" : "no"}
                  name="no"
                  onClick={() => setWorker(false)}
                >
                  No
                </div>
              </div>
              <div className="delete">
                <div className="deletebutton">
                  <button onClick={(e) => setDeleteCheck(true)}>
                    Delete product
                  </button>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="loader">
                <HalfSpinner />
              </div>
            ) : (
              <div onClick={handleSubmit} className="button">
                <button>Submit</button>
              </div>
            )}
          </Contain>
        </Product>
        <Info>
          <div className="stock">
            <div>Remaining Stock</div>
            <div> {remainingStock && remainingStock}</div>
          </div>
          <div className="salesstock">
            <div>Total sold </div>
            <div>{salesTrack && salesTrack}</div>
          </div>
        </Info>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  width: 100%;
  top: 20%;
  bottom: 10%;
  overflow: hidden;
`;

const Product = styled(motion.div)`
  position: absolute;
  height: 90%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  top: 0;

  .yes {
    background: gray;
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }
  .no {
    background: gray;
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }
`;

const Contain = styled(motion.div)`
  padding-top: 1rem;
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 1rem;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2rem;

  input {
    padding: 0.5rem;
    border: 0.01rem solid gray;
    border-radius: 2rem;
    width: 100%;
  }

  .secstocks {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .delete {
      display: grid;
      align-items: center;
      justify-items: center;
      height: 100%;

      .deletebutton {
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        width: 100%;
      }

      button {
        border: 0.01rem solid gray;
        border-radius: 2rem;
        height: 40%;
        width: 70%;
      }
    }
  }

  .sec {
    margin-top: 0.5rem;
  }
  .secname {
    width: 90%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0.2rem;
  }

  .in {
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }

  .button {
    width: 100%;
    height: 2rem;

    button {
      width: 70%;
      height: 100%;
      margin-left: 15%;
      margin-right: 15%;
      border-radius: 2rem;
      border: 0.01rem solid gray;
    }
  }

  .loader {
    display: grid;
    justify-items: center;
    align-items: center;
  }
`;

const Img = styled(motion.div)`
  height: 20%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .imginfo {
    display: grid;
    align-items: end;
  }
`;

const Info = styled(motion.div)`
  position: absolute;

  height: 10%;
  width: 100%;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  border-top: 0.02rem solid gray;

  .stock {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }

  .salesstock {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }
`;

const Generate = styled(motion.div)`
  margin-top: 10%;
  padding: 1rem;
  margin-left: 10%;
`;

export default PerUser;
