import { createSlice, current } from "@reduxjs/toolkit";

const cartSlice_Three = createSlice({
  name: "cart_three",
  initialState: {
    name: [],
    products: [],
    quantity: [0],
    total: [0],
  },
  reducers: {
    addProduct_iii: (state, action) => {
      state.quantity[0] += 1;
      state.products.push(action.payload);
      state.total[0] +=
        action.payload.price * action.payload.pcs * action.payload.quantity;
    },
    add_iii: (state, action) => {
      state.products[action.payload.index].quantity = action.payload.no;

      let currentTotal = [];

      current(state).products.map((d) => {
        return currentTotal.push(d.quantity * d.price * d.pcs);
      });

      state.total[0] = currentTotal.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
    },
    del_iii: (state, action) => {
      state.products[action.payload.index].quantity = action.payload.no;

      let currentTotal = [];

      current(state).products.map((d) => {
        return currentTotal.push(d.quantity * d.price * d.pcs);
      });

      state.total[0] = currentTotal.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
    },
    remove_iii: (state, action) => {
      state.products.splice(action.payload.index, 1);

      state.quantity[0] -= 1;
      let currentTotal = [];

      current(state).products.map((d) => {
        return currentTotal.push(d.quantity * d.price * d.pcs);
      });

      state.total[0] = currentTotal.reduce(
        (partialSum, a) => partialSum + a,
        0
      );
    },
    nameChange_iii: (state, action) => {
      if (action.payload.custName === null || action.payload.custName === "") {
        state.name = [];
      } else {
        state.name[0] = action.payload.custName;
      }
    },
    empty_iii: (state) => {
      state.name = [];
      state.products = [];
      state.quantity = [0];
      state.total = [0];
    },
  },
});

export const {
  addProduct_iii,
  add_iii,
  del_iii,
  remove_iii,
  empty_iii,
  nameChange_iii,
} = cartSlice_Three.actions;
export default cartSlice_Three.reducer;
