import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { IoBag } from "react-icons/io5";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLocationPin } from "@fortawesome/free-solid-svg-icons";

const Cartier = ({ producto, droppin, setSeeCart }) => {
  const quantity = useSelector((state) => state.cart.quantity);

  return (
    <Container>
      <Contain>
        <Words>
          <div>store</div>
          <div>Kariakoo Narung'ombe Street</div>
        </Words>
        <Nicon className="icon">
          <div className="innerIcon">
            <div
              to="/user/checkout"
              className="linko"
              onClick={() => setSeeCart(true)}
            >
              <IoBag size={40} className="align" />
              <div className="cartAlter">
                <div className="innerAlter">{quantity}</div>
              </div>
            </div>
            <div className={droppin ? "locopin" : "nopin"}>
              <FontAwesomeIcon
                icon={faLocationPin}
                className="pin"
                size="4x"
              ></FontAwesomeIcon>
              <div className="fontimg">
                <img src={producto && producto.img} alt="" />
              </div>
            </div>
          </div>
        </Nicon>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  @media (max-width: 768px) {
    position: fixed;
    bottom: 0;
    background-color: rgb(243, 241, 243);
    width: 100%;
    height: 10%;
  }
`;

const Extras = styled(motion.div)``;

const Contain = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 4fr 1fr;
    align-items: center;
    border-top: 0.05rem solid gray;
  }
`;

const Words = styled(motion.div)`
  @media (max-width: 540px) {
    margin-left: 1rem;
  }
`;

const Nicon = styled(motion.div)`
  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 1fr;
    align-items: center;
    justify-items: end;

    .align {
      margin-right: 1rem;
    }

    .innerIcon {
      .linko {
        margin-right: 10%;
        display: grid;
        align-items: center;
        justify-items: center;

        .align {
          position: absolute;
          right: 0.045rem;
        }
        .cartAlter {
          position: absolute;
          color: white;
          right: 1.285rem;
          margin-top: 0.5rem;
          text-align: center;
          height: 2rem;
          width: 2rem;
          display: grid;
          align-items: center;
          justify-items: center;
        }
      }
    }

    .locopin {
      position: fixed;
      bottom: 3.5rem;
      height: 5rem;
      width: 3.75rem;
      display: grid;
      grid-template-columns: 1fr;
      right: 0.35rem;
      align-items: center;
      justify-items: center;
      transform: scale(1.1);
      transition: all 0.2s ease-in-out;

      .fontimg {
        display: grid;
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        grid-row: 1;
        grid-column: 1;
        height: 45%;
        width: 60%;
        align-items: center;
        justify-items: center;
        border-radius: 50%;
        background: white;

        img {
          bottom: 0;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .pin {
        grid-row: 1;
        grid-column: 1;
      }
    }

    .nopin {
      position: fixed;
      bottom: 2.5rem;
      height: 5rem;
      width: 3.75rem;
      display: none;
      grid-template-columns: 1fr;
      right: 0rem;
      align-items: center;
      justify-items: center;

      .fontimg {
        display: grid;
        position: absolute;
        top: 1rem;
        bottom: 1rem;
        grid-row: 1;
        grid-column: 1;
        height: 45%;
        width: 60%;
        align-items: center;
        justify-items: center;
        border-radius: 50%;
        background: white;

        img {
          bottom: 0;
          max-height: 100%;
          max-width: 100%;
        }
      }

      .pin {
        grid-row: 1;
        grid-column: 1;
      }
    }
  }
`;

export default Cartier;
