import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { publicRequest, userRequest } from "../../requestmethods";
import moment from "moment";
import { FcBullish, FcBearish } from "react-icons/fc";

const StocksData = ({ todaysDate, todaysUnix }) => {
  const [fetchedStock, setFetchedStock] = useState(null);
  const [fullStock, setFullStock] = useState(null);
  const [stockCalc, setStockCalc] = useState(null);
  const todaysM = moment(todaysUnix).format("MM");
  const todaysY = moment(todaysUnix).format("YYYY");
  const todaysD = moment(todaysUnix).format("DD");
  const previousDay = moment(todaysUnix)
    .subtract(1, "days")
    .endOf("day")
    .format("DD");

  const previousMonth = moment(todaysUnix)
    .subtract(1, "days")
    .endOf("day")
    .format("MM");

  const previousYear = moment(todaysUnix)
    .subtract(1, "days")
    .endOf("day")
    .format("YYYY");

  useEffect(() => {
    const loader = async () => {
      const resStocks = await userRequest.get("/producttrack");

      const resProductsCol = await publicRequest.get(`products/`);

      setFetchedStock(resStocks.data);
      setFullStock(resProductsCol.data);
    };

    loader();
  }, []);

  useEffect(() => {
    const calculations = async () => {
      const todaysData = [];

      await fetchedStock?.map(async (d) => {
        const innerTodayTotal = [];
        const innerPreviousTotal = [];

        for (let i = 0; i < d.sales.length; i++) {
          const dDay = moment(d.sales[i].date * 1000).format("DD");
          const dMonth = moment(d.sales[i].date * 1000).format("MM");
          const dYear = moment(d.sales[i].date * 1000).format("YYYY");

          //    console.log(`${todaysD}/${todaysM}/${todaysY}`);

          if (
            `${dDay}/${dMonth}/${dYear}` === `${todaysD}/${todaysM}/${todaysY}`
          ) {
            innerTodayTotal.push(d.sales[i].amount);
          }
        }
        for (let i = 0; i < d.sales.length; i++) {
          const dpDay = moment(d.sales[i].date * 1000).format("DD");
          const dpMonth = moment(d.sales[i].date * 1000).format("MM");
          const dpYear = moment(d.sales[i].date * 1000).format("YYYY");

          if (
            `${dpDay}/${dpMonth}/${dpYear}` ===
            `${previousDay}/${previousMonth}/${previousYear}`
          ) {
            innerPreviousTotal.push(d.sales[i].amount);
          }
        }

        const totalamountToday = innerTodayTotal.reduce((a, b) => a + b, 0);
        const totalamountPrevious = innerPreviousTotal.reduce(
          (a, b) => a + b,
          0
        );

        await fullStock?.map((l) => {
          if (l._id === d.productId) {
            const setUpToday = {
              totalsold: totalamountToday,
              difference: totalamountToday - totalamountPrevious,
              item: l._id,
              img: l.img,
              artNo: l.artNo,
            };

            todaysData.push(setUpToday);
          }
        });
      });

      setStockCalc(todaysData);
    };

    calculations();
  }, [fetchedStock, todaysUnix]);

  return (
    <Container>
      <Contain>
        <Header>
          <tr>
            <td>Image</td>
            <td>artNO</td>
            <td>Total Sold</td>
            <td>Change</td>
          </tr>
        </Header>

        <Stocks>
          {stockCalc?.map((d) => {
            const results = () => {
              if (d.difference < 0) {
                return false;
              } else {
                return true;
              }
            };

            return (
              <Product key={d.item}>
                <td className="img">
                  <img src={d.img} />
                </td>
                <td>
                  <div>{d.artNo}</div>
                </td>
                <td>
                  <div>{d.totalsold}</div>
                </td>
                <td>
                  <div>
                    {results() ? (
                      <FcBullish size={20} />
                    ) : (
                      <FcBearish size={20} />
                    )}
                  </div>
                </td>
              </Product>
            );
          })}
        </Stocks>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 20%;
  bottom: 10%;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`;

const Contain = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 20%;
`;

const Stocks = styled(motion.tbody)`
  height: 20%;
  width: 100%;
`;

const Header = styled(motion.thead)`
  text-align: center;
  padding-bottom: 1rem;
`;

const Product = styled(motion.tr)`
  outline: 0.01rem solid gray;
  border-radius: 0.5rem;
  text-align: center;

  .img {
    height: 5rem;
    width: 20%;
  }

  img {
    max-height: 100%;
    mx-width: 100%;
  }

  td {
  }
`;

export default StocksData;
