import React from "react";
import { useState, useEffect } from "react";
import moment from "moment";
import styled from "styled-components";
import { motion } from "framer-motion";
import BottomNav from "../../components/bottomnav/bottomhomenav";
import NavbarDate from "../../components/navbar/navbarDate";
import StocksCustomer from "../../components/stocksCustomer/stocksCustomer";

const StocksCustomerPage = () => {
  const [todaysDate, setTodaysDate] = useState("");
  const [todaysUnix, setTodaysUnix] = useState("");
  const date = new Date();
  const stringed = JSON.stringify(date).slice(1, -15);
  const dateDate = moment(stringed).format("DD");
  const dateMonth = moment(stringed).format("MM");
  const dateYear = moment(stringed).format("YYYY");
  const actualD = `${dateMonth}/${dateDate}/${dateYear}`;
  const [stringDate, setStringDate] = useState(actualD);

  const unixDate = moment(
    `${stringDate} 23:59:59`,
    "MM/DD/YYYY HH:mm:ss"
  ).valueOf();
  const actualDate = moment(unixDate).format("YYYY-MM-DD");

  //  console.log(actualDate);

  useEffect(() => {
    setTodaysDate(actualDate);
    setTodaysUnix(unixDate);
  }, [stringDate]);

  return (
    <Body>
      <NavbarDate
        todaysDate={todaysDate}
        setTodaysDate={setTodaysDate}
        todaysUnix={todaysUnix}
        setTodaysUnix={setTodaysUnix}
        setStringDate={setStringDate}
      />
      <StocksCustomer todaysDate={todaysDate} todaysUnix={todaysUnix} />
      <BottomNav />
    </Body>
  );
};
const Body = styled(motion.div)``;

export default StocksCustomerPage;
