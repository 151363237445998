import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { publicRequest } from "../../requestmethods";
import { IoIosSearch } from "react-icons/io";
import { useState } from "react";
import LoadingSpinner from "../../components/LoadingSpinner";

const ProductEdit = ({ startSearch, setStartSearch }) => {
  const [fetchedProducts, setFetchedProducts] = useState([]);
  const [cat, setCat] = useState("");
  const [sort, setSort] = useState("newest");
  const [view, setView] = useState(8);
  const [assesedProducts, setAssessedProducts] = useState([]);
  const [maxLength, setMaxLength] = useState(null);
  const [showLess, setShowLess] = useState(false);

  useEffect(() => {
    const productFetching = async () => {
      const resProducts = await publicRequest.get(
        cat ? `/products?category=${cat}` : "/products"
      );

      setFetchedProducts(resProducts?.data);
      resProducts?.data && setMaxLength(resProducts?.data?.length);
    };

    productFetching();
  }, [cat]);

  useEffect(() => {
    const maxi = () => {
      if (maxLength <= view) {
        setShowLess(true);
      }

      if (maxLength === 0) {
        setView(0);
      }
    };

    maxLength && maxi();
  }, [view, maxLength]);

  useEffect(() => {
    const controller = () => {
      const miniControl = () => {
        if (fetchedProducts[0] === undefined || maxLength === 0) {
          setShowLess(true);
        }
      };

      if (maxLength && view >= maxLength) {
        setView(maxLength);
        setShowLess(true);
      }
      if (maxLength && view >= maxLength && miniControl()) {
        setView(maxLength);
        setShowLess(true);
      }

      if (maxLength > view) {
        setShowLess(false);
      }

      miniControl();
    };
    maxLength && controller();
  }, [view, fetchedProducts, maxLength]);

  useEffect(() => {
    const itemViewer = () => {
      const viewer = [];

      for (let i = 0; i < view; i++) {
        viewer.push(fetchedProducts[i]);
      }
      setAssessedProducts(viewer);
    };

    itemViewer();
  }, [fetchedProducts, cat, view]);

  return (
    <Container>
      <Contain>
        {startSearch && (
          <FilterContainer className={startSearch ? "nonfilter" : "filter"}>
            <div className="textDiv">
              <input type="text" onChange={(e) => setCat(e.target.value)} />
            </div>
            <div className="searchDiv">
              <IoIosSearch size={25} className="search" />
            </div>
          </FilterContainer>
        )}

        <Body startsearch={startSearch.toString()}>
          <Products>
            {assesedProducts.length &&
              assesedProducts?.map((d, key) => {
                return (
                  <Product key={key}>
                    <Img>
                      <img src={d?.img} alt="" />
                    </Img>
                    <Info>
                      <div className="title">
                        <div>ID| {d?._id}</div>
                        <div>artNo| {d?.artNo}</div>
                      </div>
                      <div className="info">
                        <div>pcs| {d?.pcs}</div>
                        <div>prices| {d?.price}</div>
                        <div>size| {d?.size}</div>
                      </div>
                    </Info>
                    <Edit>
                      <Link
                        to={`editproduct/${d?._id}`}
                        style={{ textDecoration: "none" }}
                        className="link"
                      >
                        <button>edit</button>
                      </Link>
                    </Edit>
                  </Product>
                );
              })}
          </Products>
          <More>
            {!showLess && assesedProducts[0] && (
              <div onClick={() => setView(view + 8)}>load more ...</div>
            )}
          </More>
        </Body>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  width: 100%;
  top: 20%;
  bottom: 10%;
  overflow-x: hidden;
  overflow-y: scroll;
`;

const Products = styled(motion.div)``;

const Product = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 2fr 0.5fr;

  align-items: center;
  margin-bottom: 1rem;
`;

const Contain = styled(motion.div)``;

const Body = styled(motion.div)`
  position: absolute;
  width: 100%;
  display: grid;
  grid-gap: 0.5%;
  top: ${({ startsearch }) => (startsearch === "false" ? "0%" : "10%")};
`;

const Img = styled(motion.div)`
  height: 100%;
  width: 100%;

  img {
    max-height: 90%;
    max-width: 90%;
  }
`;

const Info = styled(motion.div)`
  display: grid;

  .title {
  }
`;

const Edit = styled(motion.div)`
  display: grid;
  margin-right: 1rem;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-items: center;

  .link {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  button {
    width: 80%;
    height: 30%;
    margin-top: 35%;
    margin-bottom: 35%;
    border: none;
    border-radius: 2rem;
  }
`;

const FilterContainer = styled(motion.div)`
  position: absolute;
  display: grid;
  grid-template-columns: 5fr 1fr;
  align-items: center;
  width: 100%;
  margin-left: 2.5%;
  margin-right: 2.5%;
  height: 5%;
  z-index: 5;
  background: white;

  .textDiv {
    width: 100%;
    height: 100%;
    display: grid;
    justify-items: end;
    align-items: center;
    input {
      width: 90%;
      height: 70%;
      padding: 5%;
      border-radius: 2rem 0 0 2rem;
      border: 0.02rem solid gray;
    }
  }

  .searchDiv {
    border: 0.02rem solid gray;
    display: grid;
    justify-items: start;
    align-items: center;
    width: 50%;
    height: 70%;
    border-radius: 0 2rem 2rem 0;
  }
`;

const More = styled(motion.div)`
  display: grid;
  align-items: center;
  justify-items: end;

  margin-right: 4.5%;
`;

export default ProductEdit;
