import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import CreateWorkerCustomer from "./workerscustomersregister";
import BottomNav from "../../components/bottomnav/bottomhomenav";

const WorkerCreateCustomerPage = () => {
  return (
    <Body>
      <Navbar />
      <CreateWorkerCustomer />
      <BottomNav />
    </Body>
  );
};
const Body = styled(motion.div)``;

export default WorkerCreateCustomerPage;
