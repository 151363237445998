import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { HiOutlineUserCircle } from "react-icons/hi";
import { Link } from "react-router-dom";

const Navbar = () => {
  return (
    <Container>
      <Data>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="title">
            <h2>UNISON</h2>
          </div>
        </Link>
        <div>
          <Link to="/user" style={{ textDecoration: "none" }}>
            <div className="sidedata">
              <HiOutlineUserCircle size={25} />
            </div>
          </Link>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  @media (max-width: 768px) {
  }
`;

const Data = styled(motion.div)`
  position: absolute;
  z-index: 1;
  height: 20%;
  width: 100%;
  background-image: linear-gradient(to left top, blue, red);
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;

  .title {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .sidedata {
    display: grid;
    justify-items: end;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
    color: white;
  }
`;

const Items = styled(motion.div)``;
export default Navbar;
