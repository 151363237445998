import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { motion } from "framer-motion";
import { AiOutlineUser } from "react-icons/ai";
import { userRequest } from "../../requestmethods";
import { loginUserChanged } from "../../redux/userredux";
import { useDispatch } from "react-redux";
import QRCode from "qrcode";

const UserPage = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const [initialQr, setInitionQr] = useState(null);
  const [username, setUserName] = useState(null);
  const [twoFactorStatus, setTwoFactorStatus] = useState(false);
  const [userSecrets, setUserSecrets] = useState(false);
  const [code, setCode] = useState(null);
  const [url, setUrl] = useState("");
  const [qr, setQr] = useState("");
  const userFacts = useSelector((state) => state.user.currentUser.secretstatus);
  const userLink = useSelector(
    (state) => state.user.currentUser.tempsecret?.otpauth_url
  );

  useEffect(() => {
    //console.log(userFacts);
  }, []);

  useEffect(() => {
    if (user.length !== 0) {
      if (user.secret) {
        setUserSecrets(true);
      }
    }
  }, []);

  useEffect(() => {
    setInitionQr(user.tempsecret.otp);
    setUserName(user.username);
  }, []);

  useEffect(() => {
    if (!userFacts) {
      setUrl(() => userLink);
    }

    const generateQRCode = () => {
      if (userLink) {
        QRCode.toDataURL(
          userLink,
          {
            width: 100,
            margin: 2,
          },
          (err, data) => {
            if (err) return console.error(err);

            setQr(data);
          }
        );
      }
    };

    generateQRCode();
  }, [twoFactorStatus]);

  const showInputs = () => {
    if (!userFacts && twoFactorStatus) {
      return true;
    }
  };

  const handleFactor = async () => {
    const changes = {
      code: code,
      secret: user.tempsecret.hex,
      tempsecret: {},
      secretstatus: true,
    };

    try {
      const res = await userRequest.put(`/users/${user._id}`, changes);

      const chainStatus = res.data;
      console.log(chainStatus, "jello");
      dispatch(loginUserChanged({ chainStatus }));
    } catch (err) {
      console.log(err);
    }
  };

  const reEnabled = async () => {
    try {
      const newEnable = {
        recode: "enable",
      };
      const res = await userRequest.put(`/users/${user._id}`, newEnable);

      console.log(res, "hello");
      const dataChanges = res.data;

      dispatch(loginUserChanged({ dataChanges }));
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Container>
      <Contain>
        <Top>
          <div>{<AiOutlineUser size={40} />}</div>
          <div>{username}</div>
        </Top>
        <div>
          2Factor Authentication{" "}
          <div>
            {userFacts ? (
              <div onClick={reEnabled}>disable</div>
            ) : (
              <div onClick={(e) => setTwoFactorStatus(true)}> enable</div>
            )}
          </div>
          <div>{showInputs() && <img src={qr} alt="" />}</div>
          <div></div>
          <div>
            {showInputs() && (
              <input type="number" onChange={(e) => setCode(e.target.value)} />
            )}
          </div>
          <div>
            {showInputs() && <button onClick={handleFactor}>submit</button>}
          </div>
        </div>
      </Contain>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  width: 100%;
  top: 20%;
  bottom: 10%;
`;

const Contain = styled(motion.div)``;

const Top = styled(motion.div)`
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

export default UserPage;
