import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { publicRequest, userRequest } from "../../requestmethods";
import moment from "moment";
import { authentication } from "../../regs/firebase";
import { RecaptchaVerifier, signInWithPhoneNumber } from "firebase/auth";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import Otp from "../../components/register/otp";

const Register = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otp, setOtp] = useState("");
  const [enableEntry, setEnableEntry] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [enterOtp, setEnterOtp] = useState(false);
  const rando = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
  const norando = moment(new Date()).unix();
  const randomEmail = `${rando + norando}@${rando}.com`;

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        callback: (response) => {},
      },
      authentication
    );
  };

  const requestOTP = async (e) => {
    e.preventDefault();

    if (phoneNumber.length >= 10) {
      setEnableEntry(true);
      generateRecaptcha();
      const appVerifier = window.recaptchaVerifier;
      await signInWithPhoneNumber(authentication, phoneNumber, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;

          setEnterOtp(true);
        })
        .catch((error) => {
          // Error; SMS not sent
          console.log(error);
        });
    }
  };

  const verifyOTP = async (e) => {
    let otp = e.target.value;

    setOtp(otp);

    if (otp.length === 6) {
      const confirmationResult = window.confirmationResult;

      confirmationResult
        .confirm(otp)
        .then(async (result) => {
          // User signed in successfully.
          const user = await result.user;

          console.log(user);

          const poster = {
            username: username,
            email: randomEmail,
            password: password,
            phonenumber: phoneNumber,
            token: await user?.accessToken,
            nowUser: await user?.uid,
          };

          if (user.accessToken) {
            const res = await userRequest.post("/auth/register", poster);
          }

          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          console.log(error);
        });
    }
  };

  /* const ontrial = async (e) => {
    e.preventDefault();

    const poster = {
      username: username,
      email: randomEmail,
      password: password,
      phonenumber: phoneNumber,
    };

    const res = await publicRequest.post("/auth/register", poster);
  };*/

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {enterOtp && (
            <Otp
              enterOtp={enterOtp}
              setEnterOtp={setEnterOtp}
              otp={otp}
              setOtp={setOtp}
              verifyOTP={verifyOTP}
            />
          )}
        </AnimatePresence>
        <Wrapper>
          <Title>CREATE AN ACCOUNT</Title>
          <Form>
            <Data>
              <Input
                type="tel"
                placeholder="phone number"
                onChange={(e) => setPhoneNumber(e.target.value)}
              />
              <Input
                type="username"
                placeholder="username"
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                type="password"
                placeholder="password"
                onChange={(e) => setPassword(e.target.value)}
              />

              {/* enableEntry && (
                <Input
                  type="number"
                  placeholder="otp"
                  value={otp}
                  onChange={verifyOTP}
                />
              )*/}
            </Data>

            {!enableEntry && (
              <Button onClick={requestOTP}>
                <button>CREATE </button>
              </Button>
            )}
          </Form>
        </Wrapper>

        <div id="recaptcha-container"></div>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`

#recaptcha-container{
    position:absolute:
    bottom:0;
    height:5rem;
    width:5rem;
    right:0;
    margin-right:10%;

}



`;
const Wrapper = styled(motion.div)``;
const Title = styled(motion.div)`
  @media (max-width: 540px) {
    height: 10vh;
    display: grid;
    align-items: center;
    justify-items: center;
    font-size: 1.5rem;
    font-weight: 100;
  }
`;

const Data = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    margin-top: 50%;
    margin-left: 5%;
    margin-right: 5%;

    justify-items: center;
    align-items: center;
  }
`;
const Form = styled(motion.form)``;
const Input = styled(motion.input)`
  @media (max-width: 540px) {
    width: 100%;
    height: 100%;
    margin: 0.5rem;
    padding: 0.5rem;

    border: 0.04rem solid gray;
  }
`;
const Agreement = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    margin: 1rem;
    font-size: 0.6rem;
  }
`;
const Button = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    height: 5vh;
    width: 100%;

    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    margin: 0.5rem 0.5rem 2rem 0.5rem;

    button {
      height: 100%;
      width: 80%;
      border-radius: 20rem;
      border: 0.02rem solid gray;
      -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

export default Register;

/*
import React from "react";
import { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { publicRequest } from "../../requestmethods";
import { useHistory } from "react-router";
//import { auth } from "../../firebase";
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";

const Register = ({ successfulReg, setSuccessfulReg }) => {
  const [username, setUsername] = useState("");
  const [userBoo, setUserBoo] = useState(false);
  const [phonenumber, setPhoneNumber] = useState("");
  const [phoneBoo, setPhoneBoo] = useState(false);
  const [email, setEmail] = useState("");
  const [emailBoo, setEmailBoo] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordBoo, setPasswordBoo] = useState(false);
  const [conPassword, setConPassword] = useState("");
  const [conBoo, setConBoo] = useState(false);
  const [notEqual, setNotEqual] = useState(false);
  const [registerResults, setRegisterResults] = useState(null);
  const history = useHistory();

  const registerUser = async (e) => {
    e.preventDefault();

    const innerRegister = async () => {
      if (username !== "") {
        if (phonenumber !== "") {
          if (email !== "") {
            if (password !== "") {
              if (conPassword !== "") {
                if (password === conPassword) {
                  const data = {
                    username: username,
                    email: email,
                    password: password,
                    phonenumber: phonenumber,
                  };

                  try {
                    const auth = getAuth();
                    await createUserWithEmailAndPassword(auth, email, password)
                      .then((userCredential) => {
                        // Signed in
                        const user = userCredential.user;

                        console.log(user);
                        // ...
                      })
                      .catch((error) => {
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // ..
                      });
                  } catch (err) {
                    console.log(err);
                  }

                  try {
                    const res = await publicRequest.post(
                      "/actualauth/register",
                      data
                    );

                    console.log(res.data);

                    await setSuccessfulReg(true);
                    const succ = () => {
                      let interval = setInterval(() => {
                        setSuccessfulReg(false);
                      }, 2000);

                      return () => {
                        clearInterval(interval);
                      };
                    };

                    await succ();

                    return res;
                  } catch (err) {
                    console.log(err);
                  }
                } else {
                  setNotEqual(true);
                }
              } else {
                setConBoo(true);
              }
            } else {
              setPasswordBoo(true);
            }
          } else {
            setEmailBoo(true);
          }
        } else {
          setPhoneBoo(true);
        }
      } else {
        setUserBoo(true);
      }
    };

    await innerRegister()
      .then(history.push(`/login/`))
      .catch((error) => console.log(error));
  };

  return (
    <Container>
      <Wrapper>
        <Title>CREATE AN ACCOUNT</Title>
        <Form>
          <Data>
            <Input
              placeholder="first name and last name "
              onChange={(e) => setUsername(e.target.value)}
            />
            {userBoo && <div> Enter name</div>}
            <Input
              placeholder="phone number"
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            {phoneBoo && <div> Enter Phone number</div>}
            <Input
              placeholder="email"
              onChange={(e) => setEmail(e.target.value)}
            />
            {emailBoo && <div> Enter Email</div>}
            <Input
              placeholder="password"
              type="password"
              onChange={(e) => setPassword(e.target.value)}
            />
            {passwordBoo && <div> Enter Password</div>}
            <Input
              placeholder="confirm password"
              type="password"
              onChange={(e) => setConPassword(e.target.value)}
            />
            {conBoo && <div> confirm Password</div>}
            {notEqual && <div> Passwords do not match</div>}
          </Data>
          <Agreement>
            By creating an account, I consent to the processing of my personal
            data in accordance with the <b>PRIVACY POLICY</b>
          </Agreement>
          <Button onClick={registerUser}>
            <button>CREATE </button>
          </Button>
        </Form>
      </Wrapper>
    </Container>
  );
};

const Container = styled(motion.div)``;
const Wrapper = styled(motion.div)``;
const Title = styled(motion.div)`
  @media (max-width: 540px) {
    height: 10vh;
    display: grid;
    align-items: center;
    justify-items: center;
    font-size: 1.5rem;
    font-weight: 100;
  }
`;

const Data = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    grid-template-columns: 1fr;
    margin: 1rem 0.5rem 1rem 0.5rem;
    grid-gap: 1rem;
    justify-items: center;
    align-items: center;

    height: 60vh;
  }
`;
const Form = styled(motion.form)``;
const Input = styled(motion.input)`
  @media (max-width: 540px) {
    width: 100%;
    height: 100%;
    margin: 0.5rem;
    padding: 0.5rem;

    border: 0.04rem solid gray;
  }
`;
const Agreement = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    align-items: center;
    margin: 1rem;
    font-size: 0.6rem;
  }
`;
const Button = styled(motion.div)`
  @media (max-width: 540px) {
    display: grid;
    height: 5vh;
    width: 100%;

    grid-template-rows: 1fr;
    justify-items: center;
    align-items: center;
    margin: 0.5rem 0.5rem 2rem 0.5rem;

    button {
      height: 100%;
      width: 80%;
      border-radius: 20rem;
      border: 0.02rem solid gray;
      -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

export default Register;

*/
