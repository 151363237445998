import React, { useState } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import QrReader from "react-qr-reader";

const NavbarCam = ({
  camShow,
  setCamShow,
  setScanResultWebCam,
  setShowArrow,
  searchBAr,
}) => {
  const [allowCapture, setAllowCapture] = useState(false);

  const handleCapture = () => {
    setAllowCapture(true);
  };

  const handleCamShow = () => {
    setCamShow(!camShow);
  };

  const handleErrorWebCam = (err) => {
    console.log(err);
  };

  const handleScanWebCam = (results) => {
    if (results) {
      setScanResultWebCam(results);

      setShowArrow(true);

      setInterval(() => {
        setShowArrow(false);
      }, 1500);
    }
  };

  return (
    <Container>
      <Data>
        <Link to="/" style={{ textDecoration: "none" }}>
          <div className="title">
            <h2>UNISON</h2>
          </div>
        </Link>
        <div className="cam">
          <div className="qrreader">
            {camShow && (
              <QrReader
                showViewFinder={true}
                delay={allowCapture ? 400 : 400}
                onError={handleErrorWebCam}
                onScan={handleScanWebCam}
              />
            )}
          </div>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  @media (max-width: 540px) {
  }

  z-index: -1;
`;

const Data = styled(motion.div)`
  position: absolute;
  z-index: -1;
  height: 20%;
  width: 100%;
  background-image: linear-gradient(to left top, blue, red);
  margin-bottom: 2rem;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: end;

  .title {
    color: white;
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .cam {
    position: absolute;
    height: 100%;
    width: 50%;
    display: grid;
    justify-items: center;
    align-items: center;
    overflow: hidden;

    right: 0;

    .qrreader {
      position: relative;

      width: 80%;
    }
  }
`;

const Items = styled(motion.div)``;
export default NavbarCam;
