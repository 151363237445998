import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestmethods";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import QRcode from "qrcode";
import { IoIosCloseCircleOutline } from "react-icons/io";

const FinalTransPage = ({
  transCust,
  orderCode,
  setGetToFinal,
  fetchedProducts,
  handleJumla,
  customerId,
  jumpToFinal,
  nameId,
}) => {
  //start state
  const user = useSelector((state) => state.user.currentUser);
  const componentRef = useRef();
  const [madeOrder, setMadeOrder] = useState(null);
  const [paidAmount, setPaidAmount] = useState(null);
  const [paidTotal, setPaidTotal] = useState(null);
  const [toBePaid, setToBePaid] = useState(null);
  const [transDate, setTransDate] = useState(null);
  const [transStatus, setTransStatus] = useState(null);
  const [imageUrl, setImageUrl] = useState("");
  //end state

  console.log(transCust);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const customDetails = async () => {
      try {
        if (user.isAdmin) {
          console.log(customerId);
          console.log(transCust._id);
          console.log(orderCode);

          const resTrans = await userRequest.get(
            `/customers/transactions/${
              jumpToFinal ? customerId : transCust._id
            }?transactions=${orderCode}`
          );

          resTrans && console.log(resTrans.data);

          const fetchedTrans = resTrans.data.transactions[0];

          setTransDate(
            moment.unix(fetchedTrans.date).format("DD/MM/YYYY, HH:mm")
          );
          setTransStatus(fetchedTrans.orderstatus);
          setToBePaid(fetchedTrans.amount);

          if (resTrans) {
            const resPays = await userRequest.get(
              `/customers/payments/${
                jumpToFinal ? customerId : transCust._id
              }?payments=${orderCode}`
            );

            console.log(resPays);
            const arrPay = [];

            let payee = resPays?.data[0].payments;

            setPaidAmount(payee);
            payee?.map((d) => {
              arrPay.push(d.amount);
            });

            const tt = arrPay?.reduce((a, b) => a + b, 0);

            tt && console.log(arrPay);

            setPaidTotal(tt);

            if (resPays) {
              const instore = await userRequest.get(
                `/instoreorders/find/${orderCode}`
              );

              setMadeOrder(instore.data[0]);
            }
          }
        } else if (user?.isWorker) {
          console.log(orderCode);

          console.log(customerId);
          console.log(transCust._id);

          const resTrans = await userRequest.get(
            `/workerscustomers/transactions/${
              jumpToFinal ? customerId : transCust._id
            }?transactions=${orderCode}`
          );

          const fetchedTrans = resTrans.data.transactions[0];

          console.log(fetchedTrans);

          setTransDate(
            moment.unix(fetchedTrans.date).format("DD/MM/YYYY, HH:mm")
          );
          setTransStatus(fetchedTrans.orderstatus);
          setToBePaid(fetchedTrans.amount);

          if (resTrans) {
            const resPays = await userRequest.get(
              `/workerscustomers/payments/${
                jumpToFinal ? customerId : transCust._id
              }?payments=${orderCode}`
            );

            const arrPay = [];

            const payee = resPays.data.payments;

            setPaidAmount(payee);
            payee.map((d) => {
              arrPay.push(d.amount);
            });

            const tt = arrPay.reduce((a, b) => a + b, 0);

            setPaidTotal(tt);

            if (resPays) {
              const instore = await userRequest.get(
                `/instoreorders/find/${orderCode}`
              );

              setMadeOrder(instore.data[0]);
            }
          }
        }

        const generateQrcode = async () => {
          try {
            console.log(orderCode);
            const response = await QRcode.toDataURL(orderCode);
            setImageUrl(response);
          } catch (err) {
            console.log(err);
          }
        };
        await generateQrcode();
      } catch (err) {
        console.log(err);
      }
    };

    customDetails();
  }, [transCust._id, orderCode]);

  const handleExit = () => {
    // window.location.reload();
    setGetToFinal(false);
  };

  //  madeOrder && console.log(madeOrder);

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <div className="close">
          <IoIosCloseCircleOutline
            size={25}
            fontWeight={100}
            onClick={handleExit}
          />
        </div>
        <div ref={componentRef} className="data">
          <Title>
            <thead>
              <tr>
                <td>
                  <Generate>
                    <div className="img">
                      {imageUrl ? (
                        <a href={imageUrl} download>
                          <img src={imageUrl} alt="img" />
                        </a>
                      ) : null}
                    </div>
                  </Generate>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td className="date">
                  <div>WYL,</div>
                  <div>0766634233,</div>
                  <div>0754266428.</div>
                  <div>NENDA NA HII RISITI DUKANI.</div>
                  <div>{transDate}</div>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="topname">
                  <div className="top">
                    {jumpToFinal ? nameId : transCust.username}
                  </div>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td> </td>
              </tr>
            </tbody>
          </Title>
          <Table>
            <TabHead>
              <tr>
                <td>artNo</td>
                <td>Price</td>
                <td>pcs</td>
                <td>ctns</td>
                <td>total</td>
              </tr>
            </TabHead>
            <Balance>
              {fetchedProducts?.map((d) => {
                return (
                  <tr key={d._id} className="item">
                    <td className="artno">{d.productData.artNo}</td>
                    <td>{d.productData.price}</td>
                    <td>{d.productData.pcs}</td>
                    <td>{d.quantity}</td>
                    <td>
                      {d.productData.price * d.productData.pcs * d.quantity}
                    </td>
                  </tr>
                );
              })}
            </Balance>
          </Table>
          <Mid>
            <tbody>
              <tr>
                <td>total cost</td>
                <td>
                  <div></div>
                </td>
                <td>
                  <div></div>
                </td>
                <td>
                  <div></div>
                </td>
                <td>{handleJumla()}</td>
              </tr>
            </tbody>
          </Mid>
          <Bottom>
            <tbody className="payments">
              <tr>
                <td className="paying">Payments</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <Body>
              {paidAmount?.map((d) => {
                return (
                  <tr key={d._id}>
                    <td className="dd">
                      {moment.unix(d.date).format("DD/MM/YYYY, HH:mm")}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{d.amount}</td>
                  </tr>
                );
              })}
            </Body>
          </Bottom>
          <Remainder>
            <tbody>
              <tr>
                <td>Balance</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{paidTotal && paidTotal - toBePaid}</td>
              </tr>
            </tbody>
          </Remainder>
        </div>
        <Button>
          <div className="button">
            <button onClick={handlePrint}>print order</button>
          </div>
        </Button>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .data {
    position: absolute;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 85%;
    bottom: 10%;
    top: 5%;
    overflow: hidden;
    overflow-y: scroll;
  }

  .close {
    width: 90%;
    height: 5%;
    margin-left: 5%;
    margin-right: 5%;
    display: grid;
    align-items: center;
    justify-items: end;
    margin-top: 0%;
    border-bottom: 0.01rem dashed gray;
  }
`;

const Title = styled(motion.table)`
  max-width: 90%;
  margin-left: 5%;
  margin-right: 5%;

  .topname {
    max-width: 100%;
  }

  .top {
    font-size: 150%;
    font-weight: 1000;
  }

  tr {
    display: grid;
    grid-template-columns: 2fr 0.5fr 0.5fr 1fr 2fr;
    align-items: center;
    justify-items: center;
  }

  .date {
    font-size: 70%;
  }
`;

const Table = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-bottom: 5%;
  border-top: 0.01rem dashed gray;
`;

const TabHead = styled(motion.thead)`
  tr {
    display: grid;
    grid-template-columns: 25% 10% 10% 10% 25%;
    grid-gap: 5%;
    align-items: center;
    justify-items: center;
    font-size: 90%;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  background: white;
  position: absolute;
  color: black;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  bottom: 0;

  .data {
    @media (print) {
      @page {
        size: 72mm 203.1mm;
      }
    }
  }
`;

const Generate = styled(motion.div)`
  margin-top: 5%;

  .img {
    height: 50%;
  }

  .topname {
  }
`;

const Balance = styled(motion.tbody)`
  width: 100%;
  tr {
    display: grid;
    grid-template-columns: 25% 10% 10% 10% 25%;
    grid-gap: 5%;
    align-items: center;
    justify-items: center;
    font-size: 90%;
  }
`;

const Mid = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  font-size: 110%;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Bottom = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-top: 0.01rem dashed gray;
  margin-top: 2%;

  .payments {
    border-top: 0.01rem dashed gray;

    tr {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      justify-items: center;

      .paying {
        border-bottom: 0.01rem solid gray;
      }
    }
  }
`;

const Top = styled(motion.thead)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  .top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Body = styled(motion.tbody)`
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .dd {
    font-size: 0.8rem;
    text-align: start;
  }
`;

const Remainder = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;

  border-top: 0.01rem dashed gray;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Button = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 10%;
  display: grid;
  align-items: center;
  justify-items: center;
  border-top: 0.01rem solid gray;

  .button {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 60%;
    border-radius: 2rem;
    height: 50%;
    background: #d3d3d3;

    button {
      width: 100%;
      border: none;
      background: none;
    }
  }
`;

export default FinalTransPage;

/*
import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useSelector } from "react-redux";
import { userRequest } from "../../requestmethods";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
import QRcode from "qrcode";
import { IoIosCloseCircleOutline } from "react-icons/io";

const FinalTransPage = ({
  transCust,
  orderCode,
  setGetToFinal,
  fetchedProducts,
  handleJumla,
  customerId,
  jumpToFinal,
  nameId,
}) => {
  //start state
  const user = useSelector((state) => state.user.currentUser);
  const componentRef = useRef();
  const [madeOrder, setMadeOrder] = useState(null);
  const [paidAmount, setPaidAmount] = useState(null);
  const [paidTotal, setPaidTotal] = useState(null);
  const [toBePaid, setToBePaid] = useState(null);
  const [transDate, setTransDate] = useState(null);
  const [transStatus, setTransStatus] = useState(null);
  const [imageUrl, setImageUrl] = useState("");

  //end state

  console.log(transCust);

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    const customDetails = async () => {
      try {
        if (user.isAdmin) {
          console.log(customerId);
          console.log(transCust._id);
          console.log(orderCode);

          const resTrans = await userRequest.get(
            `/customers/transactions/${
              jumpToFinal ? customerId : transCust._id
            }?transactions=${orderCode}`
          );

          resTrans && console.log(resTrans.data);

          const fetchedTrans = resTrans.data.transactions[0];

          setTransDate(
            moment.unix(fetchedTrans.date).format("DD/MM/YYYY, HH:mm")
          );
          setTransStatus(fetchedTrans.orderstatus);
          setToBePaid(fetchedTrans.amount);

          if (resTrans) {
            const resPays = await userRequest.get(
              `/customers/payments/${
                jumpToFinal ? customerId : transCust._id
              }?payments=${orderCode}`
            );

            console.log(resPays);
            const arrPay = [];

            let payee = resPays?.data[0].payments;

            setPaidAmount(payee);
            payee?.map((d) => {
              arrPay.push(d.amount);
            });

            const tt = arrPay?.reduce((a, b) => a + b, 0);

            tt && console.log(arrPay);

            setPaidTotal(tt);

            if (resPays) {
              const instore = await userRequest.get(
                `/instoreorders/find/${orderCode}`
              );

              setMadeOrder(instore.data[0]);
            }
          }
        } else if (user.isWorker) {
          const resTrans = await userRequest.get(
            `/workerscustomers/transactions/${
              jumpToFinal ? customerId : transCust._id
            }?transactions=${orderCode}`
          );

          const fetchedTrans = resTrans.data.transactions[0];

          console.log(fetchedTrans);

          setTransDate(
            moment.unix(fetchedTrans.date).format("DD/MM/YYYY, HH:mm")
          );
          setTransStatus(fetchedTrans.orderstatus);
          setToBePaid(fetchedTrans.amount);

          if (resTrans) {
            const resPays = await userRequest.get(
              `/workerscustomers/payments/${
                jumpToFinal ? customerId : transCust._id
              }?payments=${orderCode}`
            );

            const arrPay = [];

            const payee = resPays.data.payments;

            setPaidAmount(payee);
            payee.map((d) => {
              arrPay.push(d.amount);
            });

            const tt = arrPay.reduce((a, b) => a + b, 0);

            setPaidTotal(tt);

            if (resPays) {
              const instore = await userRequest.get(
                `/instoreorders/find/${orderCode}`
              );

              setMadeOrder(instore.data[0]);
            }
          }
        }

        const generateQrcode = async () => {
          try {
            console.log(orderCode);
            const response = await QRcode.toDataURL(orderCode);
            setImageUrl(response);
          } catch (err) {
            console.log(err);
          }
        };
        await generateQrcode();
      } catch (err) {
        console.log(err);
      }
    };

    customDetails();
  }, [transCust._id, orderCode]);

  //  madeOrder && console.log(madeOrder);

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <div className="close">
          <IoIosCloseCircleOutline
            size={25}
            fontWeight={100}
            onClick={() => setGetToFinal(false)}
          />
        </div>
        <div ref={componentRef} className="data">
          <Title>
            <thead>
              <tr>
                <td className="top">
                  {jumpToFinal ? nameId : transCust.username}
                </td>
                <td></td>
                <td>
                  <Generate>
                    <div className="img">
                      {imageUrl ? (
                        <a href={imageUrl} download>
                          <img src={imageUrl} alt="img" />
                        </a>
                      ) : null}
                    </div>
                  </Generate>
                </td>
                <td></td>
                <td className="date">{transDate}</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td> {transStatus}</td>
              </tr>
            </tbody>
          </Title>
          <Table>
            <TabHead>
              <tr>
                <td>Product</td>
                <td>Price</td>
                <td>pcs</td>
                <td>ctns</td>
                <td>total</td>
              </tr>
            </TabHead>
            <Balance>
              {fetchedProducts?.map((d) => {
                return (
                  <tr key={d._id} className="item">
                    <td>{d.productData.artNo}</td>
                    <td>{d.productData.price}</td>
                    <td>{d.productData.pcs}</td>
                    <td>{d.quantity}</td>
                    <td>
                      {d.productData.price * d.productData.pcs * d.quantity}
                    </td>
                  </tr>
                );
              })}
            </Balance>
          </Table>
          <Mid>
            <tbody>
              <tr>
                <td>total cost</td>
                <td>
                  <div></div>
                </td>
                <td>
                  <div></div>
                </td>
                <td>
                  <div></div>
                </td>
                <td>{handleJumla()}</td>
              </tr>
            </tbody>
          </Mid>
          <Bottom>
            <tbody className="payments">
              <tr>
                <td className="paying">Payments</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
            <Body>
              {paidAmount?.map((d) => {
                return (
                  <tr key={d._id}>
                    <td className="dd">
                      {moment.unix(d.date).format("DD/MM/YYYY, HH:mm")}
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{d.amount}</td>
                  </tr>
                );
              })}
            </Body>
          </Bottom>
          <Remainder>
            <tbody>
              <tr>
                <td>Balance</td>
                <td></td>
                <td></td>
                <td></td>
                <td>{paidTotal && paidTotal - toBePaid}</td>
              </tr>
            </tbody>
          </Remainder>
        </div>
        <Button>
          <div className="button">
            <button onClick={handlePrint}>print order</button>
          </div>
        </Button>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.2rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .data {
    position: absolute;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    height: 90%;
    top: 0;
    overflow: hidden;
    overflow-y: scroll;
    margin-top: 1rem;
  }

  .close {
    width: 100%;
    display: grid;
    align-items: center;
    justify-items: end;
    margin-top: 2%;
  }
`;

const Title = styled(motion.table)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;

  .top {
    font-size: 1.5rem;
  }

  tr {
    display: grid;
    grid-template-columns: 2fr 0.5fr 0.5fr 1fr 2fr;
    align-items: center;
    justify-items: center;
  }
`;

const Table = styled(motion.table)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 5%;
  border-top: 0.01rem dashed gray;
`;

const TabHead = styled(motion.thead)`
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  background: white;
  position: absolute;
  color: black;
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  bottom: 0;
`;

const Generate = styled(motion.div)`
  .img {
    height: 50%;
  }
`;

const Balance = styled(motion.tbody)`
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Mid = styled(motion.table)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Bottom = styled(motion.table)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-top: 0.01rem dashed gray;
  margin-top: 2%;

  .payments {
    border-top: 0.01rem dashed gray;

    tr {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      align-items: center;
      justify-items: center;

      .paying {
        border-bottom: 0.01rem solid gray;
      }
    }
  }
`;

const Top = styled(motion.thead)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  .top {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Body = styled(motion.tbody)`
  tr {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    align-items: center;
    justify-items: center;
  }

  .dd {
    font-size: 0.8rem;
    text-align: start;
  }
`;

const Remainder = styled(motion.table)`
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;

  border-top: 0.01rem dashed gray;
  tr {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: center;
    justify-items: center;
  }
`;

const Button = styled(motion.div)`
  position: absolute;
  bottom: 0;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 10%;
  display: grid;
  align-items: center;
  justify-items: center;
  border-top: 0.01rem solid gray;

  .button {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 60%;
    border-radius: 2rem;
    height: 50%;
    background: #d3d3d3;

    button {
      width: 100%;
      border: none;
      background: none;
    }
  }
`;

export default FinalTransPage;


*/
