import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { MdQrCodeScanner } from "react-icons/md";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { AiOutlineStock } from "react-icons/ai";
import { BsCalendar2Check } from "react-icons/bs";
import { IoIosAddCircleOutline } from "react-icons/io";

const BottomScan = ({
  camShow,
  setCamShow,
  handleUpdates,
  setEditOrder,
  product,
  setEditProcess,
  editProcess,
  setInitiateCheck,
  jumpToFinal,
  setGetToFinal,
}) => {
  const sorting = () => {
    if (product && editProcess) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <Container>
      <Data>
        <div
          onClick={() =>
            jumpToFinal ? setGetToFinal(true) : setInitiateCheck(true)
          }
          className={product ? "checks" : "nada"}
          
        >
          <BsCalendar2Check size={25} />
          <div className="letter">
            {jumpToFinal ? "check Order" : "complete Order"}
          </div>
        </div>
        <div
          onClick={() => setEditProcess(true)}
          className={product ? "checks" : "nada"}
        >
          <HiOutlineShoppingBag size={25} />
          <div className="letter">edit Order</div>
        </div>
        <div onClick={handleUpdates} className={product ? "checks" : "nada"}>
          <AiOutlineStock size={25} />
          <div className="letter">update</div>
        </div>
        <div
          onClick={() => setEditOrder(true)}
          className={sorting() ? "checks color" : "nada rangi"}
        >
          <IoIosAddCircleOutline size={25} style={{ color: "green" }} />
          <div className="letter color">Add to Order</div>
        </div>
        <div onClick={() => setCamShow(!camShow)} className="checks">
          <MdQrCodeScanner size={25} />
          <div className="letter">Scan</div>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  .visible {
  }

  .nada {
    opacity: 0.4;
    pointer-events: none;
    display: grid;
    align-items: center;
    justify-items: center;
  }

  .color {
    color: green;
  }
`;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  position: absolute;
  bottom: 0;
  border-top: 0.04rem solid gray;
  height: 10%;
  background: white;
  z-index: 1;

  .letter {
    font-size: 0.5rem;
  }

  .checks {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

export default BottomScan;
