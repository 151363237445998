import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import { FiUserPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import HalfSpinner from "../loaders/halfspinner";

const CustomerCreate = ({ setCreateCust, createCust }) => {
  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const user = useSelector((state) => state.user.currentUser);
  const [successReg, setSuccessReg] = useState(null);
  const [showBanner, setShowBanner] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setCreateCust(false);
    }
  };

  const CreateCust = async () => {
    setIsLoading(true);
    const userInfo = {
      username: name,
      phonenumber: number,
      email: email,
      city: city,
      registerer: user._id,
    };

    try {
      const res = await userRequest.post(
        user.isAdmin ? "/customers/register" : "/workerscustomers/register",
        userInfo
      );

      if (res.data?.message === "User registered") {
        setSuccessReg(res.data.message);

        setTimeout(() => {
          window.location.reload();
        }, [1500]);
      }
    } catch (err) {
      console.log(err);
    }
  };

  /*const handleClicks = (d) => {
    setSelectedUser(d);
    setTransType(true);
    setFinishSelected(false);
    setCustomerDets(d);
  };

  useEffect(() => {
    const fullFunction = async () => {
      if (customerList) {
        setCustomerList(null);
      } else {
        if (User.isAdmin) {
          const res = await userRequest.get("/customers");

          setCustomerList(res.data);
        } else if (User.isWorker) {
          const res = await userRequest.get("/workerscustomers");

          setCustomerList(res.data);
        }
      }
    };

    fullFunction();
  }, [finishSelected]); */

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        {showBanner && (
          <Banner>
            <div>{successReg && successReg}</div>
          </Banner>
        )}
        <Title>
          <div>FUNGUA ACCOUNT YA MTEJA</div>
        </Title>
        <Words>
          <div>
            <input
              type="text"
              placeholder="name"
              className="name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div>
            <input
              type="phonenumber"
              placeholder="number ya simu"
              className="number"
              onChange={(e) => setNumber(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="email"
              className="email"
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div>
            <input
              type="text"
              placeholder="mkoa"
              className="city"
              onChange={(e) => setCity(e.target.value)}
            />
          </div>
        </Words>
        <Submit>
          {isLoading ? (
            <div className="loader">
              <HalfSpinner />
            </div>
          ) : (
            <button onClick={() => CreateCust()}>CREATE</button>
          )}
        </Submit>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  height: 90%;
  margin: 5%;
  bottom: 2%;
  z-index: 10;

  .title {
    margin-top: 1%;
    padding: 1rem;
    font-size: 2rem;
  }
`;

const Banner = styled(motion.div)``;

const Title = styled(motion.div)`
  width: 100%;
  margin-top: 15%;
  text-align: center;
`;

const Words = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  justify-items: center;
  grid-gap: 0.05rem;
  border: 0.01rem solid gray;
  margin-top: 10%;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  border-radius: 1rem;

  input {
    border: none;
    decoration: none;
    height: 3rem;
    padding: 1rem;
    font-size: 1rem;
  }

  .email {
    border-top: 0.01rem solid gray;
  }
  .number {
    border-top: 0.01rem solid gray;
  }
  .city {
    border-top: 0.01rem solid gray;
  }
`;

const Submit = styled(motion.div)`
  margin: 1rem;
  width: 80%;
  height: 10%;
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 10%;

  button {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    border: none;
    cursor: pointer;
  }

  .loader {
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

export default CustomerCreate;
