import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import { useReactToPrint } from "react-to-print";
import { FcPrint } from "react-icons/fc";
import { RiCloseCircleLine } from "react-icons/ri";
import { IoIosCloseCircleOutline } from "react-icons/io";

const PrinterPage = ({
  printer,
  setPrinter,
  handleAmounts,
  fetchedProducts,
  finishSelected,
  handleJumla,
  product,
  handlePieces,
  handlePrinting,
}) => {
  const componentRef = useRef();
  const [customerList, setCustomerList] = useState(null);
  let today = new Date().toLocaleDateString();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setPrinter(false);
    }
  };

  useEffect(() => {
    const fullFunction = async () => {
      if (customerList) {
        setCustomerList(null);
      } else {
        const res = await userRequest.get("/customers");

        setCustomerList(res.data);
      }
    };

    fullFunction();
  }, [finishSelected]);

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <Printerr>
          <div className="toshow">
            <div className="buttonss">
              <div onClick={handlePrint} className="printthis">
                <FcPrint size={25} />
                <div>Print</div>
              </div>
              <div className="xx">
                <RiCloseCircleLine size={25} onClick={handlePrinting} />
              </div>
            </div>
            <div>
              <div ref={componentRef}>
                <table className="heading1">
                  <tr className="hding">
                    <td className="user">{product && product[0].userId}</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="date">{today}</td>
                  </tr>
                </table>
                <table className="body1">
                  <tr className="sub">
                    <td>Art.No</td>
                    <td>Size</td>
                    <td>Price</td>
                    <td>Pcs</td>
                    <td className="quantity1">Quantity</td>
                    <td>Total</td>
                  </tr>

                  {printer
                    ? fetchedProducts.map((p) => (
                        <tr className="added" key={p.id}>
                          <td className="">{p.productData.artNo}</td>

                          <td className="">{p.productData.size}</td>

                          <td className=""> {p.productData.price} </td>

                          <td className=""> {p.productData.pcs} </td>

                          <td className="quantity2">Ctns| {p.quantity}</td>

                          <td className="">
                            {p.productData.price *
                              p.quantity *
                              p.productData.pcs}
                          </td>
                        </tr>
                      ))
                    : null}
                </table>

                <table className="footer1">
                  <tr className="ft">
                    <td className="ftStart">Cartons</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="ftEnd">{handleAmounts()}</td>
                  </tr>
                  <tr className="ft">
                    <td className="ftStart">Pieces</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="ftEnd">{handlePieces()}</td>
                  </tr>
                  <tr className="ft">
                    <td className="ftStart">Total</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="white">.......</td>
                    <td className="ftEnd">{handleJumla()}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </Printerr>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  .white {
    color: white;
  }

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  color: black;
  height: 80%;
  bottom: 10%;
  top: 10%;

  .title {
    margin-top: 1%;
    padding: 1rem;
    font-size: 2rem;
  }
`;

const Printerr = styled(motion.div)`
  .heading1 {
    width: 100%;

    .hding {
      width: 100%;

      .user {
        text-align: end;
      }
      .date {
        text-align: end;
      }
    }
  }

  .body1 {
    width: 100%;
    text-align: center;

    .sub {
      width: 100%;
    }
    .added {
      width: 100%;
    }
  }

  .footer1 {
    width: 100%;
    text-align: center;
    margin-top: 2rem;
    border: 0.02rem solid gray;

    .ft {
      align: center;

      .ftStart {
        text-align: start;
      }
      .ftEnd {
        text-align: start;
      }
    }
  }

  .toshow {
    position: absolute;
    width: 90%;
    height: 90%;
    background: white;
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;

    overflow: scroll;
    scroll-behaviour: smooth;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 4fr 2fr;
    border: 0.01rem solid gray;
    border-radius: 0.5rem;

    .buttonss {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      .printthis {
        width: 100%;
        margin: 0.5rem;
        button {
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }

      .xx {
        display: grid;
        justify-items: end;
        margin: 0.5rem;
        button {
          height: 70%;
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`;

export default PrinterPage;

