import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { userRequest, publicRequest } from "../../requestmethods";
import EditPage from "../../components/scanner/editpage";
import PrinterPage from "../../components/scanner/printerpage";
import { IoIosCloseCircleOutline } from "react-icons/io";
import CustomerOrderCheck from "../../components/scanner/custsomercheck";
import CustTransType from "../../components/scanner/custranstype";
import TransactOrder from "../../components/scanner/transactorder";
import moment from "moment";
import FinalTransPage from "../../components/scanner/finaltrans";

const OrderScanning = ({
  scanResultWebCam,
  setFinishSelected,
  finishSelected,
  printer,
  setPrinter,
  setEditOrder,
  editOrder,
  fetchedProducts,
  setFetchedProducts,
  orderId,
  setOrderId,
  product,
  setProduct,
  setOrderBy,
  editProcess,
  setCustomerId,
  initiateCheck,
  setInitiateCheck,
  transCust,
  setTransCust,
  getToFinal,
  setGetToFinal,
  customerId,
  jumpToFinal,
  fetchedTotal,
  setFetchedTotal,
  handleJumla,
}) => {
  const user = useSelector((state) => state.user.currentUser);
  const [camShow, setCamShow] = useState(false);
  const [searching, setSearching] = useState("");
  const [initiateTrans, setInitiateTrans] = useState(false);
  const [searchResults, setSearchResults] = useState([]);
  const [allowCapture, setAllowCapture] = useState(false);
  const [timeToFetch, setTimeToFetch] = useState(false);
  const dispatch = useDispatch();

  const [totalAmount, setTotalAmount] = useState("");
  const [cartonNo, setCartonNo] = useState("");
  const [qFr, setQFr] = useState("0fr");
  const [clickedBox, setClickedBox] = useState(null);
  const [allowTrans, setAllowTrans] = useState(false);
  const [nameId, setNameId] = useState("");
  const [orderStatus, setOrderStatus] = useState("");
  const [jello, setJello] = useState(false);
  const [amountPaid, setAmountPaid] = useState(null);
  const [orderType, setOrderType] = useState("");
  const [isCust, setIsCust] = useState(false);
  const [orderCode, setOrderCode] = useState("");
  const [changeColor, setChangeColor] = useState(null);

  const makeChanges = (d, key) => {
    //console.log(d);

    if (fetchedProducts !== null) {
      let dater = new Date().toString();
      let datatouid = dater.slice(0, -18).replaceAll(" ", "");
      let uid = new Date().getTime().toString(36) + datatouid;

      const addedCtn = {
        productData: d,
        productId: d._id,
        quantity: 1,
        id: uid,
      };

      let temp = [...fetchedProducts];

      temp.push(addedCtn);

      setFetchedProducts(temp);
      setChangeColor(key);
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setChangeColor(null);
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, [changeColor]);

  const editFetched = (index) => {
    let temp = [...fetchedProducts];

    temp.splice(index, 1);

    setFetchedProducts(temp);
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        if (scanResultWebCam) {
          const rando = Math.random()
            .toString(36)
            .replace(/[^a-z]+/g, "")
            .substr(2, 10);
          const norando = moment(new Date()).unix();
          let orderNo = transCust?.username + rando + norando;

          let forCustomer = scanResultWebCam.slice(-4);

          if (forCustomer === "cust") {
            setIsCust(true);

            const res = await userRequest.get(
              `/orders/find/${scanResultWebCam}`
            );

            // await setNameId(transCust?.username);
            await setOrderBy("cust");
            //  await setCustomerId(transCust?._id);
            await setProduct(res?.data);
            await setOrderCode(orderNo);
            await setOrderId(orderNo);
            await setIsCust(true);
          } else {
            setIsCust(false);
            const res = await userRequest.get(
              `/instoreorders/find/${scanResultWebCam}`
            );

            await setNameId(res.data[0].userId);
            await setOrderBy(res.data[0].user_ID);
            await setFetchedTotal(res.data[0].amount);
            await setCustomerId(res.data[0].customerId);
            await setProduct(res.data);
            await setOrderCode(scanResultWebCam && scanResultWebCam);
            await setOrderId(scanResultWebCam && scanResultWebCam);
          }
        }
      } catch (err) {
        console.log(err);
      }
    };
    getProduct();
  }, [scanResultWebCam]);

  useEffect(() => {
    const handlingResults = async () => {
      const res = await publicRequest.get(`/products?category=${searching}`);
      setSearchResults(res.data);
    };

    if (searching === null) {
      return "";
    } else {
      handlingResults();
    }
  }, [searching]);

  //cart fetching

  useEffect(() => {
    const productFetching = async () => {
      try {
        let fetchingArray = [];
        await product[0].products.map(async (d) => {
          const res = await publicRequest.get(`/products/find/${d.productId}`);

          const productsContain = {};
          productsContain["productData"] = res.data;
          productsContain["productId"] = d.productId;
          productsContain["quantity"] = d.quantity;
          productsContain["id"] = d._id;
          await fetchingArray.push(productsContain);
        });

        await setFetchedProducts(() => fetchingArray);
      } catch (err) {
        console.log(err);
      }
    };
    product && productFetching();
    //setCamShow(!camShow);
  }, [orderId, nameId]);

  //end of product $ cart fetching

  /* const handleErrorWebCam = (err) => {
    console.log(err);
  };

  const handleScanWebCam = (results) => {
    if (results) {
      setScanResultWebCam(results);
    }
  };*/

  const handlePieces = () => {
    let totalPisi = [];
    if (fetchedProducts) {
      fetchedProducts.map((d) => {
        let onlypcs = d.productData.pcs;
        let onlyqty = d.quantity;
        let jumla = onlypcs * onlyqty;
        totalPisi.push(jumla);
      });
    }
    const total = totalPisi.reduce((a, b) => a + b, 0);
    return total;
  };

  const handleAmounts = () => {
    let totalPrice = [];
    let totalCtns = [];

    if (fetchedProducts) {
      fetchedProducts.map((d) => {
        let onlyqty = d.quantity;

        totalCtns.push(onlyqty);
      });
    }

    const jumlaCtn = totalCtns.reduce((a, b) => a + b, 0);

    return jumlaCtn;
  };

  const handlePrinting = () => {
    setPrinter(!printer);
  };

  useEffect(() => {
    const handleAfterFetch = () => {
      if (product && fetchedProducts) {
        if (product[0].products === fetchedProducts.length) {
          setJello(true);
        }
      }
    };

    handleAfterFetch();
  }, [fetchedProducts]);

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {printer && (
            <PrinterPage
              printer={printer}
              setPrinter={setPrinter}
              fetchedProducts={fetchedProducts}
              finishSelected={finishSelected}
              setFinishSelected={setFinishSelected}
              product={product}
              totalAmount={totalAmount}
              handlePieces={handlePieces}
              handleAmounts={handleAmounts}
              handleJumla={handleJumla}
              handlePrinting={handlePrinting}
              cartonNo={cartonNo}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {editOrder && (
            <EditPage
              editOrder={editOrder}
              setEditOrder={setEditOrder}
              searching={searching}
              setSearching={setSearching}
              printer={printer}
              setPrinter={setPrinter}
              fetchedProducts={fetchedProducts}
              finishSelected={finishSelected}
              setFinishSelected={setFinishSelected}
              product={product}
              totalAmount={totalAmount}
              handlePieces={handlePieces}
              handlePrinting={handlePrinting}
              cartonNo={cartonNo}
              searchResults={searchResults}
              setSearchResults={setSearchResults}
              makeChanges={makeChanges}
              changeColor={changeColor}
              setChangeColor={setChangeColor}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {initiateCheck && (
            <CustomerOrderCheck
              setInitiateCheck={setInitiateCheck}
              transCust={transCust}
              setTransCust={setTransCust}
              setInitiateTrans={setInitiateTrans}
              isCust={isCust}
              nameId={nameId}
              customerId={customerId}
              setNameId={setNameId}
              setCustomerId={setCustomerId}
              setOrderCode={setOrderCode}
              setOrderId={setOrderId}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {initiateTrans && (
            <CustTransType
              setInitiateCheck={setInitiateCheck}
              transCust={transCust}
              setTransCust={setTransCust}
              handleJumla={handleJumla}
              setAllowTrans={setAllowTrans}
              setInitiateTrans={setInitiateTrans}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              orderStatus={orderStatus}
              setOrderStatus={setOrderStatus}
              orderType={orderType}
              setOrderType={setOrderType}
              totalAmount={totalAmount}
              fetchedProducts={fetchedProducts}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {allowTrans && (
            <TransactOrder
              setInitiateCheck={setInitiateCheck}
              transCust={transCust}
              setTransCust={setTransCust}
              handleJumla={handleJumla}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              product={product}
              totalAmount={totalAmount}
              fetchedProducts={fetchedProducts}
              allowTrans={allowTrans}
              setAllowTrans={setAllowTrans}
              getToFinal={getToFinal}
              setGetToFinal={setGetToFinal}
              orderType={orderType}
              orderStatus={orderStatus}
              isCust={isCust}
              setOrderCode={setOrderCode}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {getToFinal && (
            <FinalTransPage
              setInitiateCheck={setInitiateCheck}
              transCust={transCust}
              setTransCust={setTransCust}
              handleJumla={handleJumla}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              product={product}
              totalAmount={totalAmount}
              fetchedProducts={fetchedProducts}
              allowTrans={allowTrans}
              setAllowTrans={setAllowTrans}
              orderCode={orderCode}
              setOrderCode={setOrderCode}
              setGetToFinal={setGetToFinal}
              customerId={customerId}
              jumpToFinal={jumpToFinal}
              nameId={nameId}
            />
          )}
        </AnimatePresence>
        <Scan>
          <Processed>
            {fetchedProducts?.map((p, index) => (
              <Data
                key={p.id}
                style={{ ["--length"]: editProcess ? "0.5fr" : "0fr" }}
              >
                <div className="cartimg">
                  <img src={p.productData.img} alt="await" />
                </div>
                <div className="data">
                  <div className="noInMath">
                    <div className="artNoCart dc">{p.productData.artNo}</div>
                    <div className="sizeCart dc">
                      size | {p.productData.size}
                    </div>
                  </div>
                  <div className="inMath">
                    <div className="pcsCart dc">
                      price | {p.productData.price} Tshs
                    </div>
                  </div>
                  <div className="amountbox">
                    <div className="pcsCart dc">pcs | {p.productData.pcs} </div>
                    <div className="countAmount mv">Ctns| {p.quantity}</div>
                  </div>
                  <div className="at">
                    <div className="totalPrice">
                      {p.productData.price * p.quantity * p.productData.pcs}
                      Tshs
                    </div>
                  </div>
                </div>
                {editProcess && (
                  <div className="close" onClick={() => editFetched(index)}>
                    <IoIosCloseCircleOutline size={25} fontWeight={100} />
                  </div>
                )}
              </Data>
            ))}
          </Processed>
          <NoImgData>
            <div className="boxname">
              <div>Jina kwenye katon</div>
              <div className="ans"> {product && product[0].userId}</div>
            </div>
            <div className="ctnNo">
              <div>Idadi ya Carton</div>
              <div className="ans"> {handleAmounts()}</div>
            </div>
            <div className="pcs">
              <div>Idadi ya pisi</div>
              <div className="ans"> {handlePieces()}</div>
            </div>
            <div className="status">
              <div>status</div>
              <div className={isCust ? "waiting" : "done"}>
                {" "}
                {isCust ? "Not Transacted" : product && product[0].status}
              </div>
            </div>
            <div className="status">
              <div>transacted</div>
              <div className="ans">
                {" "}
                {isCust ? user.username : product && `${product[0].transacted}`}
              </div>
            </div>
            <div className="total">
              <div>Jumla</div>
              <div className="ans"> {handleJumla()}</div>
            </div>
          </NoImgData>
        </Scan>
      </AnimateSharedLayout>
    </Container>
  );
};
const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 20%;
  bottom: 10%;
  width: 100%;
`;

const Scan = styled(motion.div)`


.noprinting{
  display:none;
}
.printing{
  position:absolute;
  width:100%;
  height:100%;
  
  color:black;
  top:0;
  display:grid;
  align-content:center;
  
`;

const CameraandData = styled(motion.div)``;

const Data = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  display: grid;
  grid-template-columns: 2fr 2.5fr var(--length);
  align-items: center;
  justify-items: center;
  position: relative;
  border-top: 0.02rem dotted gray;
  border-bottom: 0.02rem dotted gray;

  .data {
    display: grid;
    grid-template-rows: repeat(4, 25%);

    align-items: center;
    justify-items: start;

    .noInMath {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;
      justify-items: start;
      align-items: center;
    }

    .inMath {
      display: grid;
      grid-template-columns: 3fr 1fr;
      grid-gap: 0.5rem;
      justify-items: start;
      align-items: center;
      width: 100%;
    }

    .amountbox {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 0.5rem;
      justify-items: start;
      align-items: center;
      width: 100%;
    }
  }
`;

const NoImgData = styled(motion.div)`
  border-top: 0.02rem solid gray;
  height: 20%;
  background: white;
  display: grid;
  position: absolute;
  grid-template-rows: repeat(6, 16.66666666667%);
  align-items: center;
  justify-items: start;
  bottom: 0%;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;

  .ctnNo {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .total {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .boxname {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .status {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .pcs {
    display: grid;
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }

  .ans {
    text-align: end;
  }

  .waiting {
    text-align: end;

    color: red;
  }

  .done {
    text-align: end;

    color: green;
  }
`;

const Searching = styled(motion.div)`
  position: relative;

  .editing {
  }
`;

const Processed = styled(motion.div)`
  position: absolute;
  height: 80%;
  overflow-y: scroll;
  scroll-behaviour: smooth;
  width: 100%;

  .cartimg {
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
`;

const Printerr = styled(motion.div)`
  .transparent {
    position: absolute;
    width: 100%;
    height: 100%;

    background-color: #fff;

    opacity: 0.9;
  }

  .heading1 {
    width: 100%;

    .hding {
      width: 100%;

      .user {
        text-align: end;
      }
      .date {
        text-align: end;
      }
    }
  }

  .body1 {
    width: 100%;
    text-align: center;

    .sub {
      width: 100%;
    }
    .added {
      width: 100%;
    }
  }

  .footer1 {
    width: 100%;
    align: center;
    margin-top: 2rem;
    border: 0.02rem solid gray;

    .ft {
      align: center;

      .ftStart {
        text-align: center;
      }
      .ftEnd {
        text-align: center;
      }
    }
  }

  .toshow {
    position: absolute;
    width: 70%;
    height: 70%;
    background: white;

    margin: 10% 15% 10% 15%;
    overflow: scroll;
    scroll-behaviour: smooth;
    display: grid;
    grid-template-rows: 1fr 1fr 1fr 4fr 2fr;
    border: 0.05rem solid gray;
    border-radius: 0.5rem;

    .buttonss {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 100%;

      .printthis {
        width: 100%;
        margin: 0.5rem;
        button {
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }

      .xx {
        display: grid;
        justify-items: end;
        margin: 0.5rem;
        button {
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`;

export default OrderScanning;

/*

<CamScanner>
              <button onClick={handleCamShow} className="scangate">
                {camShow ? "End Scanning" : "Start Scanning"}
              </button>
              <button onClick={handleUpdates} className="scangate">
                update order
              </button>
              <button onClick={handlePaid} className="scangate">
                paid order
              </button>
              <button className="titleText" onClick={handlePrinting}>
                complete order
              </button>

              <button className="titleText" onClick={handleEditing}>
                edit order
              </button>
            </CamScanner>

*/

// console.log(orderType);
// const [showArrow, setShowArrow] = useState(false);

/*const handlePaid = async () => {
    const productsInOrder = [];

    fetchedProducts[0] &&
      fetchedProducts.map(async (d) => {
        productsInOrder.push(d.productId);
      });

    let filteredProducts = [...new Set(productsInOrder)];

    await filteredProducts.map(async (d) => {
      let filteredData = [];

      for (let i = 0; i < fetchedProducts.length; i++) {
        if (d === fetchedProducts[i].productId) {
          filteredData.push(fetchedProducts[i].quantity);
        }
      }

      let sumQuantity = filteredData.reduce((a, b) => a + b, 0);

      const res = await publicRequest.get(`/products/find/${d}`);

      let updatedStock = (await res.data.nostock) - sumQuantity;

      let newdata = await {
        nostock: await updatedStock,
      };

      await userRequest.put(`/products/${d}`, newdata);
    });

    let statusDetail = {
      status: "paid",
    };
    await userRequest.put(`/instoreorders/${orderId}`, statusDetail);
  };

  const handleCamShow = () => {
    setCamShow(!camShow);
  };

  const handleUpdates = async () => {
    const update = {
      products: fetchedProducts,
    };

    await userRequest.put(`/instoreorders/${orderId}`, update);
  };

  const handleCapture = () => {
    setAllowCapture(true);
  };

  const handleEditing = () => {
    setEditOrder(true);
  };
  //product fetching

*/
