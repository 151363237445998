import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { MdQrCodeScanner } from "react-icons/md";
import { HiOutlineHome } from "react-icons/hi";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { GiTakeMyMoney } from "react-icons/gi";
import { Link } from "react-router-dom";
import { VscMenu } from "react-icons/vsc";
import { IoIosSearch } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";

const BottomCheckout = ({
  camShow,
  setCamShow,
  setFinishSelected,
  setSearchBar,
  setSidebar,
  sidebar,
}) => {
  const cart = useSelector((state) => state.cart);

  const cartier = cart?.quantity[0];

  return (
    <Container>
      <Data cartier={cartier}>
        <div>
          <div>
            <Link to="/" style={{ color: "black" }}>
              <HiOutlineHome size={25} />
            </Link>
          </div>
        </div>
        <div>
          <div onClick={() => setCamShow(!camShow)}>
            <MdQrCodeScanner size={25} />
          </div>
        </div>
        <div>
          <Link to="/products" style={{ color: "black" }}>
            <HiOutlineShoppingBag size={25} />
          </Link>
        </div>
        <div>
          <div>
            <IoIosSearch size={25} onClick={() => setSearchBar(true)} />
          </div>
        </div>
        <div>
          <div style={{ cursor: "pointer" }}>
            <GiTakeMyMoney
              size={25}
              onClick={() => setFinishSelected(true)}
              className="cashmoney"
            />
          </div>
        </div>
      </Data>
    </Container>
  );
};
const Container = styled(motion.div)`
  z-index: -1;
`;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  width: 100%;
  align-items: center;
  justify-items: center;
  position: absolute;
  bottom: 0;
  border-top: 0.04rem solid gray;
  height: 10%;
  background: white;
  z-index: -1;

  .cashmoney {
    pointer-events: ${({ cartier }) => (cartier > 0 ? "auto" : "none")};
    color: ${({ cartier }) => (cartier > 0 ? "black" : "dimgray")};
  }
`;

export default BottomCheckout;
