import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import moment from "moment";
import { useSelector } from "react-redux";


const OrderSelect = ({
  passedOrder,
  setOrderSelected,
  setPassedOrder,
  actBalance,
  setActBalance,
  cust,
}) => {
  const todaysDate = new Date();
  const timing = moment(todaysDate).unix();
  const [negFigure, setNegFigure] = useState(false);
  const [amountPaid, setAmountPaid] = useState(null);
  const [emptyErr, setEmptyErr] = useState(false);
  const User = useSelector((state) => state.user.currentUser);

  console.log(cust);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setOrderSelected(false);
      setPassedOrder(null);
      setActBalance(null);
    }
  };

  useEffect(() => {
    if (actBalance < 0) {
      setNegFigure(true);
    }
  }, [actBalance]);

  const makePayment = async () => {
    const payments = {
      userId: User._id,
      customerId: cust._id,
      customerName: cust.username,
      orderId: passedOrder[0].transactionId,
      amount: amountPaid,
      info: "",
    };

    const customerPayments = {
      transactionapproval: User._id,
      transactionId: passedOrder[0].transactionId,
      info: ["payment"],
      amount: amountPaid,
      date: timing,
    };

    if (amountPaid) {
      try {
        const res = await userRequest.post("/payments/", payments);

        if (res.statusText === "OK") {
          const resPays = await userRequest.put(
            `/customers/payments/${cust._id}`,
            customerPayments
          );

          //  console.log(typeof amountPaid);

          if (resPays.statusText === "OK") {
            const noBalance = parseFloat(actBalance);
            const noAmountPaid = parseFloat(amountPaid);
            const remainingBal = noAmountPaid + noBalance;

            //

            if (remainingBal === 0) {
              const statusChange = {
                orderstatus: "paid",
              };

              console.log(statusChange);

              const resReminder = await userRequest.put(
                `/transactions/${passedOrder[0].transactionId}`,
                statusChange
              );

              if (resReminder?.statusText === "OK") {
                const statusChange = {
                  orderstatus: "paid",
                };

                console.log(statusChange);

                const resCustReminder = await userRequest.put(
                  `/customers/transactions/find/${cust._id}?transactionId=${passedOrder[0].transactionId}`,
                  statusChange
                );

                if (resCustReminder.statusText === "OK") {
                  setOrderSelected(false);
                  window.location.reload();
                }
              }
            } else {
              setOrderSelected(false);
              window.location.reload();
            }
          }
        }
      } catch (err) {
        console.log(err);
      }
    } else {
      setEmptyErr(true);
    }
  };

  return (
    <CardShadow className="shadow" onClick={exitDetailHander}>
      <Items>
        <Table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Payments</td>
              <td className="trans">Transactions</td>
            </tr>
          </thead>
          <tbody>
            {passedOrder?.map((d) => {
              return (
                <tr key={d._id}>
                  <td>{moment.unix(d.date).format("DD/MMM/YYYY")}</td>
                  <td className={d.info ? "pay" : ""}>
                    {d.info ? d.amount : ""}
                  </td>
                  <td className={d.orderstatus ? "trans" : ""}>
                    {d.orderstatus ? d.amount : ""}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Balance>
          <div className="title">Balance</div>
          <div className="bal">{actBalance && actBalance}</div>
        </Balance>
        {negFigure && (
          <Paying>
            <div className="number">
              <input
                type="number"
                onChange={(e) => setAmountPaid(e.target.value)}
              />
            </div>
            <div className="button">
              <button onClick={() => makePayment()}> Make Payment</button>
            </div>
          </Paying>
        )}
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 80%;
  border-radius: 1rem;
  padding: 1rem;
  background: white;
  position: absolute;
  left: 10%;
  color: black;

  height: 70%;
  top: 10%;
`;

const Table = styled(motion.table)`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;

  thead {
    .trans {
      text-align: center;
    }
  }

  tbody {
    .pay {
      background-color: #d3d3d3;
    }
    .trans {
      text-align: center;
    }
  }
`;

const Balance = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .bal {
    text-align: end;
  }
`;

const Paying = styled(motion.div)`
  position: absolute;
  bottom: 2%;
  height: 25%;

  display: grid;
  align-items: center;
  justify-items: center;
  width: 100%;
  left: 0;
  grid-template-row: 1fr 1fr;

  .number {
    display: grid;
    align-items: end;
    justify-items: center;
    width: 100%;
    height: 100%;

    input {
      width: 40%;
      height: 40%;
      border: 0.01rem solid gray;
      padding: 2.5%;
      border-radius: 2rem;
    }
  }

  .button {
    display: grid;
    align-items: center;
    justify-items: center;
    width: 100%;
    height: 100%;

    button {
      width: 80%;
      height: 60%;
      border: 0.01rem solid gray;
      background: rgb(243, 243, 243);
      border-radius: 2rem;
    }
  }
`;

export default OrderSelect;
