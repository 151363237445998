import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { userRequest } from "../../requestmethods";
import OrderSelect from "../../components/orderSelect/orderSelect";
import { useLocation } from "react-router-dom";
import moment from "moment";
import DebtCustomer from "../../components/orderSelect/debtCustomer";

const WorkerCustomer = ({
  todaysDate,
  todaysUnix,
  showDebts,
  setShowDebts,
}) => {
  const location = useLocation();
  const iD = location.pathname.split("/")[2];
  const [orderSelected, setOrderSelected] = useState(false);
  const [passedOrder, setPassedOrder] = useState(null);
  const [actBalance, setActBalance] = useState(null);
  const [negAmount, setNegAmount] = useState(null);
  const [findNeg, setFindNeg] = useState([]);
  const [cust, setCust] = useState(null);
  const [totalTransactions, setTotalTransaction] = useState(null);
  const [totalPayments, setTotalPayments] = useState(null);
  const [balance, setBalance] = useState(null);
  const [todaysBal, setTodaysBal] = useState(null);
  const [allTransactions, setAllTransaction] = useState(null);
  const todayDate = new Date();
  const [selectedId, setSelectedId] = useState(null);
  const [custDateUse, setCustDateUse] = useState(null);
  const [negativeBal, setNegativeBal] = useState(null);
  const todaysM = moment(todaysUnix).format("MM");
  const todaysY = moment(todaysUnix).format("YYYY");
  const todaysD = moment(todaysUnix).format("DD");

  const daySorting = () => {
    const dater = moment(todaysUnix).format("DD");
    const dateMonth = moment(todaysUnix).format("MM");
    const dateYear = moment(todaysUnix).format("YYYY");
    const dateFrom = moment(todaysUnix)
      .subtract(1, "months")
      .endOf("month")
      .format("DD/MM/YYYY");
    const noDate = parseInt(dater);

    if (noDate === 1) {
      return dateFrom;
    } else {
      const stringer = noDate - 1;
      const tDate = stringer.toString();

      const actualD = `${tDate}/${dateMonth}/${dateYear}`;

      return actualD;
    }
  };

  useEffect(() => {
    setCustDateUse(() => todaysUnix);
  }, []);

  const handleClickedId = async (e) => {
    setSelectedId(e);
    setOrderSelected(true);

    const withId = [];
    const totalTT = [];
    const totalPT = [];

    await totalTransactions.map((d) => {
      if (d.transactionId === e) {
        withId.push(d);
        totalTT.push(d.amount);
      }
    });

    await totalPayments.map((d) => {
      if (d.transactionId === e) {
        withId.push(d);
        totalPT.push(d.amount);
      }
    });

    const sorting = async () => {
      const sort = withId?.sort((a, b) => a.date - b.date);

      setPassedOrder(sort);
    };

    const balancesSorting = async () => {
      const amountsTotalT = await totalTT.reduce((a, b) => a + b, 0);
      const amountsTotalP = await totalPT.reduce((a, b) => a + b, 0);

      const actualBalance = amountsTotalP - amountsTotalT;

      setActBalance(actualBalance);
    };

    await sorting();
    await balancesSorting();
    if (showDebts) {
      setShowDebts(false);
    }
  };

  useEffect(() => {
    const fullTrans = async () => {
      const fetching = async () => {
        const res = await userRequest.get(`/workerscustomers/find/${iD}`);
        setTotalTransaction(res.data.transactions);
        setTotalPayments(res.data.payments);
        setCust(res.data);
      };

      await fetching();
    };

    fullTrans();
  }, [todaysUnix]);

  useEffect(() => {
    const calculations = async () => {
      const transIds = [];
      const transactionAmount = [];
      const paymentsAmount = [];
      const includedBal = [];
      const dater = moment(todaysUnix).format("DD");
      const endOfMonth = moment(todaysDate, "YYYY-MM-DD")
        .endOf("month")
        .format("D");
      const dateFrom = moment(todaysUnix)
        .subtract(1, "months")
        .endOf("month")
        .format("MM/DD/YYYY");
      const noDate = parseInt(dater);

      // console.log(dater);

      const dateSoter = () => {
        const dateMonth = moment(todaysUnix).format("MM");
        const dateYear = moment(todaysUnix).format("YYYY");
        if (noDate === 1) {
          return dateFrom;
        } else {
          const stringer = noDate - 1;
          const tDate = stringer.toString();

          const actualD = `${dateMonth}/${tDate}/${dateYear}`;

          return actualD;
        }
      };

      await totalTransactions?.map((d) => {
        const utMonth = moment.unix(d.date).format("MM");
        const utDay = moment.unix(d.date).format("15");
        const utYear = moment.unix(d.date).format("YYYY");

        transIds.push(d.transactionId);

        let ts = moment(`${dateSoter()} 23:59:59`, "M/D/YYYY H:mm:ss").unix();

        if (d.date <= ts) {
          transactionAmount.push(d.amount);
          includedBal.push(d);
        }
      });
      await totalPayments?.map((d) => {
        let ts = moment(`${dateSoter()} 23:59:59`, "M/D/YYYY H:mm:ss").unix();

        if (d.date <= ts) {
          paymentsAmount.push(d.amount);
          includedBal.push(d);
        }
      });

      const remainingDebts = async () => {
        const allNegativeTrans = [];
        await transIds.map(async (d) => {
          const specificTrans = [];
          const specificPay = [];
          const specificTransData = [];
          const specificPaysData = [];

          for (let i = 0; i <= totalTransactions.length; i++) {
            if (d === totalTransactions[i]?.transactionId) {
              specificTrans.push(totalTransactions[i]?.amount);
              specificTransData.push(totalTransactions[i]?.date);
            }
          }

          for (let i = 0; i <= totalPayments.length; i++) {
            if (d === totalPayments[i]?.transactionId) {
              specificPay.push(totalPayments[i]?.amount);
            }
          }

          const totalForTrans = await specificTrans.reduce((a, b) => a + b, 0);

          const totalForPays = await specificPay.reduce((a, b) => a + b, 0);

          const difference = totalForPays - totalForTrans;

          if (difference < 0) {
            const collecting = {};

            collecting["date"] = specificTransData[0];
            collecting["transactionId"] = d;
            collecting["balance"] = difference;

            allNegativeTrans.push(collecting);
          }
        });

        setNegativeBal(allNegativeTrans);
      };

      const amountTransTotal = await transactionAmount.reduce(
        (a, b) => a + b,
        0
      );
      const amountPaymentsTotal = await paymentsAmount.reduce(
        (a, b) => a + b,
        0
      );

      const leftBalance = amountPaymentsTotal - amountTransTotal;

      //console.log(leftBalance);
      setBalance(leftBalance);
      setFindNeg(includedBal);
      await remainingDebts();
    };

    calculations();
  }, [totalPayments, totalTransactions, todaysUnix]);

  useEffect(() => {
    const sorter = async () => {
      let setDate = [];
      const transactionAmount = [];
      const paymentsAmount = [];
      const dateM = moment(todaysUnix).format("MM");
      const dateY = moment(todaysUnix).format("YYYY");
      const dateD = moment(todaysUnix).format("DD");

      await totalTransactions?.map((d) => {
        const utMonth = moment.unix(d.date).format("MM");
        const utDay = moment.unix(d.date).format("DD");
        const utYear = moment.unix(d.date).format("YYYY");

        if (utMonth === dateM && utDay === dateD && utYear === dateY) {
          setDate.push(d);
          transactionAmount.push(d.amount);
        }
      });
      await totalPayments?.map((d) => {
        const utMonth = moment.unix(d.date).format("MM");
        const utDay = moment.unix(d.date).format("DD");
        const utYear = moment.unix(d.date).format("YYYY");

        if (utMonth === dateM && utDay === dateD && utYear === dateY) {
          setDate.push(d);
          paymentsAmount.push(d.amount);
        }
      });

      const sortedDate = setDate.sort((a, b) => a.date - b.date);
      const amountTransTotal = await transactionAmount.reduce(
        (a, b) => a + b,
        0
      );
      const amountPaymentsTotal = await paymentsAmount.reduce(
        (a, b) => a + b,
        0
      );

      const leftBalance = amountPaymentsTotal - amountTransTotal;

      setTodaysBal(leftBalance);
      setAllTransaction(sortedDate);
    };

    sorter();
  }, [totalTransactions, totalPayments, todaysUnix]);

  useEffect(() => {
    const fullCalc = async () => {
      const allTrans = [];
      const negativeBalFinder = [];

      const calculations = async () => {
        await totalTransactions?.map((d) => {
          const dateM = moment(todaysUnix).format("MM");
          const dateY = moment(todaysUnix).format("YYYY");
          const dateD = moment(todaysUnix).format("DD");

          let ts = moment(
            `${dateM}/${dateD}/${dateY} 23:59:59`,
            "MM/DD/YYYY H:mm:ss"
          ).unix();

          if (d.date <= ts) {
            negativeBalFinder.push(d);
          }
        });
        await totalPayments?.map((d) => {
          const dateM = moment(todaysUnix).format("MM");
          const dateY = moment(todaysUnix).format("YYYY");
          const dateD = moment(todaysUnix).format("DD");

          let ts = moment(
            `${dateM}/${dateD}/${dateY} 23:59:59`,
            "MM/DD/YYYY H:mm:ss"
          ).unix();

          if (d.date <= ts) {
            negativeBalFinder.push(d);
          }
        });

        negativeBalFinder?.map((d) => {
          allTrans.push(d.transactionId);
        });
      };

      const sortCalc = async () => {
        const unique = [...new Set(allTrans)];
        const negBal = [];

        for (let i = 0; i < unique.length; i++) {
          const trans = [];
          const pays = [];
          await negativeBalFinder.map((d) => {
            if (unique[i] === d.transactionId) {
              if (d.info) {
                pays.push(d.amount);
              }
              if (d.orderstatus) {
                trans.push(d.amount);
              }
            }
          });

          const amountTransTotal = await trans.reduce((a, b) => a + b, 0);
          const amountPaymentsTotal = await pays.reduce((a, b) => a + b, 0);

          const leftBalance = amountPaymentsTotal - amountTransTotal;

          if (leftBalance < 0) {
            negBal.push(unique[i]);
          }
        }

        setNegAmount(() => negBal);
      };
      await calculations();
      await sortCalc();
    };

    fullCalc();
  }, [allTransactions]);

  const finalTotal = () => {
    if (todaysBal >= 0 || todaysBal) {
      const result = balance + todaysBal;
      return result;
    }
  };

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {orderSelected && (
            <OrderSelect
              passedOrder={passedOrder}
              setOrderSelected={setOrderSelected}
              setPassedOrder={setPassedOrder}
              actBalance={actBalance}
              setActBalance={setActBalance}
              cust={cust}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showDebts && (
            <DebtCustomer
              passedOrder={passedOrder}
              setOrderSelected={setOrderSelected}
              setPassedOrder={setPassedOrder}
              actBalance={actBalance}
              setActBalance={setActBalance}
              setShowDebts={setShowDebts}
              negativeBal={negativeBal}
              handleClickedId={handleClickedId}
            />
          )}
        </AnimatePresence>
        <Data>
          <Top>
            <div className="name">{cust && cust.username}</div>
            <div className="prevBal">
              <div>{`Balance to ${daySorting()}`}</div>
              <div className="bal">{balance && balance}</div>
            </div>
          </Top>

          <Table>
            <Header>
              <tr>
                <td>ID</td>
                <td>Tarehe</td>
                <td>Kalipa</td>
                <td>Mzigo</td>
              </tr>
            </Header>
            <Body>
              {allTransactions?.map((d) => {
                const theDates = moment.unix(d.date).format("DD/MMM/YYYY");
                let trial = "";
                negAmount?.map((r) => {
                  if (r === d.transactionId) {
                    trial = true;
                  }
                });

                return (
                  <Transactions key={d._id} className="mid">
                    <td
                      className={trial ? "neg" : "pos"}
                      key={d.transactionId}
                      onClick={() => handleClickedId(d.transactionId)}
                      value={d.transactionId}
                    >
                      {d.transactionId.slice(0, -12)}
                    </td>
                    <td className="thedates">{theDates}</td>

                    <td className={d.info ? "Pa" : ""}>
                      {d.info ? d.amount : ""}
                    </td>

                    <td>{d.orderstatus ? d.amount : ""} </td>
                  </Transactions>
                );
              })}
            </Body>
          </Table>
          <TodaysBalance>
            <div>{`Balance ya ${todaysD}/${todaysM}/${todaysY}`}</div>
            <div className="abal">{todaysBal && todaysBal}</div>
          </TodaysBalance>
          <TotalBalance>
            <div className="header">Jumla anayodaiwa</div>
            <div className="jumla">{finalTotal()}</div>
          </TotalBalance>
        </Data>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  top: 20%;
  bottom: 10%;
  overflow: hidden;
  overflow-y: scroll;
  width: 100%;
`;

const Data = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

const Top = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-items: center;
  padding: 1rem 1rem 0 1rem;
  border-bottom: 0.02rem solid gray;

  .name {
    font-size: 1.5rem;
  }

  .prevBal {
    display: grid;
    grid-template-columns: 1fr 1fr;

    .bal {
      text-align: end;
    }
  }
`;

const Table = styled(motion.table)``;

const Header = styled(motion.thead)`
  text-align: center;
  td {
    padding: 1rem;
  }
`;

const Body = styled(motion.tbody)`
  text-align: center;
  td {
    padding: 1rem;
  }
`;

const Transactions = styled(motion.tr)`
  .neg {
    color: red;
  }
`;
const Amounts = styled(motion.div)``;

const TodaysBalance = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1rem;

  .abal {
    text-align: end;
  }
`;

const TotalBalance = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 1rem 1rem 1rem;
  border-top: 0.01rem solid gray;

  .jumla {
    text-align: end;
  }
`;

export default WorkerCustomer;
