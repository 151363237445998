import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useEffect } from "react";
import { publicRequest, userRequest } from "../../requestmethods";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import DeleteProduct from "../../components/products/deleteproduct";
import QRcode from "qrcode";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import app from "../../firebase";
import HalfSpinner from "../../components/loaders/halfspinner";

const SingleProductEdit = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [fetchedProduct, setFetchedProduct] = useState(null);
  const [trackProduct, setTrackProduct] = useState(null);
  const [salesTrack, setSalesTrack] = useState(null);
  const [remainingStock, setRemainingStock] = useState(null);
  const [listingAmount, setListingAmount] = useState(null);
  const [input, setInput] = useState({});
  const [arrInput, setArrInput] = useState({});
  const [similarSize, setSimilarSizes] = useState([]);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [color, setColor] = useState([]);
  const [cat, setCat] = useState([]);
  const [file, setFile] = useState(null);
  const [stock, setStock] = useState(null);
  const [stockCheck, setStockCheck] = useState(null);
  const [deleteCheck, setDeleteCheck] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetching = async () => {
      const resProduct = await publicRequest.get(`/products/find/${id}`);
      const resTrack = await userRequest.get(`/producttrack/${id}`);

      

      const generateQrcode = async () => {
        try {
          const response = await QRcode.toDataURL(id);
          setImageUrl(response);
        } catch (err) {
          console.log(err);
        }
      };

      await generateQrcode();
      setFetchedProduct(resProduct?.data);
      setTrackProduct(resTrack?.data[0]);
      setColor(resProduct?.data.color);
      setSimilarSizes(resProduct?.data.similarsize);
      setSimilarProducts(resProduct?.data.similarproducts);
      setCat(resProduct?.data.categories);
      setStock(resProduct?.data.inStock);
      setStockCheck(resProduct?.data.inStock);
    };

    fetching();
  }, []);

  const handleChange = (e) => {
    setInput((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleArrayChange = (e) => {
    if (e.target.name === "color") {
      setColor([...e.target.value.split(",")]);
      setArrInput((prev) => {
        return { ...prev, [e.target.name]: e.target.value.split(",") };
      });
    }
    if (e.target.name === "similarsize") {
      setSimilarSizes(() => [...e.target.value.split(",")]);

      setArrInput((prev) => {
        return { ...prev, [e.target.name]: e.target.value.split(",") };
      });
    }
    if (e.target.name === "similarproducts") {
      setSimilarProducts(() => [...e.target.value.split(",")]);
      setArrInput((prev) => {
        return { ...prev, [e.target.name]: e.target.value.split(",") };
      });
    }
    if (e.target.name === "categories") {
      setCat(() => [...e.target.value.split(",")]);
      setArrInput((prev) => {
        return {
          ...prev,
          [e.target.name]: e.target.value.split(","),
        };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);

    const totalInputs = { ...input, ...arrInput, inStock: stock };

    if (file) {
      const fileName = new Date().getTime() + file.name;

      const storage = getStorage(app);

      const storageRef = ref(storage, fileName);

      const uploadTask = uploadBytesResumable(storageRef, file);

      // Register three observers:
      // 1. 'state_changed' observer, called any time the state changes
      // 2. Error observer, called on failure
      // 3. Completion observer, called on successful completion
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          // Observe state change events such as progress, pause, and resume
          // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log("Upload is " + progress + "% done");
          switch (snapshot.state) {
            case "paused":
              console.log("Upload is paused");
              break;
            case "running":
              console.log("Upload is running");
              break;
            default:
          }
        },
        (error) => {
          // Handle unsuccessful uploads
        },
        async () => {
          await getDownloadURL(uploadTask.snapshot.ref).then(
            async (downloadURL) => {
              if (stockCheck === stock) {
                const product = {
                  ...totalInputs,
                  img: downloadURL,
                };

                const res = await userRequest.put(`/products/${id}`, product);

                if (res.statusText === "OK") {
                  window.location.reload();
                }
              } else {
                const product = {
                  ...totalInputs,
                  img: downloadURL,
                  inStock: stock,
                };

                const res = await userRequest.put(`/products/${id}`, product);
                if (res.statusText === "OK") {
                  window.location.reload();
                }
              }
            }
          );
        }
      );
    } else {
      const onlyInputs = async () => {
        if (stockCheck === stock) {
          const res = await userRequest.put(`/products/${id}`, totalInputs);

          if (res.statusText === "OK") {
            window.location.reload();
          }
        } else {
          const product = {
            ...totalInputs,
            inStock: stock,
          };

          const res = await userRequest.put(`/products/${id}`, product);
          if (res.statusText === "OK") {
            window.location.reload();
          }
        }
      };

      onlyInputs();
    }
  };

  useEffect(() => {
    const calculator = () => {
      const totalAdded = [];
      const totalSales = [];

      trackProduct?.added.map((d) => {
        totalAdded.push(d.amount);
      });

      trackProduct?.sales.map((d) => {
        totalSales.push(d.amount);
      });

      const totalStock = totalAdded.reduce((a, b) => a + b, 0);
      const totalStockSales = totalSales.reduce((a, b) => a + b, 0);

      setRemainingStock(totalStock - totalStockSales);

      setListingAmount(totalStock);
      setSalesTrack(totalStockSales);
    };

    calculator();
  }, [trackProduct]);

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {deleteCheck && (
            <DeleteProduct
              deleteCheck={deleteCheck}
              setDeleteCheck={setDeleteCheck}
              id={id}
            />
          )}
        </AnimatePresence>
        <Product>
          <Img>
            <img src={fetchedProduct?.img} alt="" />
            <div>
              <div>
                {imageUrl ? (
                  <a href={imageUrl} download>
                    <img src={imageUrl} alt="img" />
                  </a>
                ) : null}
              </div>
            </div>
          </Img>
          <Generate>
            <div>Image</div>
            <div>
              <input type="file" onChange={(e) => setFile(e.target.files[0])} />
            </div>
          </Generate>

          <Contain>
            <div className="sec">
              <div className="secname">Categories</div>
              <input
                type="text"
                name="categories"
                placeholder={fetchedProduct?.categories}
                onChange={(e) => handleArrayChange(e)}
                value={cat}
              />
            </div>
            <div className="sec">
              <div className="secname">Color</div>
              <input
                type="text"
                name="color"
                placeholder={fetchedProduct?.color}
                onChange={(e) => handleArrayChange(e)}
                value={color}
              />
            </div>
            <div className="sec">
              <div className="secname">Similar Size</div>
              <input
                type="text"
                name="similarsize"
                placeholder={fetchedProduct?.similarsize}
                onChange={(e) => handleArrayChange(e)}
                value={similarSize}
              />
            </div>
            <div className="sec">
              <div className="secname">Similar Products</div>
              <input
                type="text"
                name="similarproducts"
                placeholder={fetchedProduct?.similarproducts}
                onChange={(e) => handleArrayChange(e)}
                value={similarProducts}
              />
            </div>
            <div className="sec">
              <div className="secname">Description</div>
              <input
                type="text"
                name="desc"
                placeholder={fetchedProduct?.desc}
                onChange={handleChange}
              />
            </div>
            <div className="sec">
              <div className="secname">Pieces</div>
              <input
                type="Number"
                name="pcs"
                placeholder={fetchedProduct?.pcs}
                onChange={handleChange}
              />
            </div>
            <div className="sec">
              <div className="secname">Price</div>
              <input
                type="Number"
                name="price"
                placeholder={fetchedProduct?.price}
                onChange={handleChange}
              />
            </div>
            <div className="sec">
              <div className="secname">ArtNo</div>
              <input
                type="Text"
                name="artNo"
                placeholder={fetchedProduct?.artNo}
                onChange={handleChange}
              />
            </div>
            <div className="secstocks">
              <div className="stocks">
                <div className="secname">Instock</div>
                <div
                  className={stock ? "yes" : "in"}
                  name="yes"
                  onClick={() => setStock(true)}
                >
                  Yes
                </div>

                <div
                  className={stock ? "in" : "no"}
                  name="no"
                  onClick={() => setStock(false)}
                >
                  No
                </div>
              </div>
              <div className="delete">
                <div className="deletebutton">
                  <button onClick={(e) => setDeleteCheck(true)}>
                    Delete product
                  </button>
                </div>
              </div>
            </div>

            {isLoading ? (
              <div className="loader">
                <HalfSpinner />
              </div>
            ) : (
              <div onClick={handleSubmit} className="button">
                <button>Submit</button>
              </div>
            )}
          </Contain>
        </Product>
        <Info>
          <div className="stock">
            <div>Remaining Stock</div>
            <div> {remainingStock && remainingStock}</div>
          </div>
          <div className="salesstock">
            <div>Total sold </div>
            <div>{salesTrack && salesTrack}</div>
          </div>
        </Info>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`
  position: absolute;
  height: 70%;
  width: 100%;
  top: 20%;
  bottom: 10%;
  overflow: hidden;
`;

const Product = styled(motion.div)`
  position: absolute;
  height: 90%;
  width: 100%;
  overflow: hidden;
  overflow-y: scroll;
  top: 0;

  .yes {
    background: gray;
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }
  .no {
    background: gray;
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }
`;

const Contain = styled(motion.div)`
  padding-top: 1rem;
  display: grid;
  grid-template-rows: repeat(auto-fit, 1fr);
  grid-gap: 1rem;
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-bottom: 2rem;

  input {
    padding: 0.5rem;
    border: 0.01rem solid gray;
    border-radius: 2rem;
    width: 100%;
  }

  .secstocks {
    margin-top: 0.5rem;
    display: grid;
    grid-template-columns: 1fr 1fr;

    .delete {
      display: grid;
      align-items: center;
      justify-items: center;
      height: 100%;

      .deletebutton {
        height: 100%;
        display: grid;
        align-items: center;
        justify-items: center;
        width: 100%;
      }

      button {
        border: 0.01rem solid gray;
        border-radius: 2rem;
        height: 40%;
        width: 70%;
      }
    }
  }

  .sec {
    margin-top: 0.5rem;
  }
  .secname {
    width: 90%;
    margin-left: 10%;
    margin-right: 10%;
    margin-bottom: 0.2rem;
  }

  .in {
    border: 0.01rem solid gray;
    border-radius: 1rem;
    width: 30%;
    text-align: center;
    margin: 0.5rem;
  }

  .button {
    width: 100%;
    height: 2rem;

    button {
      width: 70%;
      height: 100%;
      margin-left: 15%;
      margin-right: 15%;
      border-radius: 2rem;
      border: 0.01rem solid gray;
    }
  }

  .loader {
    display: grid;
    justify-items: center;
    align-items: center;
  }
`;

const Img = styled(motion.div)`
  height: 20%;
  width: 100%;
  display: grid;
  align-items: center;
  justify-items: center;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  img {
    max-height: 100%;
    max-width: 100%;
  }

  .imginfo {
    display: grid;
    align-items: end;
  }
`;

const Info = styled(motion.div)`
  position: absolute;

  height: 10%;
  width: 100%;
  bottom: 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  justify-items: center;
  border-top: 0.02rem solid gray;

  .stock {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }

  .salesstock {
    display: grid;
    grid-template-rows: 1fr 1fr;
    justify-items: center;
  }
`;

const Generate = styled(motion.div)`
  margin-top: 10%;
  padding: 1rem;
  margin-left: 10%;
`;

export default SingleProductEdit;
