import axios from "axios";

import { BASE_URL } from "./configAPI";

const order = () => {
  let external = localStorage.getItem("persist:root");

  if (external === null) {
    return 1;
  } else {
    let inter = JSON.parse(
      JSON.parse(localStorage.getItem("persist:root")).user
    ).currentUser;

    if (inter) {
      let internal = JSON.parse(
        JSON.parse(localStorage.getItem("persist:root")).user
      ).currentUser.accessToken;

      return internal;
    } else {
      return 1;
    }
  }
};

const callOrder = order();

export const publicRequest = axios.create({
  baseURL: BASE_URL,
});

export const userRequest = axios.create({
  baseURL: BASE_URL,
  headers: { token: `Bearer ${callOrder}` },
});


