import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import Navbar from "../../components/navbar/navbar";
import SingleProduct from "./singleProduct";

const SingleProductPage = () => {
  return (
    <Body>
      <Navbar />
      <SingleProduct />
    </Body>
  );
};
const Body = styled(motion.div)``;

export default SingleProductPage;
