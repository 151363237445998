import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import {
  add,
  del,
  remove,
  addProduct,
  nameChange,
} from "../../redux/cartredux";
import {
  addProduct_ii,
  add_ii,
  del_ii,
  remove_ii,
  empty_ii,
  nameChange_ii,
} from "../../redux/cartredux_two";
import {
  addProduct_iii,
  add_iii,
  del_iii,
  remove_iii,
  empty_iii,
  nameChange_iii,
} from "../../redux/cartredux_three";
import {
  addProduct_iv,
  add_iv,
  del_iv,
  remove_iv,
  empty_iv,
  nameChange_iv,
} from "../../redux/cartredux_four";
import {
  addProduct_v,
  add_v,
  del_v,
  remove_v,
  empty_v,
  nameChange_v,
} from "../../redux/cartredux_five";
import CustomerCheck from "../../components/cartproducts/checkoutslides";
import TransactionType from "../../components/cartproducts/checkouttype";
import FinalCheckout from "../../components/cartproducts/finalcheckoutreport";
import PrintingPage from "../../components/cartproducts/printingpage";
import CustomerCreate from "../../components/cartproducts/customerCreate";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { publicRequest, userRequest } from "../../requestmethods";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsArrowDownCircleFill } from "react-icons/bs";
import CurrentCust from "./currentcustomers";
import CheckoutReport from "./checkoutreport";
import Printing from "./printing";

const InOrder = ({
  scanResultWebCam,
  showArrow,
  setFinishSelected,
  finishSelected,
  searchBAr,
  page,
  setPage,
  editName,
  setEditName,
  orderName,
  setOrderName,
}) => {
  //
  const dispatch = useDispatch();
  const user = useSelector((state) => state?.user?.currentUser);
  const timer = useSelector((state) => state?.user?.timer);
  const cart = useSelector((state) => state?.cart);
  const cart_ii = useSelector((state) => state?.cart_two);
  const cart_iii = useSelector((state) => state?.cart_three);
  const cart_iv = useSelector((state) => state?.cart_four);
  const cart_v = useSelector((state) => state?.cart_five);
  const ongoingTimer = moment(new Date()).unix();
  const diff = ongoingTimer - timer;
  const divRef = useRef(null);
  const [createCust, setCreateCust] = useState(false);
  const [transType, setTransType] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [finalCheck, setFinalCheck] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [customerDets, setCustomerDets] = useState(null);
  const [amountPaid, setAmountPaid] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [nameOrder, setNameOrder] = useState("");
  const rando = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
  const norando = moment(new Date()).unix();
  const [orderCode, setOrderCode] = useState([]);
  const [warningOne, setWarningOne] = useState(false);
  const [warningTwo, setWarningTwo] = useState(false);
  const [userWarning, setUserWarning] = useState(false);
  const changePage = nameOrder + rando + norando;
  const history = useHistory();
  const [product, setProduct] = useState(null);
  const [cartsSlides, setCartsSlides] = useState([]);
  const [finalCut, setFinalCut] = useState(null);
  const quantity = 1;
  //

  useEffect(() => {
    const loadingFun = async () => {
      let theCarts = [cart, cart_ii, cart_iii, cart_iv, cart_v];

      setCartsSlides(theCarts);
    };

    loadingFun();
  }, [
    cart?.products,
    cart_ii?.products,
    cart_iii?.products,
    cart_iv?.products,
    cart_v?.products,
    cart?.name,
    cart_ii?.name,
    cart_iii?.name,
    cart_iv?.name,
    cart_v?.name,
    page,
  ]);

  const getInfo = () => {
    if (user !== null && diff < 3600) {
      if (user.accessToken) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const detailsAvailable = getInfo();

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  });





  /* const makeInStoreOrder = async () => {
    try {
      const proArray = [];
      const productsTopush = async () => {
        await cart.products.map((d) => {
          let ooz = {
            productId: d._id,
            quantity: d.quantity,
          };

          proArray.push(ooz);
        });
      };
      await productsTopush();

      const order = await {
        userId: nameOrder,
        orderId: nameOrder + rando + norando + "cust",
        user_ID: user._id,
        products: proArray,
        amount: cart.total[0],
      };

      const makePost = async () => {
        if (detailsAvailable) {
          const res = await userRequest.post("/instoreorders/", order);
          return res;
        }
      };

      await makePost()
        .then(history.push(`/scanner/checkout/${changePage}`))
        .catch((error) => console.log(error));
    } catch (err) {
      console.log(err);
    }
  };

   const initiateOrder = () => {
    if (cart?.products[0]) {
      if (nameOrder) {
        if (detailsAvailable) {
          makeInStoreOrder();
        } else {
          setUserWarning(true);
        }
      } else {
        setWarningTwo(true);
      }
    } else {
      setWarningOne(true);
    }
  }; */

  const editCart = (type, x) => {
    if (type === "inc") {
      if (page === 0) {
        let patcher = cart?.products[x]?.quantity;

        patcher += 1;

        dispatch(add({ index: x, no: patcher }));
      } else if (page === 1) {
        let patcher = cart_ii?.products[x]?.quantity;

        patcher += 1;

        dispatch(add_ii({ index: x, no: patcher }));
      } else if (page === 2) {
        let patcher = cart_iii?.products[x]?.quantity;

        patcher += 1;

        dispatch(add_iii({ index: x, no: patcher }));
      } else if (page === 3) {
        let patcher = cart_iv?.products[x]?.quantity;

        patcher += 1;

        dispatch(add_iv({ index: x, no: patcher }));
      } else if (page === 4) {
        let patcher = cart_v?.products[x]?.quantity;

        patcher += 1;

        dispatch(add_v({ index: x, no: patcher }));
      }
    } else if (type === "dec") {
      if (page === 0) {
        if (cart?.products[x]?.quantity > 1) {
          let patcher = cart?.products[x]?.quantity;

          patcher -= 1;

          dispatch(del({ index: x, no: patcher }));
        }
      } else if (page === 1) {
        let patcher = cart_ii?.products[x]?.quantity;

        patcher -= 1;

        dispatch(del_ii({ index: x, no: patcher }));
      } else if (page === 2) {
        let patcher = cart_iii?.products[x]?.quantity;

        patcher -= 1;

        dispatch(del_iii({ index: x, no: patcher }));
      } else if (page === 3) {
        let patcher = cart_iv?.products[x]?.quantity;

        patcher -= 1;

        dispatch(del_iv({ index: x, no: patcher }));
      } else if (page === 4) {
        let patcher = cart_v?.products[x]?.quantity;

        patcher -= 1;

        dispatch(del_v({ index: x, no: patcher }));
      }
    }
  };

  const removeItem = (x) => {
    if (page === 0) {
      dispatch(remove({ index: x }));
    } else if (page === 1) {
      dispatch(remove_ii({ index: x }));
    } else if (page === 2) {
      dispatch(remove_iii({ index: x }));
    } else if (page === 3) {
      dispatch(remove_iv({ index: x }));
    } else if (page === 4) {
      dispatch(remove_v({ index: x }));
    }
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        if (scanResultWebCam) {
          const res = await publicRequest.get(
            `/products/find/${scanResultWebCam}`
          );

          setProduct(res?.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getProduct();
  }, [scanResultWebCam]);

  useEffect(() => {
    const addCart = () => {
      if (page === 0) {
        dispatch(addProduct({ ...product, quantity }));
      } else if (page === 1) {
        dispatch(addProduct_ii({ ...product, quantity }));
      } else if (page === 2) {
        dispatch(addProduct_iii({ ...product, quantity }));
      } else if (page === 3) {
        dispatch(addProduct_iv({ ...product, quantity }));
      } else if (page === 4) {
        dispatch(addProduct_v({ ...product, quantity }));
      }
    };

    product && addCart();
  }, [product, dispatch]);

  const handlePieces = (x) => {
    let totalPisi = [];
    if (x?.products) {
      x?.products.map((d) => {
        let onlypcs = d.pcs;
        let onlyqty = d.quantity;
        let jumla = onlypcs * onlyqty;
        totalPisi.push(jumla);
      });
    }
    const total = totalPisi.reduce((a, b) => a + b, 0);
    return total;
  };

  const handleAmounts = (x) => {
    let totalPrice = [];
    let totalCtns = [];

    if (x?.products) {
      x?.products?.map((d) => {
        let onlyqty = d.quantity;

        totalCtns.push(onlyqty);
      });
    }

    const jumlaCtn = totalCtns.reduce((a, b) => a + b, 0);

    return jumlaCtn;
  };

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {finishSelected && (
            <CurrentCust
              setTransType={setTransType}
              transType={transType}
              setFinalCheck={setFinalCheck}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              setOrderType={setOrderType}
              setOrderStatus={setOrderStatus}
              finalCut={finalCut}
              setFinalCut={setFinalCut}
              setFinishSelected={setFinishSelected}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {finalCheck && (
            <CheckoutReport
              setFinalCheck={setFinalCheck}
              customerDets={customerDets}
              setCustomerDets={setCustomerDets}
              amountPaid={amountPaid}
              setTransType={setTransType}
              orderType={orderType}
              orderStatus={orderStatus}
              setOrderCode={setOrderCode}
              setPrinting={setPrinting}
              finalCut={finalCut}
              setFinalCut={setFinalCut}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {printing && (
            <Printing
              amountPaid={amountPaid}
              customerDets={customerDets}
              setOrderCode={setOrderCode}
              orderCode={orderCode}
              setCustomerDets={setFinalCheck}
              printing={printing}
              setPrinting={setPrinting}
              setFinalCheck={setFinalCheck}
              finalCut={finalCut}
              page={page}
              
            />
          )}
        </AnimatePresence>

        <Cart className="item">
          <Topper>
            <div className={showArrow ? "arrow" : "noarrow"}>
              <BsArrowDownCircleFill size={20} />
            </div>

            <div className="titlex">
              <div className="cartX">
                <div className="namesPages">
                  {editName || cartsSlides[page]?.name?.length === 0 ? (
                    <div className="actualname">
                      <input
                        className="input"
                        type="text"
                        placeholder="name"
                        onChange={(e) => setOrderName(e.target.value)}
                      />
                    </div>
                  ) : (
                    <div onClick={() => setEditName(true)} className="setname">
                      {cartsSlides[page]?.name?.length > 0
                        ? cartsSlides[page]?.name[0]
                        : ""}
                    </div>
                  )}
                  <div className="noPage">{page + 1}</div>
                </div>
              </div>
            </div>
          </Topper>
          <Middle>
            {cartsSlides[page]?.products?.map((p, index) => (
              <div className="added" key={index}>
                <div className="cartimg">
                  <img src={p.img} alt="await" />
                </div>
                <div className="data">
                  <div className="artNoCart dc">
                    <div>{p.artNo}</div>
                    <div className="remove" onClick={() => removeItem(index)}>
                      <IoIosCloseCircleOutline size={25} fontWeight={100} />
                    </div>
                  </div>
                  <div className="sizeCart dc">size | {p.size}</div>
                  <div className="sizeCart dc">pcs | {p.pcs}</div>
                  <div className="pcsCart dc">price | {p.price} Tshs</div>
                  <div className="at">
                    <div className="amountbox">
                      <div
                        className="minus mv"
                        onClick={() => editCart("dec", index)}
                      >
                        -
                      </div>
                      <div className="countAmount mv">{p.quantity}</div>
                      <div
                        className="plus mv"
                        onClick={() => editCart("inc", index)}
                      >
                        +
                      </div>
                    </div>
                    <div className="totalPrice">
                      {p.price * p.quantity * p.pcs} Tshs
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Middle>
          <Bottom ref={divRef}>
            <div className="summation">
              <div className="subtotal">Pieces</div>
              <div className="actualtotal">
                {handlePieces(cartsSlides[page] && cartsSlides[page])}
              </div>
            </div>
            <div className="summation">
              <div className="subtotal">Cartons</div>
              <div className="actualtotal">
                {handleAmounts(cartsSlides[page] && cartsSlides[page])}
              </div>
            </div>
            <div className="summation">
              <div className="subtotal">subtotal</div>
              <div className="actualtotal">
                {cartsSlides[page] && cartsSlides[page]?.total[0]} Tshs
              </div>
            </div>
            <div className="nuance">Order Summary</div>
          </Bottom>
        </Cart>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 70%;
    top: 20%;
    bottom: 10%;
    width: 100%;
    z-index: 0;
  }
`;

const Cart = styled(motion.div)`
  @media (max-width: 768px) {
  }
`;
const Topper = styled(motion.div)`
  @media (max-width: 768px) {
    top: 0%;
    position: absolute;
    display: grid;
    grid-template-columns: 10% 90%;
    grid-template-rows: 1fr;
    align-items: end;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: 5%;

    border-bottom: 0.02rem solid gray;

    background-color: white;
    z-index: 1;
    bottom: 95%;

    .arrow {
      height: 100%;
      width: 100%;
      display: grid;
      align-items: center;

      margin-bottom: 1.5%;
    }

    .noarrow {
      position: absolute;
      width: 10%;
      display: grid;
      align-items: end;
      margin-bottom: 1.5%;
      visibility: hidden;
      left: 0;
      height: 100%;
    }

    .titlex {
      right: 0;
      position: absolute;
      width: 90%;
      height: 100%;
      display: grid;
      justify-items: end;
      align-items: end;
      background-color: white;
      z-index: 1;

      .cartX {
        width: 100%;
        height: 100%;
        font-weight: 500;
        font-size: 80%;
      }
    }

    .namesPages {
      display: grid;
      grid-template-columns: 95% 5%;
      width: 100%;
      height: 100%;
      align-items: center;
      justify-items: center;

      .noPage {
        height: 100%;
        width: 100%;
        font-weight: 1000;
        font-size: 150%;
        text-align: center;
      }

      .setname {
        width: 100%;
        height: 100%;
        display: grid;
        align-items: center;
        font-weight: 2000;
      }

      .actualname {
        display: grid;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-items: center;

        input {
          height: 100%;
          width: 100%;
          padding: 0 1.5% 0 1.5%;
          font-size: 100%;
          font-weight: 500;
          border: none;
        }
        input[type="text"]:focus {
          outline: 0.1px solid dimgray;
        }
      }
    }
  }
`;
const Middle = styled(motion.div)`
  @media (max-width: 768px) {
    overflow-y: scroll;
    height: 80%;
    bottom: 15%;
    position: absolute;

    .added {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .artNoCart {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
      }

      .at {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .totalPrice {
          text-align: end;
          margin-right: 0.5rem;
        }

        .amountbox {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: 60%;
          margin-left: 1rem;
          border: 0.05rem solid gray;
          justify-items: center;
          align-items: center;
          border-radius: 20rem;
        }
      }

      .cartimg {
        display: grid;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .data {
        margin: 1rem 0.5rem 1rem 1rem;
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        align-items: center;
        grid-gap: 0.25rem;

        .dc {
          margin-left: 1rem;
        }

        .remove {
          display: grid;
          justify-items: end;
          align-items: center;
        }
      }

      .cartBox {
        overflow: scroll;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        height: 100%;

        .added {
          display: grid;
          grid-template-columns: 1fr 2.5fr;

          margin-top: 0.5rem;
          position: relative;

          .at {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .totalPrice {
              text-align: end;
              margin-right: 0.5rem;
            }

            .amountbox {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              width: 60%;
              margin-left: 1rem;
              border: 0.05rem solid gray;
              justify-items: center;
              align-items: center;
              border-radius: 20rem;
            }
          }
        }
      }
    }
  }
`;
const Bottom = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
    top: 85%;
    width: 100%;
    height: 15%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.25fr;
    border-top: 0.02rem dotted gray;

    .nuance {
      font-size: 20%;
    }

    .summation {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 95%;
      border-top: 0.02rem dotted gray;

      font-size: 90%;

      .actualtotal {
        text-align: end;
      }
    }
    .checkingOut {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      margin-bottom: 0.5rem;
      width: 100%;

      .buttonandinput {
        display: grid;
        margin: 1rem;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;

        button {
          width: 60%;
          border: 0.02rem solid gray;
          border-radius: 20rem;
          padding: 0.5rem;
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`;

export default InOrder;
