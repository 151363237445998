import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import Home from "./home";
import BottomNav from "../../components/bottomnav/bottomhomenav";
import { useState } from "react";
import SideBar from "../../components/home/sidebar";
import { useEffect } from "react";

const HomePage = () => {
  const user = useSelector((state) => state.user.currentUser);
  const [sidebar, setSidebar] = useState(false);

  return (
    <Body>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <Navbar />
        <Home />
        <BottomNav sidebar={sidebar} setSidebar={setSidebar} />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)``;

export default HomePage;
