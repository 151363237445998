import React from "react";
import { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { useLocation } from "react-router-dom";
import { publicRequest } from "../../requestmethods";
import SimilarProducts from "../../components/products/similarProducts";
import SimilarSizes from "../../components/products/similarSizes";
import { addProduct, add, del } from "../../redux/cartredux";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Cartier from "../../components/cart/cart";

const SingleProduct = () => {
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const [product, setProduct] = useState({});
  const [quantity, setQuantity] = useState(1);
  const [dropPin, setDropPin] = useState(false);
  const [seeCart, setSeeCart] = useState(false);
  const [colorNo, setColorNo] = useState(null);
  const cart = useSelector((state) => state.cart);
  const dispatch = useDispatch();

  useEffect(() => {
    const getProduct = async () => {
      try {
        const res = await publicRequest.get("/products/find/" + id);

        setProduct(res?.data);
        setColorNo(res?.data?.color);
      } catch (err) {
        console.log(err);
      }
    };

    getProduct();
  }, [id]);

  const handleQuantity = (type) => {
    if (type === "dec") {
      quantity > 1 && setQuantity(quantity - 1);
    } else {
      setQuantity(quantity + 1);
    }
  };

  const addCart = () => {
    dispatch(addProduct({ ...product, quantity }));

    setDropPin(true);
  };

  useEffect(() => {
    let interval = setInterval(() => {
      setDropPin(false);
    }, 2000);

    return () => {
      clearInterval(interval);
    };
  }, [dropPin]);

  const editCart = (type, x) => {
    if (type === "inc") {
      let patcher = cart.products[x].quantity;

      patcher += 1;

      dispatch(add({ index: x, no: patcher }));
    } else if (type === "dec") {
      if (cart.products[x].quantity > 1) {
        let patcher = cart.products[x].quantity;

        patcher -= 1;

        dispatch(del({ index: x, no: patcher }));
      }
    }
  };

  const showCart = () => {
    setSeeCart(!seeCart);
  };

  return (
    <Container>
      <Cart className={`hidecart  ${seeCart ? "showcart" : ""}`}>
        <div className="transparent" onClick={showCart}></div>
        <div className="ontopcartpage">
          <div className="cartpage">
            <div className="title">
              <div className="titlecart">
                <div className="titleText">Cart</div>
              </div>
              <div className="titlex" onClick={showCart}>
                <div className="cartX"> X </div>
              </div>
            </div>
            <div className="cartBox">
              {cart.products.map((p, index) => (
                <div className="added" key={index}>
                  <div className="cartimg">
                    <img src={p.img} alt="wait" />
                  </div>
                  <div className="data">
                    <div className="artNoCart dc">{p.artNo}</div>
                    <div className="sizeCart dc">size | {p.size}</div>
                    <div className="pcsCart dc">price | {p.price} Tshs</div>
                    <div className="at">
                      <div className="amountbox">
                        <div
                          className="minus mv"
                          onClick={() => editCart("dec", index)}
                        >
                          -
                        </div>
                        <div className="countAmount mv">{p.quantity}</div>
                        <div
                          className="plus mv"
                          onClick={() => editCart("inc", index)}
                        >
                          +
                        </div>
                      </div>
                      <div className="totalPrice">
                        {p.price * p.quantity * p.pcs} Tshs
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <div className="footer">
              <div className="summation">
                <div className="subtotal">subtotal</div>
                <div className="actualtotal">{cart.total[0]} Tshs</div>
              </div>
              <div className="nuance">taxes and shipping all inclusive</div>
              <Link className="checklink" to="/checkout" onClick={showCart}>
                <div className="checkoutbutton"> Checkout</div>
              </Link>
            </div>
          </div>
        </div>
      </Cart>

      <Image>
        <img src={product.img} alt="await" />
      </Image>
      <Info>
        <div className="artno">
          <h4>{product.artNo}</h4>
        </div>
        <div className="price">
          <h3>{`${product.price} Tshs`}</h3>
        </div>

        {/* <Similar>{product && <SimilarSizes products={product} />}
       </Similar>*/}

        {/*  <Defcolor>
          <h4 className="color">color </h4>
          <h4 className="defcolor">{colorNo?.length}</h4>
      </Defcolor>*/}
        {/*product && (
          <Color style={{ ["--length"]: colorNo?.length }}>
            {product.color?.map((c) => (
              <FilterColor color={c} key={c} />
            ))}
          </Color>
        )*/}

        {/* <Selection>
          {product && <SimilarProducts products={product} />}
            </Selection>*/}

        <Defsizenpcs>
          <div className="sizeonly">
            <h4 className="color">size </h4>
            <h4 className="defcolor">{product.size}</h4>
          </div>
          <div className="pcsonly">
            <h4 className="color">pcs </h4>
            <h4 className="defcolor">{product.pcs}</h4>
          </div>
        </Defsizenpcs>
        <PickAndChoose>
          <div className="at">
            <div className="amountbox">
              <div className="minus mv" onClick={() => handleQuantity("dec")}>
                -
              </div>
              <div className="countAmount mv">{quantity}</div>
              <div className="plus mv" onClick={() => handleQuantity("inc")}>
                +
              </div>
            </div>
          </div>
        </PickAndChoose>

        <AddCart onClick={addCart}>
          <p> Add to Cart</p>
        </AddCart>
      </Info>

      <Cartier
        producto={product}
        droppin={dropPin}
        seeCart={seeCart}
        setSeeCart={setSeeCart}
      />
    </Container>
  );
};

const Container = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    overflow-y: scroll;
    overflow-x: hidden;
    max-width: 100%;
    max-height: 80%;
    bottom: 10%;
    top: 20%;
  }

  .artno {
    height: 4vh;
    text-align: center;
    width: 100%;
  }
  .price {
    height: 4vh;
    text-align: center;
    width: 100%;
  }

  .hidecart {
    visibility: hidden;
  }
  .showcart {
    visibility: visible;
    transform: translateX(0%);
  }
`;

const PickAndChoose = styled(motion.div)`
@media (max-width: 768px) {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  align-items: center;

  .at {
    display: grid;
    grid-template-columns:1fr;
    margin-top: 2%;
    margin-bottom: 2%;
    width:100%;
    align-items:center;
    justify-items:center;

    .amountbox {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      width: 30%;
      
      border: 0.05rem solid gray;
      justify-items: center;
      align-items: center;
      border-radius: 20rem;
    }

    .sizenpcs{
      display:grid;
      grid-template-columns:1fr 1fr;
    }
  }
  `;

const Cart = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: 1fr 5fr;
    height: 100%;
    width: 100%;
    top: 0;
    right: 0;
    position: fixed;
    transform: translateX(100%);
    transition: all 0.5s ease;
    z-index: 1;

    .transparent {
      background-color: #fff;
      background-color: rgba(255, 255, 255, 0.5);
    }

    .ontopcartpage {
      position: relative;
    }

    .cartpage {
      background-color: rgb(245, 245, 244);
      display: grid;
      grid-template-rows: 1fr 12fr 2fr;
      height: 100%;
      width: 100%;
      position: absolute;

      .title {
        top: 0;
        margin-bottom: 0;
        padding-bottom: 0;
        display: grid;
        grid-template-columns: 5fr 1fr;
        border-bottom: 1px solid gray;
        justify-items: center;
        align-items: center;
        margin: 0.5rem;
        font-size: 3vh;

        .titlecart {
          display: grid;
          justify-items: start;
          width: 100%;
        }

        .titlex {
          display: grid;
          justify-items: end;
          width: 100%;
        }
      }

      .footer {
        width: 80%;
        bottom: 0;
        margin: 0.5rem 0.5rem 0 0.5rem;

        background-color: rgb(245, 245, 244);
        z-index: 1;

        border-top: 0.05rem solid gray;
        display: grid;
        grid-template-rows: 1fr 1fr 1.5fr;

        .summation {
          width: 100%;
          display: grid;
          grid-template-columns: 1fr 1fr;

          .subtotal {
            text-align: start;
          }
          .actualtotal {
            margin: 0 0.45rem 0 0;
            text-align: end;
            padding-left: 3rem;
            font-size: 2vh;
            font-weight: 500;
          }
        }

        .nuance {
          text-align: center;
          font-size: 0.8rem;
          margin: 0.5rem;
        }

        .checklink {
          text-decoration: none;
        }

        .checkoutbutton {
          display: grid;
          justify-items: center;
          align-items: center;
          border: 0.05rem solid gray;
          border-radius: 20rem;
          margin: 1rem;
          color: black;
          height: 40%;
          width: 60%;
          margin-left: 20%;
          margin-right: 20%;
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }

      .cartimg {
        height: 20vh;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .data {
        .dc {
          height: 5vh;
          margin-left: 1rem;
        }
      }

      .cartBox {
        overflow: scroll;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        height: 100%;

        .added {
          display: grid;
          grid-template-columns: 1fr 2.5fr;

          margin-top: 0.5rem;
          position: relative;

          .at {
            grid-row: 7/10;
            display: grid;
            grid-template-columns: 2fr 1fr;

            .amountbox {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              width: 60%;
              margin-left: 1rem;
              border: 0.05rem solid gray;
              justify-items: center;
              align-items: center;
              border-radius: 20rem;
            }
          }
        }

        .added:last-child {
        }
      }
    }
  }
`;

const Image = styled(motion.div)`
  @media (max-width: 768px) {
    grid-row: 1/3;
    align-items: center;
    justify-content: center;
    margin: 1rem 0 2rem 0;
    width: 100%;
    position: relative;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      align-items: center;
    }
  }
`;

const Similar = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    width: 100%;
    justify-items: center;
    align-items: center;
    height: 10vh;
    margin-bottom: 1.5rem;
  }
`;
// grid-template-columns: repeat(${(props) => props.nocolor}, 1fr);
const Color = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(var(--length), minmax(10%, 1fr));
    grid-gap: 0rem;
    height: 5vh;
    justify-items: center;
    align-items: center;
    padding: 0 5rem 0 5rem;

    .circle {
      border-radius: 50%;
      border: 1px solid;
      height: 3vh;
      width: 3vh;
    }

    .black {
      background-color: black;
    }
    .white {
      background-color: white;
    }
    .brown {
      background-color: brown;
    }
    .red {
      background-color: red;
    }
  }
`;

const FilterColor = styled(motion.div)`
  @media (max-width: 768px) {
    width: 3vh;
    height: 3vh;
    border-radius: 50%;
    background-color: ${(props) => props.color};

    margin: 0px 5px;
    cursor: pointer;
  }
`;

const Defcolor = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    margin: 1rem;
    .color {
      text-align: right;
      border-right: 0.08rem solid;
      margin: 0 0.5rem 0 0;
    }
    .defcolor {
      left: 0;
      margin: 0 0 0 0.5rem;
    }
  }
`;

const Defsizenpcs = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-gap: 0.5rem;
    justify-items: center;
    margin: 1rem;

    .pcsonly {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }

    .sizeonly {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .color {
      text-align: right;
      border-right: 0.08rem solid;
      margin: 0 0.5rem 0 0;
    }

    .defcolor {
      left: 0;
      margin: 0 0 0 0.5rem;
    }
  }
`;

const AddCart = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    grid-template-column: 1fr;
    align-items: center;
    height: 8vh;

    p {
      border: 0.02rem solid gray;
      padding: 0;
      border-radius: 20rem;
      height: 80%;
      margin: 0 3rem 0 3rem;
      display: flex;
      justify-content: center;
      align-items: center;
      -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
      box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
    }
  }
`;

const Selection = styled(motion.div)`
  @media (max-width: 768px) {
    display: grid;
    width: 100%;
    justify-items: center;
    align-items: center;
    height: 8vh;
    margin-bottom: 1.5rem;
  }
`;

const Info = styled(motion.div)``;

export default SingleProduct;
