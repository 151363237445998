import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const userSlice = createSlice({
  name: "user",
  initialState: {
    currentUser: null,
    isFetching: false,
    error: false,
    timer: null,
  },

  reducers: {
    loginStart: (state) => {
      state.isFetching = true;
    },
    loginSuccess: (state, action) => {
      const minitime = new Date();
      state.currentUser = action.payload;
      state.isFetching = false;
      state.timer = moment(minitime).unix();
    },
    loginUserChanged: (state, action) => {
      if (action.payload.chainStatus) {
        state.currentUser = [
          ...state.currentUser,
          (state.currentUser[0].tempsecret =
            action.payload.chainStatus.tempsecret),
          (state.currentUser[0].secret = action.payload.chainStatus.secret),
          (state.currentUser[0].secretstatus =
            action.payload.chainStatus.secretstatus),
        ];
      } else {
        state.currentUser = [
          ...state.currentUser,
          (state.currentUser[0].tempsecret =
            action.payload.dataChanges.tempsecret),
          (state.currentUser[0].secret = action.payload.dataChanges.secret),
          (state.currentUser[0].secretstatus =
            action.payload.dataChanges.secretstatus),
        ];
      }
    },
    loginFailure: (state) => {
      state.isFetching = false;
      state.error = true;
    },
    logout: (state) => {
      state.isFetching = false;
      state.currentUser = null;
      state.error = false;
      state.timer = null;
    },
  },
});

export const {
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  loginUserChanged,
} = userSlice.actions;
export default userSlice.reducer;

/*
 if (action.payload.chainStatus) {
        state.currentUser = [
          ...state.currentUser,
          (state.currentUser[0].tempsecret =
            action.payload.chainStatus.tempsecret),
          (state.currentUser[0].secret = action.payload.chainStatus.secret),
          (state.currentUser[0].secretstatus =
            action.payload.chainStatus.secretstatus),
        ];
      } else {
        state.currentUser = [
          ...state.currentUser,
          (state.currentUser[0].tempsecret =
            action.payload.dataChanges.tempsecret),
          (state.currentUser[0].secret = action.payload.dataChanges.secret),
          (state.currentUser[0].secretstatus =
            action.payload.dataChanges.secretstatus),
        ];
      }



*/
