import React from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useDispatch, useSelector } from "react-redux";
import { add, del, remove, addProduct } from "../../redux/cartredux";
import CustomerCheck from "../../components/cartproducts/checkoutslides";
import TransactionType from "../../components/cartproducts/checkouttype";
import FinalCheckout from "../../components/cartproducts/finalcheckoutreport";
import PrintingPage from "../../components/cartproducts/printingpage";
import CustomerCreate from "../../components/cartproducts/customerCreate";
import { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router";
import moment from "moment";
import { publicRequest, userRequest } from "../../requestmethods";
import { IoIosCloseCircleOutline } from "react-icons/io";
import { BsArrowDownCircleFill } from "react-icons/bs";

const Checkout = ({
  scanResultWebCam,
  showArrow,
  setFinishSelected,
  finishSelected,
  searchBAr,
}) => {
  //
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.currentUser);
  const timer = useSelector((state) => state.user.timer);
  const ongoingTimer = moment(new Date()).unix();
  const diff = ongoingTimer - timer;
  const divRef = useRef(null);
  const [createCust, setCreateCust] = useState(false);
  const [transType, setTransType] = useState(false);
  const cart = useSelector((state) => state.cart);
  const [selectedUser, setSelectedUser] = useState(null);
  const [finalCheck, setFinalCheck] = useState(false);
  const [printing, setPrinting] = useState(false);
  const [customerDets, setCustomerDets] = useState(null);
  const [amountPaid, setAmountPaid] = useState(null);
  const [orderType, setOrderType] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [nameOrder, setNameOrder] = useState("");
  const rando = Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
  const norando = moment(new Date()).unix();
  const [orderCode, setOrderCode] = useState(null);
  const [warningOne, setWarningOne] = useState(false);
  const [warningTwo, setWarningTwo] = useState(false);
  const [userWarning, setUserWarning] = useState(false);
  const changePage = nameOrder + rando + norando;
  const history = useHistory();
  const [product, setProduct] = useState(null);

  const quantity = 1;
  //

  const getInfo = () => {
    if (user !== null && diff < 3600) {
      if (user.accessToken) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const detailsAvailable = getInfo();

  useEffect(() => {
    divRef.current.scrollIntoView({ behavior: "smooth" });
  });

  const makeInStoreOrder = async () => {
    try {
      const proArray = [];
      const productsTopush = async () => {
        await cart?.products?.map((d) => {
          let ooz = {
            productId: d._id,
            quantity: d.quantity,
          };

          proArray.push(ooz);
        });
      };
      await productsTopush();

      const order = await {
        userId: nameOrder,
        orderId: nameOrder + rando + norando,
        user_ID: user._id,
        products: proArray,
        amount: cart.total[0],
      };

      const makePost = async () => {
        if (detailsAvailable) {
          const res = await userRequest.post("/instoreorders/", order);
          return res;
        }
      };

      await makePost()
        .then(history.push(`/scanner/checkout/${changePage}`))
        .catch((error) => console.log(error));
    } catch (err) {
      console.log(err);
    }
  };

  const initiateOrder = () => {
    if (cart.products[0]) {
      if (nameOrder) {
        if (detailsAvailable) {
          makeInStoreOrder();
        } else {
          setUserWarning(true);
        }
      } else {
        setWarningTwo(true);
      }
    } else {
      setWarningOne(true);
    }
  };

  const editCart = (type, x) => {
    if (type === "inc") {
      let patcher = cart.products[x].quantity;

      patcher += 1;

      dispatch(add({ index: x, no: patcher }));
    } else if (type === "dec") {
      if (cart.products[x].quantity > 1) {
        let patcher = cart.products[x].quantity;

        patcher -= 1;

        dispatch(del({ index: x, no: patcher }));
      }
    }
  };

  const removeItem = (x) => {
    dispatch(remove({ index: x }));
  };

  useEffect(() => {
    const getProduct = async () => {
      try {
        if (scanResultWebCam) {
          const res = await publicRequest.get(
            `/products/find/${scanResultWebCam}`
          );

          setProduct(res.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    getProduct();
  }, [scanResultWebCam]);

  useEffect(() => {
    const addCart = () => {
      dispatch(addProduct({ ...product, quantity }));
    };

    product && addCart();
  }, [product, dispatch]);

  const handlePieces = () => {
    let totalPisi = [];
    if (cart.products) {
      cart.products.map((d) => {
        let onlypcs = d.pcs;
        let onlyqty = d.quantity;
        let jumla = onlypcs * onlyqty;
        totalPisi.push(jumla);
      });
    }
    const total = totalPisi.reduce((a, b) => a + b, 0);
    return total;
  };

  const handleAmounts = () => {
    let totalPrice = [];
    let totalCtns = [];

    if (cart.products) {
      cart.products.map((d) => {
        let onlyqty = d.quantity;

        totalCtns.push(onlyqty);
      });
    }

    const jumlaCtn = totalCtns.reduce((a, b) => a + b, 0);

    return jumlaCtn;
  };

  return (
    <Container>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {finishSelected && (
            <CustomerCheck
              setFinishSelected={setFinishSelected}
              finishSelected={finishSelected}
              setSelectedUser={setSelectedUser}
              selectedUser={selectedUser}
              setTransType={setTransType}
              transType={transType}
              customerDets={customerDets}
              setCustomerDets={setCustomerDets}
              setCreateCust={setCreateCust}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {createCust && (
            <CustomerCreate
              setCreateCust={setCreateCust}
              createCust={createCust}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {transType && (
            <TransactionType
              setTransType={setTransType}
              transType={transType}
              setFinalCheck={setFinalCheck}
              amountPaid={amountPaid}
              setAmountPaid={setAmountPaid}
              setOrderType={setOrderType}
              setOrderStatus={setOrderStatus}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {finalCheck && (
            <FinalCheckout
              setFinalCheck={setFinalCheck}
              customerDets={customerDets}
              setCustomerDets={setCustomerDets}
              amountPaid={amountPaid}
              setTransType={setTransType}
              orderType={orderType}
              orderStatus={orderStatus}
              setOrderCode={setOrderCode}
              setPrinting={setPrinting}
            />
          )}
        </AnimatePresence>
        <AnimatePresence>
          {printing && (
            <PrintingPage
              amountPaid={amountPaid}
              customerDets={customerDets}
              setOrderCode={setOrderCode}
              orderCode={orderCode}
              setCustomerDets={setFinalCheck}
              printing={printing}
              setPrinting={setPrinting}
              setFinalCheck={setFinalCheck}
            />
          )}
        </AnimatePresence>
        <Cart>
          <Topper>
            <div className={showArrow ? "arrow" : "noarrow"}>
              <BsArrowDownCircleFill size={20} />
            </div>

            <div className="titlex">
              <div className="cartX"> Order Summary </div>
            </div>
          </Topper>
          <Middle>
            {cart?.products.map((p, index) => (
              <div className="added" key={index}>
                <div className="cartimg">
                  <img src={p.img} alt="await" />
                </div>
                <div className="data">
                  <div className="artNoCart dc">
                    <div>{p.artNo}</div>
                    <div className="remove" onClick={() => removeItem(index)}>
                      <IoIosCloseCircleOutline size={25} fontWeight={100} />
                    </div>
                  </div>
                  <div className="sizeCart dc">size | {p.size}</div>
                  <div className="sizeCart dc">pcs | {p.pcs}</div>
                  <div className="pcsCart dc">price | {p.price} Tshs</div>
                  <div className="at">
                    <div className="amountbox">
                      <div
                        className="minus mv"
                        onClick={() => editCart("dec", index)}
                      >
                        -
                      </div>
                      <div className="countAmount mv">{p.quantity}</div>
                      <div
                        className="plus mv"
                        onClick={() => editCart("inc", index)}
                      >
                        +
                      </div>
                    </div>
                    <div className="totalPrice">
                      {p.price * p.quantity * p.pcs} Tshs
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Middle>
          <Bottom ref={divRef}>
            <div className="summation">
              <div className="subtotal">Pieces</div>
              <div className="actualtotal">{handlePieces()}</div>
            </div>
            <div className="summation">
              <div className="subtotal">Cartons</div>
              <div className="actualtotal">{handleAmounts()}</div>
            </div>
            <div className="summation">
              <div className="subtotal">subtotal</div>
              <div className="actualtotal">{cart.total[0]} Tshs</div>
            </div>
            <div className="nuance">taxes all inclusive</div>
          </Bottom>
        </Cart>
      </AnimateSharedLayout>
    </Container>
  );
};

const Container = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    overflow-y: hidden;
    overflow-x: hidden;
    height: 70%;
    top: 20%;
    bottom: 10%;
    width: 100%;
    z-index: 0;
  }
`;

const Cart = styled(motion.div)`
  @media (max-width: 768px) {
  }
`;
const Topper = styled(motion.div)`
  @media (max-width: 768px) {
    top: 0%;
    position: absolute;
    display: grid;
    grid-template-columns: 0.5fr 1.5fr;
    grid-template-rows: 1fr;
    align-items: end;
    width: 98%;
    margin-left: 1%;
    margin-right: 1%;
    height: 5%;

    border-bottom: 0.02rem solid gray;

    background-color: white;
    z-index: 1;
    bottom: 95%;

    .arrow {
      max-height: 100%;
      width: 100%;
      display: grid;
      align-items: end;
      margin-bottom: 1.5%;
    }

    .noarrow {
      width: 100%;
      display: grid;
      align-items: end;
      margin-bottom: 1.5%;
      visibility: hidden;

      max-height: 100%;
    }

    .titlex {
      width: 100%;
      height: 100%;
      display: grid;
      justify-items: end;
      align-items: end;
      background-color: white;
      z-index: 1;

      .cartX {
        font-weight: 500;
        font-size: 80%;
      }
    }
  }
`;
const Middle = styled(motion.div)`
  @media (max-width: 768px) {
    overflow-y: scroll;
    height: 80%;
    bottom: 15%;
    position: absolute;

    .added {
      display: grid;
      grid-template-columns: 1fr 2fr;

      .artNoCart {
        display: grid;
        grid-template-columns: 2fr 1fr;
        align-items: center;
      }

      .at {
        display: grid;
        grid-template-columns: 1fr 1fr;

        .totalPrice {
          text-align: end;
          margin-right: 0.5rem;
        }

        .amountbox {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          width: 60%;
          margin-left: 1rem;
          border: 0.05rem solid gray;
          justify-items: center;
          align-items: center;
          border-radius: 20rem;
        }
      }

      .cartimg {
        display: grid;
        align-items: center;

        img {
          max-width: 100%;
          max-height: 100%;
        }
      }
      .data {
        margin: 1rem 0.5rem 1rem 1rem;
        display: grid;
        grid-template-rows: repeat(5, 1fr);
        align-items: center;
        grid-gap: 0.25rem;

        .dc {
          margin-left: 1rem;
        }

        .remove {
          display: grid;
          justify-items: end;
          align-items: center;
        }
      }

      .cartBox {
        overflow: scroll;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 1rem;

        height: 100%;

        .added {
          display: grid;
          grid-template-columns: 1fr 2.5fr;

          margin-top: 0.5rem;
          position: relative;

          .at {
            display: grid;
            grid-template-columns: 1fr 1fr;

            .totalPrice {
              text-align: end;
              margin-right: 0.5rem;
            }

            .amountbox {
              display: grid;
              grid-template-columns: repeat(3, 1fr);
              width: 60%;
              margin-left: 1rem;
              border: 0.05rem solid gray;
              justify-items: center;
              align-items: center;
              border-radius: 20rem;
            }
          }
        }
      }
    }
  }
`;
const Bottom = styled(motion.div)`
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
    top: 85%;
    width: 100%;
    height: 15%;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-rows: 0.5fr 0.5fr 0.5fr 0.25fr;
    border-top: 0.02rem dotted gray;

    .nuance {
      font-size: 20%;
    }

    .summation {
      display: grid;
      grid-template-columns: 1fr 1fr;
      width: 95%;
      border-top: 0.02rem dotted gray;

      font-size: 90%;

      .actualtotal {
        text-align: end;
      }
    }
    .checkingOut {
      display: grid;
      grid-template-columns: 1fr;
      justify-items: center;
      align-items: center;
      margin-bottom: 0.5rem;
      width: 100%;

      .buttonandinput {
        display: grid;
        margin: 1rem;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center;

        button {
          width: 60%;
          border: 0.02rem solid gray;
          border-radius: 20rem;
          padding: 0.5rem;
          -moz-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }
`;

export default Checkout;
