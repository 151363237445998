import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import moment from "moment";

const DebtCustomer = ({ setShowDebts, negativeBal, handleClickedId }) => {
  const [collectiveBal, setCollectiveBal] = useState(null);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setShowDebts(false);
    }
  };

  useEffect(() => {
    const theTotal = negativeBal.reduce((a, b) => +a + +b.balance, 0);

    setCollectiveBal(theTotal);
  }, [negativeBal]);

  return (
    <CardShadow className="shadow" onClick={exitDetailHander}>
      <Items>
        <Table>
          <thead>
            <tr>
              <td>Date</td>
              <td>Payments</td>
              <td className="trans">Transactions</td>
            </tr>
          </thead>
          <tbody>
            {negativeBal?.map((d) => {
              return (
                <tr
                  onClick={() => handleClickedId(d?.transactionId)}
                  className="clicked"
                  key={d.transactionId}
                >
                  <td>{moment.unix(d.date).format("DD/MMM/YYYY")}</td>
                  <td className={d.info ? "pay" : ""}>
                    {d?.transactionId.slice(0, -12)}
                  </td>
                  <td className={d.orderstatus ? "trans" : ""}>{d.balance}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Balance>
          <div className="title">Balance</div>
          <div className="bal">{collectiveBal && collectiveBal}</div>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }

  .clicked {
    cursor: pointer;
  }
`;

const Items = styled(motion.div)`
  width: 80%;
  border-radius: 1rem;
  padding: 1rem;
  background: white;
  position: absolute;
  left: 10%;
  color: black;

  height: 70%;
  top: 10%;
`;

const Table = styled(motion.table)`
  position: relative;
  width: 100%;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;

  thead {
    .trans {
      text-align: center;
    }
  }

  tbody {
    .pay {
      background-color: #d3d3d3;
    }
    .trans {
      text-align: center;
    }
  }
`;

const Balance = styled(motion.div)`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;

  .bal {
    text-align: end;
  }
`;
export default DebtCustomer;
