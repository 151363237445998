import React, { useState } from "react";
import styled from "styled-components";
import { motion, AnimatePresence, AnimateSharedLayout } from "framer-motion";
import { useSelector } from "react-redux";
import Navbar from "../../components/navbar/navbar";
import BottomProductNav from "../../components/bottomnav/bottomproductnav";
import SideBar from "../../components/home/sidebar";
import RegisteredUsers from "../../components/users/userslist";

const UsersListPage = () => {
  const [sidebar, setSidebar] = useState(false);
  return (
    <Body>
      <AnimateSharedLayout type="crossfade">
        <AnimatePresence>
          {sidebar && <SideBar sidebar={sidebar} setSidebar={setSidebar} />}
        </AnimatePresence>
        <Navbar />
        <RegisteredUsers />
        <BottomProductNav sidebar={sidebar} setSidebar={setSidebar} />
      </AnimateSharedLayout>
    </Body>
  );
};
const Body = styled(motion.div)``;

export default UsersListPage;
