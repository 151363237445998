import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import { FiUserPlus } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";

const CustomerOrderCheck = ({
  finishSelected,
  setInitiateCheck,
  setTransCust,
  setInitiateTrans,
  isCust,
  nameId,
  customerId,
  setNameId,
  setCustomerId,
  setOrderCode,
  setOrderId,
}) => {
  const [customerList, setCustomerList] = useState(null);
  const User = useSelector((state) => state.user.currentUser);
  const [userSearch, setUserSearch] = useState("");

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setInitiateCheck(false);
    }
  };

  const handleClicks = (d) => {
    const rando = Math.random()
      .toString(36)
      .replace(/[^a-z]+/g, "")
      .substr(2, 10);
    const norando = moment(new Date()).unix();
    let orderNo = d?.username + rando + norando;
    setInitiateCheck(false);
    setInitiateTrans(true);
    setTransCust(d);

    if (isCust) {
      setCustomerId(d?._id);
      setNameId(d?.username);
      setOrderCode(orderNo);
      setOrderId(orderNo);
    }
  };

  useEffect(() => {
    const fullFunction = async () => {
      if (customerList) {
        setCustomerList(null);
      } else {
        if (User.isAdmin) {
          const res = await userRequest.get("/customers");

          const collectedRes = await res.data;
          const sorter = await collectedRes.sort((a, b) =>
            a.username.localeCompare(b.username)
          );

          setCustomerList(sorter);
        } else if (User.isWorker) {
          const res = await userRequest.get("/workerscustomers");

          const collectedRes = await res.data;
          const sorter = await collectedRes.sort((a, b) =>
            a.username.localeCompare(b.username)
          );

          setCustomerList(sorter);
        }
      }
    };

    fullFunction();
  }, [finishSelected]);

  useEffect(() => {
    const custSearch = async () => {
      try {
        if (userSearch) {
          const res = await userRequest.get(
            `/customers?username=${userSearch} `
          );

          setCustomerList(res?.data);
        }
      } catch (err) {
        console.log(err);
      }
    };

    custSearch();
  }, [userSearch]);

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <div className="title">Customer Names</div>
        <Input>
          <div className="search">search</div>
          <input type="text" onChange={(e) => setUserSearch(e.target.value)} />
        </Input>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          {customerList?.map((d) => (
            <Item key={d._id} value={d} onClick={() => handleClicks(d)}>
              {d.username}
            </Item>
          ))}
        </Balance>
        <Create>
          <Link
            to={User.isAdmin ? "/createcustomer" : "/createworkercustomer"}
            style={{ textDecoration: "none", color: "black" }}
          >
            <div> CREATE USER</div>
            <FiUserPlus size={25} />
          </Link>
        </Create>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;

  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;

  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 100%;
  border-radius: 1rem 1rem 0 0;
  background: white;
  position: absolute;
  color: black;
  height: 70%;
  bottom: 0;

  .title {
    margin-top: 1%;
    padding: 1%;
    font-size: 150%;
    height: 10%;
  }
`;

const Input = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  height: 15%;
  display: grid;
  align-items: center;
  grid-template-rows: 20% 80%;
  .search {
    margin-left: 5%;
    font-size: 100%;
  }

  input {
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
    border-radius: 2rem;
    border: 0.01rem solid gray;
    padding: 2.5%;
    font-size: 100%;
  }
`;

const Item = styled(motion.li)`
  width: 100%;
  font-size: 150%;
  background-color: rgb(243 241 243);
  padding: 1%;
  margin-left: 5%;
  margin-right: 5%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 60%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  overflow-y: scroll;
  padding: 2%;
  display: grid;
  grid-auto-rows: auto;
  grid-gap: 3.5%;
  align-items: center;
`;

const Create = styled(motion.div)`
  position: absolute;
  width: 100%;
  background: white;
  height: 15%;
  overflow: hidden;
  padding: 2.5%;
  bottom: 0;
  display: grid;
  align-items: center;
`;

export default CustomerOrderCheck;
