import React from "react";
import styled from "styled-components";
import ProductCross from "../../components/products/productCross";
import Customers from "../../components/customers/customers";
import WorkersCredit from "../../components/workers/workerscredit";
import { useSelector } from "react-redux";
import WorkersCustomers from "../../components/workersCustomers/workersCustomers";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";

const Home = () => {
  const user = useSelector((state) => state?.user?.currentUser);

  return (
    <Body>
      <ProductCross />
      {user?.isAdmin ? <Customers /> : <WorkersCustomers />}
      {user?.isAdmin ? <WorkersCredit /> : ""}
    </Body>
  );
};
const Body = styled(motion.div)`
  position: absolute;
  overflow-y: scroll;
  overflow-x: hidden;
  max-width: 100%;
  max-height: 80%;
  bottom: 10%;
  top: 20%;
`;

export default Home;
