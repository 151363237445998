import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { publicRequest } from "../../requestmethods";
import LoadingSpinner from "../LoadingSpinner";

import OneProduct from "./oneProduct";

const ProductCross = ({ cat, sort, filters }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [assesedProducts, setAssessedProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productLength, setProductLength] = useState("");
  const [maxLength, setMaxLength] = useState(null);
  const [showLess, setShowLess] = useState(false);
  const [view, setView] = useState(9);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await publicRequest.get(
          cat ? `/products?category=${cat}` : "/products"
        );

        const inStockTotal = [];
        setProducts(res.data);

        await res.data?.map((d) => {
          if (d.inStock) {
            inStockTotal.push(d);
          }
        });

        setProductLength(() => inStockTotal.length);
        setIsLoading(false);
        res?.data && setMaxLength(res.data.length);
      } catch (err) {
        console.log(err);
      }
    };

    getProducts();
  }, [cat]);

  useEffect(() => {
    const maxi = () => {
      if (maxLength <= view) {
        setShowLess(true);
      }

      if (maxLength === 0) {
        setView(0);
      }
    };

    maxLength && maxi();
  }, [view, maxLength]);

  useEffect(() => {
    const controller = () => {
      const miniControl = () => {
        if (products[0] === undefined || maxLength === 0) {
          setShowLess(true);
        }
      };

      if (maxLength && view >= maxLength) {
        setView(maxLength);
        setShowLess(true);
      }
      if (maxLength && view >= maxLength && miniControl()) {
        setView(maxLength);
        setShowLess(true);
      }

      if (maxLength > view) {
        setShowLess(false);
      }

      miniControl();
    };
    maxLength && controller();
  }, [view, products, maxLength]);

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    const itemViewer = () => {
      const viewer = [];

      for (let i = 0; i < view; i++) {
        viewer.push(products[i]);
      }
      setAssessedProducts(viewer);
    };

    itemViewer();
  }, [products, cat, view]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);

  return (
    <Container>
      <Data>
        <div>
          <div>Viatu vya Kutangaza</div>
        </div>
      </Data>
      <Items style={{ ["--length"]: view  }}>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          assesedProducts &&
          assesedProducts.map(
            (item) =>
              item?.inStock && (
                <OneProduct item={item} key={item?._id} className="item" />
              )
          )
        )}
      </Items>
      <More>
        <div onClick={() => setView(view + 6)}>{"load more ...>>"}</div>
      </More>
    </Container>
  );
};
const Container = styled(motion.div)`
  scroll-behavior: smooth;
  margin-top: 2.5%;
`;

const Data = styled(motion.div)`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Items = styled(motion.div)`
  max-width: 100%;
  display: grid;
  grid-template-columns: repeat(var(--length), minmax(8rem, 1fr));
  grid-gap: 1rem;
  justify-items: center;
  align-items: center;
  border-radius: 1rem;
  margin-top: 1%;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 1.5%;

  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

const More = styled(motion.div)`
  margin-top: 0.5%;
  margin-bottom: 0.5%;
  margin-right:5%;
  text-align: end;
`;

export default ProductCross;

/*

filteredProducts.map(
  (item) =>
    item.inStock && <OneProduct item={item} key={item.title} />
)

*/
