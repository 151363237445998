import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { HiOutlineUserCircle } from "react-icons/hi";
import { useSelector, useDispatch } from "react-redux";
import { logsout } from "../../redux/apiCalls";

const SideBar = ({ setSidebar }) => {
  const dispatch = useDispatch();
  const User = useSelector((state) => state.user.currentUser);

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";

      setSidebar(false);
    }
  };

  const loginOut = () => {
    logsout(dispatch);
  };

  return (
    <CardShadow
      className="shadow"
      onClick={exitDetailHander}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <Items>
        <Item>
          <div className="name">{User.username}</div>
          <div className="sidedata">
            <HiOutlineUserCircle size={25} />
          </div>
        </Item>
        <List>
          <div
            className="list"
            style={{ textDecoration: "none", color: "black" }}
          >
            <div>
              <Link
                className="list"
                style={{ textDecoration: "none", color: "black" }}
                to={User.isAdmin ? "/createcustomer" : "/createworkercustomer"}
              >
                Create Customer
              </Link>
            </div>
          </div>
          <div className="list">
            {User.isAdmin && (
              <Link
                className="list"
                style={{ textDecoration: "none", color: "black" }}
                to="/createproduct"
              >
                <div> Create Product</div>
              </Link>
            )}
          </div>
          <div className="list">
            {User.isAdmin && (
              <Link
                className="list"
                style={{ textDecoration: "none", color: "black" }}
                to="/editproduct"
              >
                <div> Products Details</div>
              </Link>
            )}
          </div>
          <div className="list">
            {User.isAdmin && (
              <Link
                className="list"
                style={{ textDecoration: "none", color: "black" }}
                to="/stocksdata"
              >
                <div> Products indepth </div>
              </Link>
            )}
          </div>
          <div className="list">
            {User.isAdmin && (
              <Link
                className="list"
                style={{ textDecoration: "none", color: "black" }}
                to="/userlist"
              >
                <div> Users </div>
              </Link>
            )}
          </div>
        </List>

        <Data>
          <div className="data">
            <Link
              to="/login"
              style={{ textDecoration: "none", color: "black" }}
            >
              Sign In
            </Link>
          </div>
          <div className="data" onClick={loginOut}>
            Sign Out
          </div>
          <div className="data">
            {User?.isAdmin && (
              <Link
                to="/register"
                style={{ textDecoration: "none", color: "black" }}
              >
                Register
              </Link>
            )}
          </div>
        </Data>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 70%;

  background: white;
  position: absolute;
  color: black;
  height: 100%;
  bottom: 0;
  right: 0;
`;

const List = styled(motion.div)`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
  grid-gap: 1rem;
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
`;

const Data = styled(motion.div)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  margin-top: 20%;
  grid-gap: 0.5rem;
  justify-items: center;
  align-items: center;
  margin-left: 2%;

  height: 40%;

  .data {
    height: 10%;
    width: 100%;
    background-color: rgb(243, 243, 243);
    border-radius: 1rem;

    padding: 2%;
    font-size: 90%;
    display: grid;
    align-items: center;
    justify-items: center;
  }
`;

const Item = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 5%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: center;
  margin-bottom: 10%;

  .name {
    font-size: 100%;
  }

  .sidedata {
    text-align: end;
  }
`;

export default SideBar;
