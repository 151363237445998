import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { publicRequest } from "../../requestmethods";
import OneProductList from "./oneProductList";
import LoadingSpinner from "../LoadingSpinner";
import { useLocation } from "react-router-dom";

const ProductList = ({ sort, filters, cat, setCat }) => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [view, setView] = useState(8);
  const [assesedProducts, setAssessedProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [maxLength, setMaxLength] = useState(null);
  const [showLess, setShowLess] = useState(false);
  const location = useLocation();
  let currentLoc = location.search.slice(1, 9);

  useEffect(() => {
    const getProducts = async () => {
      try {
        const res = await publicRequest.get(
          cat ? `/products?category=${cat}` : "/products"
        );

        setProducts(res?.data);
        setIsLoading(false);
        res?.data && setMaxLength(res?.data.length);
      } catch (err) {
        console.log(err);
      }
    };

    getProducts();
  }, [cat, currentLoc]);

  useEffect(() => {
    const maxi = () => {
      if (maxLength <= view) {
        setShowLess(true);
      }

      if (maxLength === 0) {
        setView(0);
      }
    };

    maxLength && maxi();
  }, [view, maxLength]);

  useEffect(() => {
    const controller = () => {
      const miniControl = () => {
        if (products[0] === undefined || maxLength === 0) {
          setShowLess(true);
        }
      };

      if (maxLength && view >= maxLength) {
        setView(maxLength);
        setShowLess(true);
      }
      if (maxLength && view >= maxLength && miniControl()) {
        setView(maxLength);
        setShowLess(true);
      }

      if (maxLength > view) {
        setShowLess(false);
      }

      if (maxLength > 8 && view < 8) {
        setView(8);
        setShowLess(false);
      }

      miniControl();
    };
    maxLength && controller();
  }, [view, products, maxLength]);

  useEffect(() => {
    const itemViewer = () => {
      const viewer = [];

      for (let i = 0; i < view; i++) {
        viewer.push(products[i]);
      }
      setAssessedProducts(viewer);
    };

    itemViewer();
  }, [products, cat, view]);

  return (
    <Container>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <Items>
          {assesedProducts[0] &&
            assesedProducts?.map((item) => (
              <OneProductList item={item} key={item?._id} />
            ))}
        </Items>
      )}
      {showLess || !assesedProducts[0] ? (
        <div></div>
      ) : (
        <p
          style={{ cursor: "pointer", width: "40%" }}
          onClick={() => setView(view + 6)}
        >
          load more...
        </p>
      )}
    </Container>
  );
};
const Container = styled(motion.div)`
  @media (max-width: 768px) {
    scroll-behavior: smooth;
  }
`;

const Items = styled(motion.div)`
  @media (max-width: 768px) {
    max-width: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    justify-items: center;
    align-items: center;
    margin-left: 5%;
    margin-right: 5%;
  }
`;
export default ProductList;

/*

  useEffect(() => {
    cat &&
      setFilteredProducts(
        products.filter((item) =>
          Object.entries(filters).every(([key, value]) =>
            item[key].includes(value)
          )
        )
      );
  }, [products, cat, filters]);

  useEffect(() => {
    if (sort === "newest") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.createdAt - b.createdAt)
      );
    } else if (sort === "asc") {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => a.price - b.price)
      );
    } else {
      setFilteredProducts((prev) =>
        [...prev].sort((a, b) => b.price - a.price)
      );
    }
  }, [sort]);



*/
