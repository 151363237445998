import React from "react";
import styled from "styled-components";
import { motion } from "framer-motion";
import { userRequest } from "../../requestmethods";
import { useHistory } from "react-router";

const DeleteProduct = ({ setDeleteCheck, id }) => {
  const history = useHistory();

  const exitDetailHander = (e) => {
    const element = e.target;
    if (element.classList.contains("shadow")) {
      document.body.style.overflow = "auto";
      setDeleteCheck(false);
    }
  };

  const deleteConfirm = async () => {
    try {
      const res = await userRequest.delete(`/products/${id}`);

      setDeleteCheck(false);

      console.log(res);
      history.push("/editproduct/");

      return res;
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <CardShadow
      className="shadow"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      onClick={exitDetailHander}
    >
      <Items>
        <Balance
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <div className="title">
            <h3>Are you sure you want to delete?</h3>
          </div>
          <div className="delete">
            <button onClick={deleteConfirm}>Yes</button>
            <button onClick={(e) => setDeleteCheck(false)}>No</button>
          </div>
        </Balance>
      </Items>
    </CardShadow>
  );
};
const CardShadow = styled(motion.div)`
  width: 100%;
  min-height: 100vh;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 5;

  &::-webkit-scrollbar {
    width: 0.5rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #ff7676;
  }

  &::-webkit-scrollbar-track {
    background: white;
  }
`;

const Items = styled(motion.div)`
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
  border-radius: 1rem;
  background: white;
  position: absolute;
  color: black;
  height: 50%;
  top: 25%;
  bottom: 25%;
`;

const Balance = styled(motion.ul)`
  position: absolute;
  width: 90%;
  background: white;
  height: 50%;
  margin-left: 5%;
  margin-right: 5%;
  overflow: hidden;
  top: 25%;
  bottom: 25%;

  border-radius: 1rem;
  padding: 1rem;

  .delete {
    display: grid;
    margin-top: 2rem;
    grid-gap: 1rem;
    height: 70%;

    button {
      width: 70%;
      border: 0.01rem solid gray;
      border-radius: 2rem;
      margin-left: 15%;
      margin-right: 15%;
    }
  }
`;

export default DeleteProduct;
